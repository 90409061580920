import React, { useState, FormEvent } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Checkbox, Col, Row, Typography, message } from "antd";
import "./index.less";
import { t } from "i18next";

interface Iprops {
  checkboxChecked: boolean;
  handleCheckboxChange: any;
  setLoading: any;
  loading: any;
  handlePayment: any;
  paymentAmount: any;
}

const CheckoutForm: React.FC<Iprops> = ({ checkboxChecked, handleCheckboxChange, loading, setLoading, handlePayment, paymentAmount }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    let mainAmount = paymentAmount();
    if (!mainAmount || Number(mainAmount) <= 1) {
      message.error(t("no-amount"));
      return;
    }
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      setError("Card number element not found");
      setLoading(false);
      return;
    }

    const { token, error: stripeError } = await stripe.createToken(cardNumberElement);

    if (stripeError) {
      if (stripeError.code === "incomplete_number") {
        message.error(t("card-number-incomplete"));
      } else if (stripeError.code === "incomplete_expiry") {
        message.error(t("card-expiry-incomplete"));
      } else if (stripeError.code === "incomplete_number") {
        message.error(t("card-cvv-incomplete"));
      } else {
        message.error(stripeError.message);
      }
      setError(`Token creation failed: ${stripeError.message}`);
      setLoading(false);
    } else if (token) {
      setError(null);
      handlePayment(token.id);
      // Send token to backend for payment processing
    }
  };

  const cardStyle = {
    base: {
      color: "#ffff",
      border: "1px solid white", // Add border
      padding: "10px !important",
      fontFamily: "Arial, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "15px",
      "::placeholder": {
        color: "#5e5e5e",
      },
    },
    invalid: {
      color: "#ffff",
      iconColor: "#ffff",
    },
  };

  return (
    <Row className="w-full">
      <form onSubmit={handleSubmit} className="w-full">
        <Row className="w-full">
          <Typography.Text className="fanPaymentLargeLabel">{t("card-number")}</Typography.Text>
          <CardNumberElement className="w-full mt-10 mb-10" options={{ style: cardStyle }} />
        </Row>
        <Row className="w-full">
          <Col span={12} className="custom-col">
            <Typography.Text className="fanPaymentLargeLabel">{t("expiration-date")}</Typography.Text>
            <CardExpiryElement className="w-full mt-10 mb-10" options={{ style: cardStyle }} />
          </Col>
          <Col span={12} className="custom-col">
            <Typography.Text className="fanPaymentLargeLabel">{t("cryptogram")}</Typography.Text>
            <CardCvcElement className="w-full mt-10 mb-10" options={{ style: cardStyle }} />
          </Col>
        </Row>
        <Row className="mt-10">
          <Checkbox className="paymentTermsCheckBox" checked={checkboxChecked} onChange={handleCheckboxChange}>
            {t("payment-terms")}
          </Checkbox>
        </Row>
        <button type="submit" disabled={!stripe || loading || !checkboxChecked} className="authBtn mt-20 mb-20">
          {t("confirm")}
        </button>
      </form>
    </Row>
  );
};

const StripeCheckoutForm: React.FC<Iprops> = ({ checkboxChecked, handleCheckboxChange, setLoading, loading, handlePayment, paymentAmount }) => {
  return (
    <CheckoutForm
      checkboxChecked={checkboxChecked}
      handleCheckboxChange={handleCheckboxChange}
      setLoading={setLoading}
      loading={loading}
      handlePayment={handlePayment}
      paymentAmount={paymentAmount}
    />
  );
};

export default StripeCheckoutForm;
