import React from "react";
import { Col, Row, Space, Typography, Select } from "antd";
import { useTranslation } from "react-i18next";
import BackIcon from "../../../assets/icons/new-arrow-left.svg";
import { IBillingAddress, IUser } from "../../../helpers/types";
import AuthInputComponent from "../../AuthInputComponent";
import AuthButtonComponent from "../../AuthButtonCompnent";
import AuthOrDividerComponent from "../../AuthOrDividerComponent";
import { SelectValue } from "antd/es/select";
import { FilterFunc } from "rc-select/lib/Select";
import "../index.less";

interface OptionItem {
  value: SelectValue;
  label: string;
}

type SignUpCreatorStepFiveProps = {
  billingAddress: IBillingAddress | null;
  setBillingAddress: (address: any) => void;
  handleBillingChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  updateBillingAddress: () => void;
  countries: Array<{ label: string; value: string }>;
  user?: IUser | null;
  handleBackStep: () => void;
  setCurrentStep: (step: number) => void;
  handleDropdownVisibleChange: (open: boolean) => void;
};

const SignUpCreatorStepFiveComponent: React.FC<SignUpCreatorStepFiveProps> = ({
  billingAddress,
  setBillingAddress,
  handleBillingChange,
  updateBillingAddress,
  countries,
  user,
  handleBackStep,
  setCurrentStep,
  handleDropdownVisibleChange,
}) => {
  const { t } = useTranslation();

  const filterOption: FilterFunc<OptionItem> | undefined = (inputValue: string, option?: OptionItem) => {
    return (option?.label.toLowerCase() ?? "").indexOf(inputValue.toLowerCase()) !== -1;
  };

  return (
    <Col className="padding-container">
      <Row>
        <Space className="w-full gap-20" direction="vertical">
          <Row onClick={() => setCurrentStep(4)}>
            <img className="mt-10" src={BackIcon} width={32} onClick={handleBackStep} alt="Back" />
            <Typography.Text className="authHeader authBillingHeader mt-5">{t("billing")}</Typography.Text>
          </Row>
          <Row className="mt-20">
            <AuthInputComponent
              label={t("company-name")}
              placeholder={t("company-name")!}
              type="text"
              name="companyName"
              value={billingAddress?.companyName || ""}
              handleChange={handleBillingChange}
            />
          </Row>
          <Row>
            <AuthInputComponent
              label={t("address")}
              placeholder={t("address")!}
              type="text"
              name="address"
              value={billingAddress?.address || ""}
              handleChange={handleBillingChange}
            />
          </Row>
          <Row gutter={7}>
            <Col span={12}>
              <AuthInputComponent
                label={t("city")}
                placeholder={t("city")!}
                type="text"
                name="city"
                value={billingAddress?.city || ""}
                handleChange={handleBillingChange}
              />
            </Col>
            <Col span={12}>
              <AuthInputComponent
                label={t("postal-code")!}
                placeholder={t("postal-code")!}
                type="text"
                name="postalCode"
                value={billingAddress?.postalCode || ""}
                handleChange={handleBillingChange}
              />
            </Col>
          </Row>
          <Row>
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="authInputLabel">{t("country")}</Typography.Text>
              <Select
                showSearch
                placeholder={t("country")!}
                className="select-billing dark"
                options={countries}
                filterOption={filterOption}
                value={user?.country}
                onSelect={(country: any) => {
                  setBillingAddress({ ...billingAddress, country });
                }}
                autoFocus={false}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ position: "fixed", zIndex: 1000 }}
                onDropdownVisibleChange={handleDropdownVisibleChange}
                onFocus={(e) => e.preventDefault()}
                onClick={(e) => e.preventDefault()}
              />
            </Space>
          </Row>
          <Row>
            <AuthInputComponent
              label={t("vat-number")}
              placeholder={t("vat-number")!}
              type="text"
              name="vatNumber"
              value={billingAddress?.vatNumber || ""}
              handleChange={handleBillingChange}
            />
          </Row>
          <Row className="mt-20">
            <AuthButtonComponent label={t("continue-label")} onClick={updateBillingAddress} />
          </Row>
        </Space>
      </Row>
      <Row>
        <AuthOrDividerComponent />
      </Row>
    </Col>
  );
};

export default SignUpCreatorStepFiveComponent;
