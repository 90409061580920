import { SendOutlined } from "@ant-design/icons";
import { Row, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GiftIcon from "../../../assets/icons/gift.svg";
import ShareIcon from "../../../assets/icons/share.svg";
import SendCommentIcon from "../../../assets/icons/send-comment.svg";
import InputComponent from "../../../components/InputComponent";
import LiveCommentsComponent from "../../../components/LiveCommentsComponent";
import LiveTipModalComponent from "../../../components/LiveTipModalComponent";
import LiveTipsComponent from "../../../components/LiveTipsComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import { PAYMENT_TYPES } from "../../../helpers/constant";
import { ILive, ILiveTip } from "../../../helpers/types";
import { addLiveComment, editLiveComment, newLiveTip } from "../../../redux/lives/liveDataSlice";
import { clearToken } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { authService, liveService } from "../../../services";
import { socket } from "../../../socket";

interface Props {
  id: string;
  live: ILive | null;
}

export const FanLiveRTMPFooter = (props: Props) => {
  const { id, live } = props;
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);

  return (
    <>
      <Space className="z-fan-live footer-shadow mb-5" direction="vertical">
        <Row className="justify-content-end flex-no-wrap gap-24" align="middle">
          <img src={ShareIcon} width={22} alt="share" onClick={() => setIsShareModalOpen(true)} />
        </Row>
      </Space>
      <ShareModalComponent
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        id={live?.creator?._id}
        username={live?.creator?.username}
        link="https://my.loly.app"
        headerTitle='share-stream-via'
      />
    </>
  );
};
