import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);
dayjs.extend(duration);
dayjs.extend(isSameOrBefore);

dayjs.updateLocale("en", {
  monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
});

dayjs.updateLocale("fr", {
  weekdaysShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
  weekdays: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
  monthsShort: ["Janv", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Août", "Sep", "Oct", "Nov", "Déc"],
});

dayjs.updateLocale("es", {
  monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
  weekdaysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
  weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
});

const HOURS_IN_SECONDS = 3600;
class DateUtil {
  constructor() {
    this.isPastTime = this.isPastTime.bind(this);
  }

  public getWeekLabelsForRevenuesPage(currentLanguage: string) {
    dayjs.locale(currentLanguage);
    const today = dayjs();
    const labels = [];
    
    // Generate labels for the last 7 days, ending with today
    for (let i = 6; i >= 0; i--) {
      labels.push(today.subtract(i, "day").format("dddd"));
    }
  
    return labels;
  }  

  public getYearLabelsForRevenuesPage(currentLanguage: string) {
    dayjs.locale(currentLanguage); // Ensure the locale is set to the current language

    const labels = [];
    for (let i = 0; i < 12; i++) {
      labels.push(dayjs().month(i).format("MMMM")); // Get the name of each month
    }

    return labels;
  }

  public getMonthShortFromMonth(month: string): string {
    dayjs.locale("en");
    // Define the months with specific index types
    const months: { [key: string]: string } = {
      January: "Janvier",
      February: "Février",
      March: "Mars",
      April: "Avril",
      May: "Mai",
      June: "Juin",
      July: "Juillet",
      August: "Août",
      September: "Septembre",
      October: "Octobre",
      November: "Novembre",
      December: "Décembre",
    };

    // Find the English month that corresponds to either the English long name or French name
    const englishMonth = Object.keys(months).find(
      (key) => key.toLowerCase() === month.toLowerCase() || months[key].toLowerCase() === month.toLowerCase()
    );

    if (!englishMonth) {
      throw new Error("Invalid month name");
    }

    // Convert the found English month name to a month index
    const monthIndex = new Date(`${englishMonth} 1 2000`).getMonth(); // 'January 1 2000' => 0, 'February 1 2000' => 1, etc.

    // Get short English version using dayjs, now correctly using the month index
    return dayjs().month(monthIndex).format("MMM");
  }

  public getDayShortFromDay(day: string): string {
    dayjs.locale("en"); // Set the locale to English for consistent outputs

    // Map of day names to their indices
    const dayIndices: { [key: string]: number } = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sun: 0,
      Mon: 1,
      Tue: 2,
      Wed: 3,
      Thu: 4,
      Fri: 5,
      Sat: 6,
      Dimanche: 0,
      Lundi: 1,
      Mardi: 2,
      Mercredi: 3,
      Jeudi: 4,
      Vendredi: 5,
      Samedi: 6,
    };

    // Get the day index
    const dayIndex = dayIndices[day];

    if (dayIndex === undefined) {
      throw new Error("Invalid day name");
    }

    // Generate the short day name
    const shortDay = dayjs().day(dayIndex).format("ddd");
    return shortDay;
  }

  public getMonthDaysWithCurrentDayLast(): string[] {
    const today = dayjs(); // Gets today's date dynamically
    const startDate = today.subtract(29, 'day'); // Start 29 days ago to include today as the 30th day
    const dates = [];
  
    for (let date = startDate; date.isBefore(today) || date.isSame(today, 'day'); date = date.add(1, 'day')) {
      dates.push(date.format('DD/MM/YYYY'));
    }
  
    return dates;
  }  

  public convertDateTimeStringToDateString(dateTimeString: string) {
    return dateTimeString.includes("T") ? dateTimeString.split("T")[0] : dateTimeString;
  }

  public getYearFromDateTimeString(dateTimeString: string) {
    return dayjs(dateTimeString).year();
  }

  public getMonthFromDateTimeString(dateTimeString: string) {
    const dateTime = new Date(dateTimeString);
    let month = (dateTime.getMonth() + 1).toString();
    if (month !== null && Number(month) < 10) month = "0" + month;

    return month;
  }

  public getMonthAbbreviationFromDateTimeString(dateTimeString: string, currentLanguage: string) {
    return dayjs(dateTimeString).locale(currentLanguage).format("MMMM");
  }

  public getDayAbbreviationFromDateTimeString(dateTimeString: string, currentLanguage: string) {
    return dayjs(dateTimeString).locale(currentLanguage).format("dddd");
  }

  public getDateFromDateTimeString(dateTimeString: string) {
    const dateTime = new Date(dateTimeString);
    let date = dateTime.getDate().toString();
    if (date !== null && Number(date) < 10) date = "0" + date;
    return date;
  }

  public getHourFromDateTimeString(dateTimeString: string) {
    const dateTime = new Date(dateTimeString);
    let hour = dateTime.getHours().toString();
    if (hour !== null && Number(hour) < 10) hour = "0" + hour;
    return hour;
  }

  public getMinFromDateTimeString(dateTimeString: string) {
    const dateTime = new Date(dateTimeString);
    let min = dateTime.getMinutes().toString();
    if (min !== null && Number(min) < 10) min = "0" + min;
    return min;
  }

  public getHoursMinSecTimeStreaming(seconds: number) {
    const duration = dayjs.duration(seconds, "seconds");
    return duration.format(seconds >= HOURS_IN_SECONDS ? "HH:mm:ss" : "mm:ss");
  }

  public getMinuteFromSecond(second: number) {
    return dayjs
      .duration(second * 1000)
      .get("m")
      .toString();
  }

  public getSecondFromSecond(second: number) {
    let seconds = dayjs
      .duration(second * 1000)
      .get("s")
      .toString();
    if (!isNaN(Number(seconds)) && Number(seconds) < 10) {
      seconds = "0" + seconds;
    }
    return seconds;
  }

  public isToday(dateTime: string) {
    return dayjs().isSame(dayjs(dateTime), "day");
  }

  public isCurrentWeek(dateTime: string) {
    return dayjs().isSame(dayjs(dateTime), "week");
  }

  public isCurrentYear(dateTime: string) {
    return dayjs().isSame(dayjs(dateTime), "year");
  }

  public isPastDate(date: dayjs.Dayjs) {
    const selectedDate = date && date.set("h", 0).set("m", 0).set("s", 0).set("ms", 0);
    const currentDate = dayjs().set("h", 0).set("m", 0).set("s", 0).set("ms", 0);
    return selectedDate && selectedDate.isSameOrBefore(currentDate.subtract(1, "day"));
  }

  public isCurrentHour(date: any): boolean {
    return date && date.get("h") === dayjs().get("h");
  }

  public getDisabledHours(date: any) {
    const currentHour = dayjs().hour();
    const disabledHours: number[] = [];
    if (this.isToday(date.toString())) {
      for (let i = 0; i < currentHour; i++) {
        disabledHours.push(i);
      }
    }
    return disabledHours;
  }

  public getDisabledMinutes(date: any) {
    const disabledMinutes = [];
    const currentMinute = dayjs().minute();
    if (this.isCurrentHour(date)) {
      for (let i = 0; i < currentMinute; i++) {
        disabledMinutes.push(i);
      }
    }
    return disabledMinutes;
  }

  public isPastTime(date: any | null) {
    return {
      disabledHours: () => (date ? this.getDisabledHours(date) : []),
      disabledMinutes: () => (date ? this.getDisabledMinutes(date) : []),
      disabledSeconds: () => [],
    };
  }
}

const dateUtil = new DateUtil();

export default dateUtil;
