import { Space, Typography } from "antd";
import { t } from "i18next";
import React from "react";

type props = {
  className?: string;
  flag: string;
  name: string;
  code: string;
  countryCode: string;
  showName?: boolean;
  showCode?: boolean;
  showFlag?: boolean;
  onClick?: (countryCode: string) => void;
};

const PhoneCodeComponent: React.FC<props> = ({
  flag,
  name,
  code,
  countryCode,
  className,
  onClick,
  showName = false,
  showCode = true,
  showFlag = true,
}) => {
  return (
    <Space
      onClick={() => {
        if (code && onClick) {
          onClick(countryCode);
        }
      }}
    >
      {showFlag && <div className="phoneCodeFlag" style={{ backgroundImage: `url("${flag}")` }}></div>}
      {showCode && <Typography.Text className="text-white-color">{code}</Typography.Text>}
      {showName && (
        <Typography.Text className={!name ? "country-name" : "text-white-color"}>{name ? name.split(",")[0] : t("country")}</Typography.Text>
      )}
    </Space>
  );
};

export default PhoneCodeComponent;
