import { Layout, Row, Typography } from "antd";
import ArrowLeftIconComponent from "../../../../components/ArrowLeftIconComponent";
import { useTranslation } from "react-i18next";
import "./index.less";
import CollectionGridComponent from "../../../../components/Collections/CollectionGridComponent";

const CreatorCollectionPage = () => {
  const { t } = useTranslation();

  return (
    <Layout className="gradient-background-blue-settings">
      <Layout.Content className="content creator-collection-page-wrapper background-transparent">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={12} left={10} />
          <Typography.Text className="header-title-black-35">{t("collections")}</Typography.Text>
        </Row>
        <div className="mt-25">
          <CollectionGridComponent isMyProfile={true} />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorCollectionPage;
