import { Space, Typography, Upload, message } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VideoIcon from "../../assets/icons/jouer.svg";
import PlusIcon from "../../assets/icons/plus-black.svg";
import { compressImage, convertHeicFile, generateThumbnailURL, isImageAcceptedFormat } from "../../utls/FunctionsUtil";
import MailboxMediaModal from "../MailboxMediaModal";
import "./index.less";

type props = {
  fileList: UploadFile[];
  setFileList: Dispatch<SetStateAction<UploadFile<any>[]>>;
  setQuickFiles: any;
  handleCancelUploadVideo: Function;
  setIsVideo: Dispatch<SetStateAction<boolean>>;
};

const AddUnlockLinkMediaComponent: React.FC<props> = ({ fileList, setFileList, setQuickFiles, handleCancelUploadVideo, setIsVideo }) => {
  const { t } = useTranslation();
  const uploadRef = useRef<any>(null);
  const [isMediaModal, setIsMediaModal] = useState(false);
  const navigate = useNavigate();

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      // If the file is in an accepted format, proceed without conversion
      if (isImageAcceptedFormat(file.type)) {
        // compress file
        const compressedImage = await compressImage(file);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        //handleConvertedFile(file.uid, file, true);
        return false;
      } else if (file.type === "image/heic" || file.type === "") {
        handleConvertedFile(file.uid, file, false);
        // convert file
        const convertedFile = await convertHeicFile(file);
        // compress file
        const compressedImage = await compressImage(convertedFile);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        return false;
      } else if (file.type.includes("video/")) {
        handleConvertedFile(file.uid, file, true);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      removeUnsupportedFiles(file.uid);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = async (uid: string, file: File, converted: boolean) => {
    try {
      if (!converted) {
        const fileObj: UploadFile = {
          uid: uid,
          name: file.name,
          status: "uploading",
          percent: 50,
        };
        setFileList([fileObj]);
      } else {
        const fileObj: UploadFile = {
          uid: uid,
          name: file.name,
          status: "done",
          type: file.type,
          size: file.size,
          originFileObj: file as RcFile,
        };
        if (file.type.includes("video")) {
          const result = await generateThumbnailURL(file as RcFile);
          fileObj.url = result;
          setIsVideo(true)
        }
        setFileList([fileObj]);
      }
    } catch (e) {
      console.log("error ", e);
    }
  };

  // Example usage:

  const removeUnsupportedFiles = (uid: string) => {
    setFileList([]);
  };

  const onRemove = async (file: UploadFile<any>) => {
    setFileList((prev) => prev.filter((f) => f.uid !== file.uid));
    setQuickFiles("");
    handleCancelUploadVideo();

  };

  const isImageUrl = (): boolean => {
    return true;
  };

  const customItemRender = (originNode: React.ReactNode, file: UploadFile) => {
    let isVideo: boolean = file.type?.includes("video")!;
    return (
      <>
        {originNode}
        {isVideo && <img src={VideoIcon} alt="video icon" className="unlockVideoIcon" />}
      </>
    );
  };

  const handleDeviceClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
      setIsMediaModal(false);
    }
  };

  return (
    <Space className="w-full gap-1 addUnlockLinkMediaWrapper" direction="vertical">
      {fileList.length === 0 && (
        <div className="addCircleGreen" onClick={() => setIsMediaModal(true)}>
          <img src={PlusIcon} width={90} alt="plus" />
        </div>
      )}
      <Upload
        accept="image/*,video/*"
        action=""
        itemRender={(originNode: any, file: any) => customItemRender(originNode, file)}
        fileList={fileList}
        listType="picture-card"
        maxCount={1}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
        isImageUrl={isImageUrl}
        showUploadList={{
          showPreviewIcon: false,
          showDownloadIcon: false,
        }}
      >
        {/* {fileList.length === 0 && (
          <div className="addCircleGreen">
            <img src={PlusIcon} width={70} alt="plus" />
          </div>
        )} */}
        <div ref={uploadRef} className="d-none"></div>
      </Upload>
      <Typography.Text className="font-24-bold text-white-color">{fileList?.length === 0 ? t("send-file") : t("1-media")}</Typography.Text>
      <Typography.Text className="font-13-regular text-grey-color">{fileList?.length === 0 && t("send-file-description")}</Typography.Text>
      <MailboxMediaModal
        isOpen={isMediaModal}
        onClose={() => setIsMediaModal(false)}
        onMyAlbums={() => {
          navigate(`/creator/quickLink?id=unlock-link`);
        }}
        onMyDevice={handleDeviceClick}
      />
    </Space>
  );
};

export default AddUnlockLinkMediaComponent;
