import { Layout, Row, Space, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FingerPrint from "../../../assets/icons/d_blanc.svg";
import DecryptLogo from "../../../assets/icons/decrypt-title-logo.svg";
import PaymentMethodsIcon from "../../../assets/images/organisme_de_paiement_resultat.webp";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import { FALLBACK_IMAGE } from "../../../helpers/constant";
import { IDecrypt } from "../../../helpers/types";
import { decryptService } from "../../../services/decrypt.service";
import VideoIcon from "../../../assets/icons/jouer.svg";
import "./index.less";

const DecryptPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const shouldFetch = useRef(true);

  const [decrypt, setDecrypt] = useState<Partial<IDecrypt>>({});
  const [expiredLink, setExpiredLink] = useState<boolean>(false);

  useEffect(() => {
    if (shouldFetch.current && searchParams.get("token") !== null && searchParams.get("token") !== undefined) {
      shouldFetch.current = false;
      decryptService.getDecryptInfos(searchParams.get("token")).then((data) => {
        if (data === "This link has expired.") {
          setExpiredLink(true);
        } else {
          setDecrypt(data);
        }
      });
    }
  }, []);

  const decryptClicked = () => {
    navigate(`/decrypt/payment/${searchParams.get("token")}`);
  };

  return (
    <Layout>
      {!expiredLink && (
        <div className="bottom-container">
          <div className="bottom-sticky">
            <Space direction="vertical" className="left-content gap-6">
              <p className="price">
                <span className="price-value">{decrypt?.fanPrice?.toFixed(2)} €</span>
              </p>
              <p className="secure-payment">{t("secure-payment")}</p>
            </Space>
            <button className="pay-button" onClick={decryptClicked}>
              {t("unlock")}
            </button>
          </div>
        </div>
      )}
      <Layout.Content className="content unlockLinkContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-60">
          <Typography.Text className="header-title">
            <img src={FingerPrint} width={30} style={{ marginRight: "10px" }} alt="Loly Decrypt Fingerprint" />
            <img src={DecryptLogo} width={150} alt="Loly Decrypt Logo" />
          </Typography.Text>
        </Row>
        {expiredLink ? (
          <Space className="w-full gap-21 mt-40" direction="vertical">
            <Row className="justify-content-center">
              <img src={FALLBACK_IMAGE} alt="media" className={"mediaImage"} width={150} height={225} />
            </Row>
            <Row className="justify-content-center mt-100">
              <Typography.Text className="font-48-bold text-white-color text-center">{t("link-expired")}</Typography.Text>
            </Row>
            <Row className="justify-content-center mt-50">
              <AuthButtonComponent
                onClick={() => {
                  navigate("/");
                }}
                label={t("go-to-loly")}
              />
            </Row>
          </Space>
        ) : (
          <Space className="w-full mb-100 mt-20" direction="vertical">
            <div className="position-relative text-center">
              <div className="container-img">
                <img src={decrypt.url} className="decrycptLinkImage" alt="media" />
                {decrypt.isVideo && <img src={VideoIcon} alt="video icon" className="video-icon" />}
              </div>
              <div className="mt-30">
                <Typography.Text className="priceDecryptFan">{decrypt?.fanPrice?.toFixed(2)} €</Typography.Text>
              </div>
            </div>
            <Row className="d-flex flex-col items-center mb-5">
              <Typography.Text className="font-10-regular text-grey-color mb-6 mt-10">{t("secure-payment-description")}</Typography.Text>
              <img src={PaymentMethodsIcon} width={170} height={31} alt="payment visa master card apple pay" />
            </Row>

            <div className="bottomDecrypt">
              <div className={"divider"} />

              <Row className="flex-col gap-13 mt-30">
                <Typography.Text className="font-18-medium text-white-color">{t("payment-detail")}</Typography.Text>

                <div className="d-flex justify-content-between">
                  <Typography.Text className="font-14-medium text-grey-color">{t("media")}</Typography.Text>
                  <Typography.Text className="font-14-medium text-grey-color">{decrypt.price} €</Typography.Text>
                </div>

                <div className="d-flex justify-content-between">
                  <Typography.Text className="font-14-medium text-grey-color">{t("taxes")}</Typography.Text>
                  <Typography.Text className="font-14-medium text-grey-color">
                    {decrypt.price !== undefined && decrypt.fanPrice !== undefined ? (decrypt.fanPrice - decrypt.price).toFixed(2) : ""} €
                  </Typography.Text>
                </div>

                <div className="d-flex justify-content-between">
                  <Typography.Text className="font-14-medium text-grey-color">{t("total")}</Typography.Text>
                  <Typography.Text className="font-14-medium text-grey-color">{decrypt.fanPrice} €</Typography.Text>
                </div>
              </Row>

              <Space className="mt-130 gap-13" direction="vertical">
                <Typography.Text className="font-18-medium text-white-color">{t("legal-mention")}</Typography.Text>
                <Typography.Text className="font-14-medium text-grey-color">Mail : contact@loly.app</Typography.Text>
                <Typography.Text className="font-14-medium text-grey-color">
                  <Link className="link" to="https://loly.app/conditions-generales-de-vente/" target="_blank" rel="noopener noreferrer">
                    {t("terms-and-conditions-of-sale")}
                  </Link>
                </Typography.Text>
                <Typography.Text className="font-14-medium text-grey-color">
                  <Link className="link" to="https://loly.app/conditions-generales-dutilisation/" target="_blank" rel="noopener noreferrer">
                    {t("terms-and-conditions-of-use")}
                  </Link>
                </Typography.Text>
                <Typography.Text className="font-14-medium text-grey-color">
                  <Link className="link" to="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank" rel="noopener noreferrer">
                    {t("privacy-policies")}
                  </Link>
                </Typography.Text>
              </Space>
            </div>
          </Space>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default DecryptPage;
