import React, { useState } from "react";
import { Col, message, Row, Space, Typography, Upload } from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { useTranslation } from "react-i18next";
import CameraIcon from "../../../assets/icons/camera.svg";
import DocumentIcon from "../../../assets/icons/document.svg";
import AuthButtonComponent from "../../AuthButtonCompnent";
import { convertHeicFile, isImageAcceptedFormat } from "../../../utls/FunctionsUtil";
import { userService } from "../../../services";
import "../index.less";

const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

type SignUpCreatorStepSixProps = {
  userId: string | null;
  setLoading: (loading: boolean) => void;
  setCurStep: (step: number) => void;
};

const SignUpCreatorStepSixComponent: React.FC<SignUpCreatorStepSixProps> = ({ userId, setLoading, setCurStep }) => {
  const { t } = useTranslation();
  const [idCardFile, setIdCardFile] = useState<UploadFile | null>(null);
  const [selfieFile, setSelfieFile] = useState<UploadFile | null>(null);
  const [idCardImageUrl, setIdCardImageUrl] = useState<string>();
  const [selfieImageUrl, setSelfieImageUrl] = useState<string>();

  const verifications = [
    {
      title: t("identity-title"),
      subTitle: t("identity-subTitle"),
      name: "idCard",
    },
    {
      title: t("selfie-title"),
      subTitle: t("selfie-subTitle"),
      name: "selfie",
    },
  ];

  const fileChanged = (key: string, file: UploadFile<any> | null) => {
    if (file !== null && file?.type?.includes("image")) {
      getBase64(file?.originFileObj, (url) => {
        if (key === "idCard") setIdCardImageUrl(url);
        if (key === "selfie") setSelfieImageUrl(url);
      });
    }
    if (file === null || file?.type?.includes("image")) {
      if (key === "idCard") setIdCardFile(file);
      if (key === "selfie") setSelfieFile(file);
    }
  };

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile, key: any): Promise<File | boolean | string> => {
    if (file !== null && file.type !== "" && !file?.type?.includes("image")) {
      message.error(t("only-images-allowed-error-message"));
      return false;
    }

    // If the file is in an accepted format, proceed without conversion
    if (isImageAcceptedFormat(file.type)) {
      return true;
    }

    try {
      if (file.type === "image/heic" || file.type === "") {
        handleConvertedFile(file.uid, file, false, key);
        // convert file
        const convertedFile = await convertHeicFile(file);
        handleConvertedFile(file.uid, convertedFile, true, key);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      fileChanged(key, null);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = (uid: string, file: File, converted: boolean, key: string) => {
    let fileObj: UploadFile;
    if (!converted) {
      fileObj = {
        uid: uid,
        name: file.name,
        status: "uploading",
        percent: 50,
      };
    } else {
      fileObj = {
        uid: uid,
        name: file.name,
        status: "done",
        type: file.type,
        size: file.size,
        originFileObj: file as RcFile,
      };
    }
    fileChanged(key, fileObj);
  };

  const uploadIdFiles = () => {
    if (!idCardFile) return message.warning(t("card-id-error-message"));
    if (!selfieFile) return message.warning(t("selfie-error-message"));
    if (!userId) return;

    setLoading(true);
    const formData = new FormData();
    const idCard: File = idCardFile.originFileObj as any;
    const selfie: File = selfieFile.originFileObj as any;
    formData.append("idVerification", idCard);
    formData.append("selfie", selfie);

    userService
      .uploadIdFiles(userId, formData)
      .then(() => setCurStep(7))
      .catch((error) => message.error(error))
      .finally(() => setLoading(false));
  };

  return (
    <Col>
      <Row className="justify-content-center mt-20">
        <Typography.Text className="authHeader authVerifyIdentity">{t("auth-identity-verification-title")}</Typography.Text>
      </Row>
      <Row className="mt-24">
        <Space className="w-full gap-20 mt-10" direction="vertical">
          {verifications.map((verification, index) => {
            const imgUrl = index === 0 ? (idCardImageUrl ? idCardImageUrl : undefined) : selfieImageUrl ? selfieImageUrl : undefined;
            const imgSize = imgUrl !== undefined ? "100%" : "";
            return (
              <Col key={index}>
                <Row className="justify-content-center">
                  <div className="authIdUploadWrapper">
                    <img
                      src={imgUrl || DocumentIcon}
                      width={imgSize}
                      height={imgSize}
                      style={{ borderRadius: imgSize, objectFit: "contain" }}
                      alt="document"
                    />
                    <Upload
                      accept="image/*"
                      showUploadList={false}
                      fileList={index === 0 ? (idCardFile ? [idCardFile] : undefined) : selfieFile ? [selfieFile] : undefined}
                      beforeUpload={async (file: RcFile) => {
                        return await beforeUpload(file, verification.name as any);
                      }}
                      onChange={(info: any) => {
                        fileChanged(verification.name, info.file);
                      }}
                      onRemove={() => fileChanged(verification.name, null)}
                    >
                      <img className="authIdUploadCameraIcon" src={CameraIcon} alt="camera" />
                    </Upload>
                  </div>
                </Row>
                <Row className="justify-content-center mt-24">
                  <Typography.Text className="authRevenueItemTitle text-center">{verification.title}</Typography.Text>
                </Row>
                <Row className="justify-content-center mt-10">
                  <Typography.Text className="authRevenueItemSubTitle text-center">{verification.subTitle}</Typography.Text>
                </Row>
                {index === 0 && (
                  <Row className="mt-10">
                    <div className="divider-identity" />
                  </Row>
                )}
              </Col>
            );
          })}
        </Space>
      </Row>
      <Row className="mt-40">
        <AuthButtonComponent label={t("terminate-label")} onClick={uploadIdFiles} />
      </Row>
    </Col>
  );
};

export default SignUpCreatorStepSixComponent;
