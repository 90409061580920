export const APP_NAME = "Loly"

// SUBSCRIPTION_PRICING_LIMITS contains the maximum subscription prices
// for different subscription periods.

export const SUBSCRIPTION_PRICING_LIMITS = {
    MONTHLY: 300, // Maximum price for a monthly subscription
    TRIMESTRIAL: 500, // Maximum price for a trimestrial subscription
  };

export const PAYMENT_ERROR_MESSAGES = ["incorrect_cvc", "insufficient_funds", "card_declined" , "card_blocked" , "authentication_required"];

export const PUBLIC_LIVES_LINK = `${window.location.origin}/p/fan/lives`

  