import { Badge, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { messageService } from "../../services";
import { socket } from "../../socket";

type props = {
  id: string;
  name: string;
  link: string;
  icon: string;
  activeIcon: string;
  isActive?: boolean;
};

const FooterItemComponent: React.FC<props> = React.memo(({ id, name, link, icon, activeIcon, isActive }) => {
  const {pathname} = useLocation()
  const shouldFetch = useRef(true);
  const navigate = useNavigate();
  const [unreadConversations, setUnreadConversations] = useState(0);

  function handleUpdateUnreadConversations(data: number) {
    if (data !== unreadConversations) {
      setUnreadConversations(data);
    }
  }

  useEffect(() => {
    if (shouldFetch.current && id === "messaging") {
      shouldFetch.current = false;
      messageService.getTotalNotReadMessages().then((total) => {
        if (total && total !== "") setUnreadConversations(parseInt(total, 10));
      });
    }

    socket.on("notify-read-messages-in-conversation", handleUpdateUnreadConversations);

    return () => {
      socket.off("notify-read-messages-in-conversation", handleUpdateUnreadConversations);
    };
  }, [id]);

  return (
    <Space
      className={`${isActive ? "text-white-color" : "text-grey-color"} 
        ${id === "messaging" ? "footerItemMsg" : id === "live" ? "footerItemLive" : "footerItem"} 
        cursor-pointer gap-5`}
      direction="vertical"
      align="center"
      onClick={() => navigate(link)}
    >
      {id === "messaging" ? (
        <Badge count={unreadConversations}>
          <img width={24} height={24} src={isActive ? activeIcon : icon} alt={`${name} icon`} />
        </Badge>
      ) : id === "live" ? (
        <img width={45} height={24} src={isActive ? activeIcon : icon} alt={`${name} icon`} />
      ) : (
        <>
          <img width={24} height={24} src={isActive ? activeIcon : icon} alt={`${name} icon`} onClick={()=>{
            if(id === 'home' && pathname === '/fan/home'){
              localStorage.setItem("isHome" , "true")
              window.dispatchEvent(new Event("localStorageChanged"));
            }
          }} />
        </>
      )}
    </Space>
  );
}, (prevProps, nextProps) => prevProps.isActive === nextProps.isActive);

export default FooterItemComponent;
