import { Button, Col, Row, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import AvatarComponent from "../AvatarComponent";
import "./index.less";

type props = {
  name: string;
  username: string;
  avatar: string;
  handleClick?: any;
  firstName?: string;
  lastName?: string;
  suggestions?: boolean;
};

const SuggestionCardComponent: React.FC<props> = ({ name, username, avatar, handleClick, firstName, lastName, suggestions }) => {
  const { t } = useTranslation();
  return (
    <>
      {!suggestions ? (
        <Row onClick={handleClick} className="justify-between items-center mt-10 pt-10">
          <Col span={24}>
            <Row className="items-center">
              <img className="avatar" src={avatar} alt={avatar} />
              <Space direction="vertical" className="recentVisitedTextWrapper">
                <Typography.Text className="UserName">{t(`${username}`)}</Typography.Text>
                <Typography.Text className="FirstName">{t(`${name}`)}</Typography.Text>
              </Space>
            </Row>
          </Col>
        </Row>
      ) : (
        <div className="cardWrapper">
          <div className="cardPost" onClick={handleClick}>
            <AvatarComponent
              className="cardAvatar"
              username={username}
              name={name}
              firstName={firstName}
              lastName={lastName}
              image={avatar}
              size={100}
              fontSize={20}
              lineHeight={24}
              fontWeight={true}
              fromSuggestions={true}
            />
            <Button className="discoverButton">{t("discover")}</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default SuggestionCardComponent;
