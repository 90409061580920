import { PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest } from "@stripe/stripe-js";
import { Checkbox, Col, Layout, Radio, Row, Space, Spin, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import FingerPrint from "../../../assets/icons/d_blanc.svg";
import BankCardImg from "../../../assets/icons/fan/payment/bank-card.svg";
import DecryptLogo from "../../../assets/icons/decrypt-title-logo.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FanPaymentPopUp from "../../../components/FanPaymentPopUp";
import FanPaymentRadioButtonComponent from "../../../components/FanPaymentRadioButtonComponent";
import InputComponent from "../../../components/InputComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { decryptService } from "../../../services/decrypt.service";
import { formatCardNumber, validateEmail } from "../../../utls/FunctionsUtil";
import StripeCheckoutForm from "../../FanPages/FanPaymentPage/StripeCheckoutForm";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import { settingService } from "../../../services/settings.service";
import Cookies from "js-cookie";
import { PAYMENT_ERROR_MESSAGES } from "../../../utls/constants";
import InfoPaymentImg from "../../../assets/images/info-payment.webp";
import "./index.less";

interface ApplePayData {
  _canMakePaymentAvailability: any;
}

type decrypt = {
  id?: string;
  price?: number;
};

const DecryptPaymentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [Key, setKey] = useState("");
  const { decryptId } = useParams();
  // payment option
  const paymentTypes = [
    {
      name: t("Bank-card"),
      value: "bankCard",
      suffix: <img src={BankCardImg} width={100} alt="bank card" />,
    },
  ];

  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState<string | null>(null);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [paymentStatus, setpaymentStatus] = useState<string>();
  const [showApplePay, setShowApplyPay] = useState<string | undefined>(undefined);
  const [applePay, setApplePay] = useState<ApplePayData>();
  // card details
  const [cardNumber, setCardNumber] = useState("");
  const [MonthYear, setMonthYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [error, setError] = useState("");
  // card validation
  const cardnumberReg = /^[0-9\s]*$/;
  const CardMonthYearReg = /^[0-9/]*$/;
  const [buy, setBuy] = useState(false);
  const cvvReg = /^\d*$/;
  const currentYear = new Date().getFullYear().toString().substring(2);
  const expCurrentYear = parseInt(currentYear, 10);
  const [month, year] = MonthYear.split("/");
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>(paymentTypes[0]?.value);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [decrypt, setDecrypt] = useState<decrypt>({});
  const shouldFetch = useRef(true);
  const [payemntGateway, setPayemntGateway] = useState<any>("shift4");

  // check box
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  useEffect(() => {
    if (shouldFetch.current && decryptId !== undefined) {
      Cookies.remove("subscription_data");
      shouldFetch.current = false;
      decryptService.getDecryptInfos(decryptId, true).then((data) => {
        setDecrypt(data);
      });
    }
  }, [decryptId]);

  if (Key !== "Backspace") {
    if (cardNumber.length === 4 || cardNumber.length === 9 || cardNumber.length === 14) {
      setCardNumber(cardNumber + " ");
    }
    if (MonthYear.length === 2) {
      setMonthYear(MonthYear + "/");
    }
  }

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  // apply pay
  useEffect(() => {
    if (!stripe || !elements || !paymentAmount || paymentAmount === 0 || !decrypt) {
      return;
    }

    const applePay = async () => {
      let label = `Unlock Link`;
      let amount = paymentAmount * 100; // $1
      let currency = "eur";
      let country = "BE";

      const pr = stripe.paymentRequest({
        currency: currency,
        country: country,
        requestPayerEmail: true,
        requestPayerName: true,
        total: {
          label: label,
          amount: amount,
        },
      });
      await pr.canMakePayment().then(async (result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      await pr.on("paymentmethod", async (event) => {
        const paymentMethod = event.paymentMethod;

        if (paymentMethod) {
          const paymentMethodId = event.paymentMethod.id;
          const applePayDetails = {
            unlockLinkId: decrypt?.id,
            paymentMethodId: paymentMethodId,
            email: "demo@gmail.com",
          };
          decryptService.applePayPurchaseMedia(applePayDetails).then((res: any) => {
            if (res.transaction.status === "success") {
              navigate("/decrypt/unlocked", {
                state: {
                  decryptId: res.transaction.targetId,
                  transactionId: res.transaction._id,
                },
              });
            } else {
              setpaymentStatus("error");
            }
          });
          // for Server side Stripe-API handling of payment
        }
        event.complete("success"); // Completes the transaction and closes Google/Apple pay
      });
    };

    applePay();
  }, [stripe, elements, paymentAmount, decrypt]);

  useEffect(() => {
    setShowApplyPay(JSON.stringify(paymentRequest));
  }, [paymentRequest]);

  useEffect(() => {
    if (showApplePay) {
      setApplePay(JSON.parse(showApplePay));
    }
  }, [showApplePay]);

  // stripe media push details
  const stripeDecryptDetail = {
    unlockLinkId: decrypt?.id,
    email: email,
    cardNumber: parseInt(cardNumber.replace(/\s/g, "")),
    expiryMonth: parseInt(month),
    expiryYear: 2000 + parseInt(year),
    cvv: parseInt(cvv),
    paymentGateway: payemntGateway,
  };

  // subscribe btn
  const subscribe = (token: any) => {
    if (!validateEmail(email!)) {
      setIsLoading(false);
      return message.warning(t("email-invalid"));
    }
    if (payemntGateway === "shift4") {
      if (cvv.length < 3) {
        return message.warning(t("valid-cvv"));
      }
      if (paymentAmount <= 1) {
        message.error(t("no-amount"));
        return;
      }
    }
    if (error) {
      return message.warning(error);
    }

    setIsLoading(true);

    decryptService
      .purchaseMedia({ ...stripeDecryptDetail, token })
      .then((data) => {
        setIsLoading(false);
        if (data.transaction) {
          if (data?.transaction?.redirectUrl) {
            Cookies.set(
              "subscription_data",
              JSON.stringify({
                token: data?.transaction?.token?.id,
                transactionId: data?.transaction?.transactionId,
                type: "decryptLink",
                email,
                targetId: data?.transaction?.targetId,
                decryptId,
              })
            );
            window.location.href = data?.transaction?.redirectUrl;
          } else {
            if (data.transaction.status === "success") {
              navigate("/decrypt/unlocked", {
                state: {
                  decryptId: data.transaction.targetId,
                  transactionId: data.transaction._id,
                },
              });
            }
          }
        } else {
          if (PAYMENT_ERROR_MESSAGES.includes(data.message)) {
            message.error(t(data.message));
          }
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log("data err", err);
        message.error(t("payment-failed-lable"));
      });
  };

  useEffect(() => {
    setPaymentAmount(decrypt?.price || 0);
  }, [decrypt]);

  useEffect(() => {
    if (cardNumber.length === 19 && cvv.length === 3 && MonthYear.length === 5 && checkboxChecked) {
      setBuy(true);
    } else {
      setBuy(false);
    }
  }, [cardNumber, cvv, MonthYear, checkboxChecked]);

  useEffect(() => {
    getCurrentPaymentGateway();
  }, []);

  const getCurrentPaymentGateway = async () => {
    await settingService.getDecryptPayentGateway().then((res: any) => setPayemntGateway(res?.decrypt));
  };

  useEffect(() => {
    const formattedCardNumber = formatCardNumber(cardNumber);
    if (formattedCardNumber !== cardNumber) {
      setCardNumber(formattedCardNumber);
    }
  }, [cardNumber]);

  return (
    <Spin spinning={isLoading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout>
        <Layout.Content className="content fanSubscribeContentWrap gradient-background">
          <Row className="relative justify-content-center mt-25">
            <ArrowLeftIconComponent top={2} left={16} />
            <Typography.Text className="header-title">
              <img src={FingerPrint} width={30} style={{ marginRight: "10px" }} alt="Loly Decrypt Fingerprint" />
              <img src={DecryptLogo} width={150} alt="Loly Decrypt Logo" />
            </Typography.Text>
          </Row>
          <Col>
            <Row>
              <div className="fanSubscribeHeaderContent">
                <Row>
                  <Typography.Text className="fanPaymentLargeLabel">{t("summary")}</Typography.Text>
                </Row>
                <Row className="d-flex justify-content-between items-center mt-10">
                  <Typography.Text className="fanPaymentMiddleLabel">{t("decrypt-menu")}</Typography.Text>
                  <Typography.Text className="fanPaymentMiddleLabel">{paymentAmount.toFixed(2)} €</Typography.Text>
                </Row>
              </div>
            </Row>
            <Row className="fanPaymentMainContent">
              <Row className="w-full">
                <Space className="w-full" direction="vertical">
                  <Typography.Text className="fanPaymentLargeLabel">{t("email-label")}</Typography.Text>
                  <InputComponent
                    className="authInput"
                    placeholder={t("email-label")!}
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Space>
              </Row>
              <Row className="w-full mt-20">
                <Space className="w-full" direction="vertical">
                  <Typography.Text className="fanPaymentLargeLabel">{t("payment-method")}</Typography.Text>
                  <Radio.Group onChange={(e) => setPaymentMethod(e.target.value)} className="w-full" defaultValue={paymentTypes[0]?.value}>
                    <Space className="w-full" direction="vertical">
                      {paymentTypes.map((type, index) => {
                        return <FanPaymentRadioButtonComponent key={index} name={type?.name} suffix={type?.suffix} value={type?.value} />;
                      })}

                      {applePay && applePay._canMakePaymentAvailability.APPLE_PAY === true
                        ? paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />
                        : ""}
                    </Space>
                  </Radio.Group>
                </Space>
              </Row>
              <div></div>
              {paymentMethod === "bankCard" && payemntGateway === "shift4" && (
                <>
                  <Row className="w-full mt-20">
                    <Space className="w-full" direction="vertical">
                      <Typography.Text className="fanPaymentLargeLabel">{t("card-number")}</Typography.Text>
                      <InputComponent
                        maxLength={19}
                        className="authInput"
                        onChange={(e) => {
                          if (e.target.value.match(cardnumberReg)) {
                            setCardNumber(e.target.value);
                          }
                        }}
                        value={cardNumber}
                        placeholder="1234 1234 1234 1234"
                        onKeyDown={(e) => setKey(e.key)}
                        inputMode="numeric"
                      />
                    </Space>
                  </Row>
                  <Row className="w-full mt-20" gutter={5}>
                    <Col span="12" className="w-100">
                      <Space direction="vertical" className="w-full">
                        <Typography.Text className="fanPaymentLargeLabel">{t("expiration-date")}</Typography.Text>
                        <InputComponent
                          className="authInput"
                          placeholder="MM/YY"
                          inputMode="numeric"
                          maxLength={5}
                          onChange={(e) => {
                            if (e.target.value.match(CardMonthYearReg)) {
                              const [month, year] = e.target.value.split("/");
                              const inputYear = parseInt(year, 10);
                              const inputMonth = parseInt(month, 10);

                              setMonthYear(e.target.value);
                              if (inputYear) {
                                if (inputYear >= expCurrentYear && inputMonth <= 12) {
                                  setMonthYear(e.target.value);
                                  setError("");
                                } else {
                                  setError("Enter Valid Expiry Month and Year");
                                }
                              }
                            }
                          }}
                          value={MonthYear}
                          onKeyDown={(e) => setKey(e.key)}
                        />
                      </Space>
                    </Col>
                    <Col span="12">
                      <Space direction="vertical" className="FanpaymentCvvInput">
                        <Typography.Text className="fanPaymentLargeLabel">{t("cryptogram")}</Typography.Text>
                        <InputComponent
                          className="authInput"
                          placeholder="CVC"
                          inputMode="numeric"
                          onChange={(e) => {
                            if (e.target.value.match(cvvReg)) {
                              setCvv(e.target.value);
                            }
                          }}
                          value={cvv}
                          maxLength={3}
                        />
                      </Space>
                    </Col>
                  </Row>
                  <Row className="mt-20">
                    <Checkbox className="paymentTermsCheckBox" checked={checkboxChecked} value={checkboxChecked} onChange={handleCheckboxChange}>
                      {t("payment-terms")}
                    </Checkbox>
                  </Row>
                  <Row className="fanSubscribeContinueButtonWrap mt-20">
                    <AuthButtonComponent onClick={() => subscribe(null)} label={t("buy")} disabled={!buy} />
                  </Row>
                  <Row className="mt-20 w-full justify-content-center">
                    <Typography.Text className="transaction-text">{t("transaction-appear-bank-statement-universal-media")}</Typography.Text>
                  </Row>

                  <Row className="mt-20 w-full justify-content-center">
                    <img src={InfoPaymentImg} width={200} alt="" />
                  </Row>
                  <Row className="mt-20 w-full justify-content-center">
                    <Typography.Text className="info-company-text">ITDW SRL</Typography.Text>
                  </Row>
                  <Row className="w-full justify-content-center">
                    <Typography.Text className="info-company-text">VAT: BE0789767565</Typography.Text>
                  </Row>
                  <Row className="w-full justify-content-center">
                    <Typography.Text className="info-company-text">6280, Gerpinnes, Belgium</Typography.Text>
                  </Row>
                </>
              )}

              {payemntGateway === "stripe" && (
                <>
                  <StripeCheckoutForm
                    checkboxChecked={checkboxChecked}
                    handleCheckboxChange={handleCheckboxChange}
                    handlePayment={subscribe}
                    loading={isLoading}
                    setLoading={setIsLoading}
                    paymentAmount={() => {
                      return paymentAmount.toFixed(2);
                    }}
                  />
                </>
              )}
            </Row>
          </Col>
        </Layout.Content>
        <FanPaymentPopUp paymentStatus={paymentStatus} setPaymentStatus={setpaymentStatus} username="" />
      </Layout>
    </Spin>
  );
};

export default DecryptPaymentPage;
