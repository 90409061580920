import type { InputRef, MenuProps } from "antd";
import { Button, Dropdown, message } from "antd";
import React, { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { ICountry } from "../../helpers/types";
import { utilService } from "../../services";
import countryUtil, { COUNTRIES } from "../../utls/CountryUtil";
import InputCompnent from "../InputComponent";
import PhoneCodeComponent from "../PhoneCodeComponent";
import { t } from "i18next";

type props = {
  className?: string;
  countryCode?: string | null;
  phoneCode?: string | null;
  phone?: string | null;
  onChangePhoneCode?: (phoneCode: string, countryCode: string) => void;
  onChangePhone?: ChangeEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
};

const FranceCode = "FR";

const PhoneInputComponent: React.FC<props> = ({ className, countryCode, phoneCode, phone, onChangePhoneCode, onChangePhone, autoFocus = false }) => {
  const [countries, setCountries] = useState<ICountry[] | null>(COUNTRIES);
  const [items, setItems] = useState<MenuProps["items"]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const phoneInputRef = useRef<InputRef | null>(null);

  const itemClicked = (countryCode: string) => {
    if (!countries) return;
    const country = countryUtil.getCountryFromCode(countries, countryCode);
    setSelectedCountry(country);
  };

  useEffect(() => {
    if (!selectedCountry || !onChangePhoneCode) return;
    onChangePhoneCode(selectedCountry.dial_code, selectedCountry.code);
  }, [selectedCountry]);

  useEffect(() => {
    const newItems = countries?.map((country) => {
      return {
        key: country.code,
        label: (
          <PhoneCodeComponent
            className={className}
            flag={country.flag}
            name={country.name}
            code={country.dial_code}
            countryCode={country.code}
            onClick={itemClicked}
            showName={true}
          />
        ),
      };
    });
    setItems(newItems);

    let defaultCountry = null;
    let defaultCountryCode = countryCode || FranceCode;
    if (countries && countries?.length > 0) defaultCountry = countryUtil.getCountryFromCode(countries, defaultCountryCode);
    setSelectedCountry(defaultCountry);
  }, [countries, countryCode]);

  // useEffect(() => {
  //   utilService
  //     .getPhoneCoutries()
  //     .then((data: ICountry[]) => setCountries(data))
  //     .catch((error) => {
  //       message.error(error || t("something-want-wrong"));
  //     });
  // }, []);

  useEffect(() => {
    if (autoFocus && phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, [autoFocus]);

  return (
    <div className={`phoneCodeInput d-flex items-center w-full gap-7 ${className}`}>
      <Dropdown overlayClassName={`phoneCodeDropDown dark`} menu={{ items }} placement="bottomLeft" trigger={["click"]}>
        <Button
          className={
            className === "authDark"
              ? "authPhoneDesign"
              : className === "dark"
                ? "lightBlackButton  w-unset pt-8 pb-8"
                : className === "fan-phone-modal"
                  ? "fan-phone-modal-input"
                  : ""
          }
        >
          <PhoneCodeComponent
            className={className === "fan-phone-modal" ? "fan-phone-modal-input" : className}
            flag={selectedCountry?.flag || ""}
            name={selectedCountry?.name || ""}
            code={selectedCountry?.dial_code || ""}
            countryCode={selectedCountry?.code || ""}
          />
        </Button>
      </Dropdown>
      <InputCompnent
        ref={phoneInputRef}
        className={
          className === "authDark"
            ? "authPhoneDesign"
            : className === "dark"
              ? "darkInput"
              : className === "fan-phone-modal"
                ? "fan-phone-modal-input"
                : ""
        }
        placeholder={t("number")!}
        type="tel"
        value={phone || ""}
        onChange={onChangePhone}
      />
    </div>
  );
};

export default PhoneInputComponent;
