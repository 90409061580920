import { PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest } from "@stripe/stripe-js";
import { Checkbox, Col, Layout, Radio, Row, Space, Spin, Typography, message } from "antd";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NewArrowLeftIcon from "../../../assets/icons/new-arrow-left.svg";
import LockIcon from "../../../assets/icons/payment-lock.svg";
import InfoPaymentImg from "../../../assets/images/info-payment.webp";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import CountryDropdownComponent from "../../../components/CountryDropdownComponent/CountryDropdownComponent";
import FanPaymentPopUp from "../../../components/FanPaymentPopUp";
import InputComponent from "../../../components/InputComponent";
import ConfirmationEventPaymentModal from "../../../components/LiveEventComponent/ConfirmationEventPaymentModal";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { IStripeLivePayload } from "../../../helpers/types";
import { updateLivePayment } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { authService, liveService, userService, utilService } from "../../../services";
import { settingService } from "../../../services/settings.service";
import { PAYMENT_ERROR_MESSAGES } from "../../../utls/constants";
import { formatCardNumber } from "../../../utls/FunctionsUtil";
import "./index.less";
import StripeCheckoutForm from "./StripeCheckoutForm";

interface ApplePayData {
  _canMakePaymentAvailability: any;
}

const FanPaymentRTMPPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromDirectStreamPage = searchParams.get("fln");
  const [Key, setKey] = useState("");
  const shouldFetchCountry = useRef(true);
  const user = useSelector((state: StoreState) => state.users.item);
  const dispatch = useDispatch<AppDispatch>();
  const stripe = useStripe();
  const elements = useElements();
  const [NetPaymentAoumt, setNetPaymentAoumt] = useState<number>(0);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | any>();
  const [paymentStatus, setpaymentStatus] = useState<string>();
  const [showApplePay, setShowApplyPay] = useState<string | any>(undefined);
  const [liveDetails, setLiveDetails] = useState<any>(null);
  const [applePay, setApplePay] = useState<ApplePayData>();
  // card details
  const [cardNumber, setCardNumber] = useState("");
  const [MonthYear, setMonthYear] = useState("");
  const [cardHolderName, setCardHolderName] = useState<any>({ firstname: "", lastname: "" });
  const [cvv, setCvv] = useState("");
  const [error, setError] = useState("");
  // card validation
  const cardnumberReg = /^[0-9\s]*$/;
  const CardMonthYearReg = /^[0-9/]*$/;
  const [buy, setBuy] = useState(false);
  const cvvReg = /^\d*$/;
  const currentYear = new Date().getFullYear().toString().substring(2);
  const expCurrentYear = parseInt(currentYear, 10);
  const [month, year] = MonthYear.split("/");
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // check box
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [paymentCheckBox, setPaymentCheckBox] = useState(true);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState<any[]>([]);
  const [vatCountries, setVatCountries] = useState<any[]>([]);
  const [vatPercentage, setVatPercentage] = useState<any>(0);
  const [payemntGateway, setPayemntGateway] = useState<any>("shift4");
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const userCountry = Cookies.get("country");

  // choosed plan
  useEffect(() => {
    liveService
      .getRtmpLive()
      .then((data: any) => setLiveDetails(data))
      .catch((error) => console.log(error));
    getCurrentPaymentGateway();
    getMe();
  }, []);

  const getMe = async () => {
    await userService.getMyProfile().then((res: any) => {
      setCardHolderName({ firstname: res?.firstName, lastname: res?.lastName });
    });
  };

  if (Key !== "Backspace") {
    if (cardNumber.length === 4 || cardNumber.length === 9 || cardNumber.length === 14) {
      setCardNumber(cardNumber + " ");
    }
    if (MonthYear.length === 2) {
      setMonthYear(MonthYear + "/");
    }
  }

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  useEffect(() => {
    userService
      .getVatCountries()
      .then((data: any) => {
        setVatCountries(data?.list);
      })
      .catch((error: any) => {
        message.error(error || t("something-went-wrong"));
      });
    utilService
      .getPhoneCoutries()
      .then((data: any) => {
        setCountries(data);
      })
      .catch((error: any) => {
        message.error(error || t("something-went-wrong"));
      });
  }, []);

  useEffect(() => {
    if (userCountry && userCountry !== null) {
      setCountry(userCountry);
      const selected = vatCountries?.find((item: any) => item.country === userCountry);
      if (selected) {
        setVatPercentage(selected?.vat);
      } else {
        setVatPercentage(0);
      }
    } else {
      if (vatCountries && countries && !country) {
        if (shouldFetchCountry.current) {
          shouldFetchCountry.current = false;
          utilService.fetchUserCountry().then((res: any) => {
            if (res) {
              setCountry(res);
              Cookies.set("country", res, { expires: 1 });
            }
          });
        }
      }
    }
  }, [vatCountries, countries, userCountry]);

  // apply pay
  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    setPaymentRequest(null);
    const applePay = async (Amount: number) => {
      let label = `Payment to $creatorId by ${authService.getId()}`;
      let currency = "eur";
      let country = "BE";

      const pr = stripe.paymentRequest({
        currency: currency,
        country: country,
        requestPayerEmail: true,
        requestPayerName: true,
        total: {
          label: label,
          amount: Amount,
        },
      });

      await pr.canMakePayment().then(async (result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      await pr.on("paymentmethod", async (event) => {
        const paymentMethod = event.paymentMethod;

        if (paymentMethod) {
          const paymentMethodId = event.paymentMethod.id;
          // for Server side Stripe-API handling of payment
        }
        event.complete("success"); // Completes the transaction and closes Google/Apple pay
      });
    };

    applePay(NetPaymentAoumt);
  }, [stripe, elements, NetPaymentAoumt]);

  useEffect(() => {
    // THIS WILL RUN WHEN THERE'S A CHANGE IN 'quotes'
    setShowApplyPay(JSON.stringify(paymentRequest));
  }, [paymentRequest]);

  useEffect(() => {
    if (showApplePay) {
      setApplePay(JSON.parse(showApplePay));
    }
  }, [showApplePay]);

  const livePaymentDetails: IStripeLivePayload = {
    liveId: liveDetails?._id!,
    cardNumber: parseInt(cardNumber.replace(/\s/g, "")),
    expiryMonth: parseInt(month),
    expiryYear: 2000 + parseInt(year),
    cvv: parseInt(cvv),
    paymentGateway: payemntGateway,
    country: country,
    cardHolderName: `${cardHolderName?.firstname} ${cardHolderName?.lastname}`,
    savedCard: paymentCheckBox,
    isCardToken: false,
    token: "",
  };

  useEffect(() => {
    if (checkboxChecked && country) {
      setBuy(true);
    } else {
      if (
        cardNumber.length === 19 &&
        cvv.length === 3 &&
        MonthYear.length === 5 &&
        checkboxChecked &&
        country &&
        cardHolderName.firstname &&
        cardHolderName.lastname
      ) {
        setBuy(true);
      } else {
        setBuy(false);
      }
    }
  }, [cardNumber, cvv, MonthYear, checkboxChecked, country, cardHolderName.firstname, cardHolderName.lastname]);

  useEffect(() => {
    if (country) {
      const selected = vatCountries?.find((item: any) => item.country === country);
      if (selected) {
        setVatPercentage(selected?.vat);
      } else {
        setVatPercentage(0);
      }
    }
  }, [country]);

  const handleNavigate = () => {
    return navigate(-1);
  };

  const calculateAmount = () => {
    let baseAmount = liveDetails?.amount || 0;
    return baseAmount?.toFixed(2);
  };

  const getCurrentPaymentGateway = async () => {
    await settingService.getPayentGateway().then((res: any) => {
      setPayemntGateway(res?.stream);
    });
  };

  const formatNumber = (number: string) => {
    return number
      .replace(/\s?/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  useEffect(() => {
    const formattedCardNumber = formatCardNumber(cardNumber);
    if (formattedCardNumber !== cardNumber) {
      setCardNumber(formattedCardNumber);
    }
  }, [cardNumber]);

  // subscribe btn
  const subscribe = (token: any) => {
    let mainAmount = calculateAmount();
    if (!mainAmount || Number(mainAmount) < 1) {
      message.error(t("no-amount"));
      setIsLoading(false);
      return;
    }

    if (payemntGateway !== "stripe") {
      if (!cardNumber || !cvv || !MonthYear || !livePaymentDetails.country) {
        const errorIn: string[] = [];
        if (!cardNumber) {
          errorIn.push("cardNumber");
        }
        if (!cvv) {
          errorIn.push("cvv");
        }
        if (!MonthYear) {
          errorIn.push("monthYear");
        }
        if (!livePaymentDetails.country) {
          errorIn.push("country");
        }
        setErrorFields(errorIn);
        return message.warning(t("fill-all-fields"));
      }
      if (livePaymentDetails.country === "Pays") {
        setErrorFields(["country"]);
        return message.warning(t("country-required"));
      }
      if (cvv.length < 3) {
        setErrorFields(["cvv"]);
        return message.warning(t("valid-cvv"));
      }
    }
    if (error) {
      setIsLoading(false);
      return message.warning(error);
    }
    setIsLoading(true);
    if (livePaymentDetails.liveId) {
      liveService.stripeLive({ ...livePaymentDetails, token }).then((res: any) => {
        setIsLoading(false);
        if (res.statusCode === 400 && PAYMENT_ERROR_MESSAGES.includes(res.message)) {
          return message.error(t(res.message));
        }
        if (res?.transaction?.redirectUrl) {
          const { token, transactionId, redirectUrl } = res.transaction;
          Cookies.set(
            "subscription_data",
            JSON.stringify({
              token: token?.id,
              transactionId,
              creatorId: liveDetails?.creator.creatorId,
              planId: "",
              type: "live",
              username: user?.username,
              liveId: livePaymentDetails.liveId,
              isRTMP: true,
              isLiveStarted: fromDirectStreamPage !== undefined && fromDirectStreamPage !== "",
              isScheduled: "schedule",
            })
          );
          window.location.href = redirectUrl;
        } else {
          if (res?.transaction?.status === "success") {
            setpaymentStatus(res?.transaction?.status);
            dispatch(updateLivePayment({ id: livePaymentDetails.liveId, isSuggested: false }));
          } else {
            setpaymentStatus("error");
          }
        }
      });
    }
  };

  const handleCancelModal = () => {
    if (fromDirectStreamPage && fromDirectStreamPage === "rtlf") {
      navigate(`/fan/live/${livePaymentDetails.liveId}?from=paymentPage`);
    } else {
      navigate("/p/fan/lives?schedule");
    }
  };

  return (
    <Spin spinning={isLoading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout>
        <Layout.Content className="content fanSubscribeContentWrap gradient-background">
          <Col>
            <Row>
              {/* <div className="fanSubscribeHeaderBg"></div> */}
              <div className="fanSubscribeHeaderContent">
                <Row className="relative justify-content-center">
                  {/* <ArrowLeftIconComponent top={6} navigateTo={handleNavigate()} /> */}
                  <img className="header-arrow-left" src={NewArrowLeftIcon} alt="arrow left" style={{ top: 6 }} onClick={handleNavigate} />
                  <Row className="ml-20" align="middle">
                    <img src={LockIcon} width={20} alt="arrow left" />
                    <Typography.Text className="ml-10 header-title-black-28">{t("secure-payment")}</Typography.Text>
                  </Row>
                </Row>
                <Row className="mt-20">
                  <Typography.Text className="fanPaymentRecapLabel">{t("summary")}</Typography.Text>
                </Row>
                <Row className="mt-20">
                  <Typography.Text className="fanPaymentMiddleLabel">{t("rtmp-summary")}</Typography.Text>
                </Row>
                <Row className="d-flex justify-content-between items-center mt-10">
                  <Typography.Text className="fanPaymentMiddleLabel">{t("rtmp-fighters")}</Typography.Text>
                  <Typography.Text className="fanPaymentMiddleLabel">{liveDetails?.amount} €</Typography.Text>
                </Row>
                {country && vatPercentage !== 0 && (
                  <Row className="d-flex justify-content-between items-center mt-3">
                    <Typography.Text className="fanPaymentMiddleLabel tva">{t("including-vat")}</Typography.Text>
                    <Typography.Text className="fanPaymentMiddleLabel tva">
                      {NetPaymentAoumt
                        ? ((NetPaymentAoumt * vatPercentage) / 100).toFixed(2)
                        : (((liveDetails?.amount || 0) * vatPercentage) / 100).toFixed(2)}
                      €
                    </Typography.Text>
                  </Row>
                )}
              </div>
            </Row>
            <Row className="fanPaymentMainContent">
              <Row className="w-full">
                <Space className="w-full" direction="vertical">
                  {/* <Typography.Text className="fanPaymentLargeLabel">{t("payment-method")}</Typography.Text> */}
                  <Radio.Group onChange={(e) => setPaymentMethod(e.target.value)} className="w-full">
                    <Space className="w-full" direction="vertical">
                      {/* {paymentTypes.map((type, index) => {
                        return <FanPaymentRadioButtonComponent key={index} name={type.name} value={type.value} suffix={type.suffix} />;
                      })} */}

                      {applePay && applePay?._canMakePaymentAvailability.APPLE_PAY === true
                        ? paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />
                        : ""}
                    </Space>
                  </Radio.Group>
                </Space>
              </Row>
              <Row className="w-full ">
                <Space className="w-full" direction="vertical">
                  <Typography.Text className="fanPaymentLargeLabel">{t("country")}</Typography.Text>
                  <CountryDropdownComponent
                    currentValue={country}
                    setCurrentValue={setCountry}
                    countries={countries || []}
                    error={errorFields.includes("country")}
                  />
                </Space>
              </Row>
              {payemntGateway === "shift4" && (
                <>
                  <Row className="w-full mt-20">
                    <Space className="w-full" direction="vertical">
                      <Typography.Text className="fanPaymentLargeLabel">{t("card-number")}</Typography.Text>
                      <InputComponent
                        maxLength={19}
                        className="authInput"
                        onChange={(e) => {
                          if (e.target.value.match(cardnumberReg)) {
                            setCardNumber(formatNumber(e.target.value));
                          }
                        }}
                        value={cardNumber}
                        placeholder="1234 1234 1234"
                        onKeyDown={(e) => setKey(e.key)}
                        inputMode="numeric"
                        status={errorFields.includes("cardNumber") ? "error" : undefined}
                      />
                    </Space>
                  </Row>
                  <Row className="w-full mt-20 mb-20">
                    <Col span={12} className="custom-col">
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Typography.Text className="fanPaymentLargeLabel">{t("expiration-date")}</Typography.Text>
                        <InputComponent
                          className="authInput"
                          inputMode="numeric"
                          placeholder="MM/YY"
                          maxLength={5}
                          onChange={(e) => {
                            if (e.target.value.match(CardMonthYearReg)) {
                              const [month, year] = e.target.value.split("/");
                              const inputYear = parseInt(year, 10);
                              const inputMonth = parseInt(month, 10);

                              setMonthYear(e.target.value);
                              if (inputYear) {
                                if (inputYear >= expCurrentYear && inputMonth <= 12) {
                                  setMonthYear(e.target.value);
                                  setError("");
                                } else {
                                  setError("Enter Valid Expiry Month and Year");
                                }
                              }
                            }
                          }}
                          value={MonthYear}
                          onKeyDown={(e) => setKey(e.key)}
                          status={errorFields.includes("monthYear") ? "error" : undefined}
                        />
                      </Space>
                    </Col>
                    <Col span={12} className="custom-col">
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <Typography.Text className="fanPaymentLargeLabel">{t("cryptogram")}</Typography.Text>
                        <InputComponent
                          className="authInput"
                          inputMode="numeric"
                          placeholder="CVC"
                          onChange={(e) => {
                            if (e.target.value.match(cvvReg)) {
                              setCvv(e.target.value);
                            }
                          }}
                          value={cvv}
                          maxLength={3}
                          status={errorFields.includes("cvv") ? "error" : undefined}
                        />
                      </Space>
                    </Col>
                  </Row>

                  <Row className="">
                    <Checkbox className="paymentTermsCheckBox" checked={checkboxChecked} onChange={handleCheckboxChange}>
                      {t("payment-terms")}
                    </Checkbox>
                  </Row>
                  <Row className="mt-20 w-full justify-content-center">
                    <Typography.Text className="transaction-text">{t("transaction-appear-bank-statement-universal-media")}</Typography.Text>
                  </Row>

                  <Row className="mt-20 w-full justify-content-center">
                    <img src={InfoPaymentImg} width={200} alt="" />
                  </Row>
                  <Row className="mt-10 w-full justify-content-center">
                    <Typography.Text className="info-company-text">ITDW SRL</Typography.Text>
                  </Row>
                  <Row className="w-full justify-content-center">
                    <Typography.Text className="info-company-text">VAT: BE0789767565</Typography.Text>
                  </Row>
                  <Row className="w-full justify-content-center mb-60">
                    <Typography.Text className="info-company-text">6280, Gerpinnes, Belgium</Typography.Text>
                  </Row>
                  <Row className="fanSubscribeContinueButtonWrap stickyButtonWrapper">
                    <div className="w-button">
                      <AuthButtonComponent onClick={() => subscribe(null)} label={t("confirm")} disabled={!buy} />
                    </div>
                  </Row>
                </>
              )}
              {payemntGateway === "stripe" && (
                <StripeCheckoutForm
                  setLoading={setIsLoading}
                  checkboxChecked={checkboxChecked}
                  handleCheckboxChange={handleCheckboxChange}
                  loading={isLoading}
                  handlePayment={subscribe}
                  paymentAmount={calculateAmount}
                />
              )}
            </Row>
          </Col>
          {paymentStatus && paymentStatus === "error" ? (
            <FanPaymentPopUp paymentStatus={paymentStatus} setPaymentStatus={setpaymentStatus} username={user?.username!} />
          ) : (
            <ConfirmationEventPaymentModal paymentStatus={paymentStatus} onClose={handleCancelModal} />
          )}
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default FanPaymentRTMPPage;
