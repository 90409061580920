import { Layout, Spin } from "antd";
import EventImg from "../../../../assets/images/live-fight-event/live-fight-event-3.webp";
import InfoTicketLiveEventComponent from "../../../../components/LiveEventComponent/InfoTicketLiveEventComponent";
import { useEffect, useState } from "react";
import ArrowLeftIconComponent from "../../../../components/ArrowLeftIconComponent";
import "./index.less";
import { AppDispatch, StoreState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { liveService } from "../../../../services";
import { getRtmpLive } from "../../../../redux/lives/livesSlice";
import SpinnerComponent from "../../../../components/SpinnerComponent/SpinnerComponent";
import Cookies from "js-cookie";

const FanLiveEventTicketPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {rtmpLive, loading} = useSelector((state: StoreState) => state.lives);
  
  useEffect(() => {
    if(!rtmpLive) {
      dispatch(getRtmpLive());
    }
  }, [])

  const reserveTicket = () => {
    navigate('/common/event-payment');
  };

  const handleNavigateToPaymentPage = () => {
    Cookies.remove('ffe');
    navigate('/common/event-payment');
    return null;
  }

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
    <Layout>
      {
        rtmpLive && !rtmpLive.isPaymentVerified && Cookies.get('ffe') ? handleNavigateToPaymentPage() 
        : <Layout.Content className="event-ticket-wrapper">
          <ArrowLeftIconComponent top={20} left={15} navigateTo="/p/fan/lives?schedule" />
          <img className="event-img" src={EventImg} alt="Fight" />
          <InfoTicketLiveEventComponent alreadyReserved={!!rtmpLive?.isPaymentVerified} onButtonClick={reserveTicket} />
      </Layout.Content> 
      }

    </Layout>
    </Spin>
  );
};

export default FanLiveEventTicketPage;
