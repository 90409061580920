import { Navigate } from "react-router-dom";
import { messageService } from "../../../services";
import { useEffect, useState } from "react";

function FanHomeMessagerieWrapper() {
  const [totalNotReadMessages, setTotalNotReadMessages] = useState<number | null>(null);

  useEffect(() => {
    fetchUnreadMessages();
  }, []);

  const fetchUnreadMessages = async () => {
    try {
      const total = await messageService.getTotalNotReadMessages();
      if (total && total !== "") {
        setTotalNotReadMessages(parseInt(total, 10));
      } else {
        setTotalNotReadMessages(0);
      }
    } catch (error) {
      console.error(error);
      setTotalNotReadMessages(0);
    }
  };

  if (totalNotReadMessages === null) {
    return null;
  }

  if (totalNotReadMessages && totalNotReadMessages > 0) {
    return <Navigate to="/fan/message" replace />;
  } else {
    return <Navigate to="/fan/home" replace />;
  }
}

export default FanHomeMessagerieWrapper;
