import { Button, Col, Input, Layout, Row, Space, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../assets/icons/info-white.svg";
import CrossIcon from "../../../assets/icons/white-large-cross.svg";
import LingotImg from "../../../assets/images/lingot.webp";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AvatarComponent from "../../../components/AvatarComponent";
import SponsorshipInfoModalComponent from "../../../components/SponsorshipInfoModalComponent";
import { ISponsorship } from "../../../helpers/types";
import { userService } from "../../../services";
import { copyToClipboard } from "../../../utls/FunctionsUtil";
import "./index.less";

const CreatorSponsorshipPage = () => {
  const shouldFetch = useRef(true);
  const [sponsorshipData, setSponsorshipData] = useState<ISponsorship>({});
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      userService
        .creatorSponsorship()
        .then((res) => {
          setSponsorshipData(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const sponsorshipLinkCopy = async () => {
    await copyToClipboard(sponsorshipData?.link!);
    setCopied(true);
    return message.success(t("copied-the-link-to-clipboard"));
  };

  if (sponsorshipData.report) {
    for (let i = 0; i < sponsorshipData.report.length; i++) {
      const item = sponsorshipData.report[i];
      if (item.creator && item.creator.createdAt) {
        const createdAt = new Date(item.creator.createdAt);
        item.creator.createdAt = createdAt.toLocaleDateString("en-US", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        });
      }
    }
  }

  return (
    <Layout className="gradient-background-blue-settings">
      <Layout.Content className="content creatorSponsorshipContentWrapper background-transparent">
        <Row className="relative justify-content-center header-title-container-60">
          <ArrowLeftIconComponent top={10} />
          <Typography.Text className="header-title-black-35">{t("sponsorship")}</Typography.Text>
        </Row>
        <Row className="w-full justify-content-center">
          <img className="imgLingot" src={LingotImg} alt="" />
        </Row>
        <Col className="price-container">
          <Row>
            <Typography.Text className="font-12-regular text-grey-color">{t("you-have-won")}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text className="font-36-black text-white-color">{sponsorshipData && sponsorshipData.totalGrossPrice} €</Typography.Text>
          </Row>
          <Row className="mt-10">
            <Typography.Text className="font-12-regular text-grey-color">{t("for-sponsoring-designers-on-Loly")}</Typography.Text>
          </Row>
        </Col>
        <Row className="mt-40">
          <Space>
            <Typography.Text className="font-30-bold text-white-color">{t("creator-link")}</Typography.Text>
            <img src={InfoIcon} width={14} onClick={() => setOpenModal(true)} alt="Loly - info icon" />
          </Space>
        </Row>
        <Row className="sponsorship">
          <div className="sponsorshipLink">
            <Input className="sponsorshipContent" value={sponsorshipData.link} disabled={true} />
            <Button className="sponsorshipLinkCopy" onClick={sponsorshipLinkCopy}>
              {copied ? t("link-copied") : t("copy")}
            </Button>
          </div>
        </Row>
        <Row>
          <Typography.Text className="font-30-bold text-white-color">{t("your-creator")}</Typography.Text>
        </Row>
        <Row className="mt-21 w-full gap-10">
          {sponsorshipData.report && sponsorshipData.report.length > 0 ? (
            sponsorshipData.report.map((item) => {
              return (
                <Row className="creator-container" key={item.creator?._id}>
                  <div className="flex-container">
                    <AvatarComponent image={item.creator?.avatarPath} size={90} />
                    <div className="flex-column">
                      <Row>
                        <Typography.Text className="font-12-light text-white-color">
                          {t("sponsored-on")} {item.creator?.createdAt}
                        </Typography.Text>
                      </Row>
                      <Row className="mt-2">
                        <Typography.Text className="user-name-text typography-text">{item.creator?.username}</Typography.Text>
                      </Row>
                      <Row className="mt-5">
                        <Typography.Text className="font-16-regular text-white-color">{item.earning} €</Typography.Text>
                      </Row>
                    </div>
                    <div className="chevron-right" onClick={() => setOpenModal(true)}>
                      <img width={25} src={CrossIcon} />
                    </div>
                  </div>
                </Row>
              );
            })
          ) : (
            <Row className="w-full justify-content-center mt-20">
              <Typography.Text className="font-14-light text-white-color text-center">{t("havent-sponsored-any-designers")}</Typography.Text>
            </Row>
          )}
        </Row>
        <SponsorshipInfoModalComponent isOpen={openModal} onClose={() => setOpenModal(false)} />
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorSponsorshipPage;
