import { Button, Row, Space, Spin, Typography, UploadFile } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StatusBlackIcon from "../../assets/icons/status-circle-black.svg";
import { ACCEPTED, FILE, MAX_MEDIAS_TO_SEND, PENDING, REJECTED, THUMBNAIL_TYPES } from "../../helpers/constant";
import { IMessage } from "../../helpers/types";
import { UploadedPart } from "../../pages/CreatorPages/CreatorCreateDecryptPage/CreatorCreateDecryptPage";
import { mediapushService } from "../../services";
import { decryptService } from "../../services/decrypt.service";
import { socket } from "../../socket";
import AddMediaComponent from "../AddMediaComponent";
import MediaComponent from "../MediaComponent";
import MessagePriceComponent from "../MessagePriceComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";

type props = {
  message: IMessage;
  expired?: boolean;
  hideStatus?: boolean;
  isBlur?: boolean;
  displayVideo?: boolean;
};

const MessageMediaItemComponent: React.FC<props> = ({ message, expired, hideStatus, isBlur = true, displayVideo = false }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  useEffect(() => {
    function onProgressUpdate(data: number) {
      setProgress((prev) => {
        const prog = prev + 1;
        if (prog <= 99) {
          return prog;
        }
        return prev;
      });
    }

    function onProcessEnd() {
      setLoading(false);
    }

    socket.on("file-updated", onProcessEnd);
    socket.on("progress-update", onProgressUpdate);

    return () => {
      socket.off("file-updated", onProcessEnd);
      socket.off("progress-update", onProgressUpdate);
    };
  }, []);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress / 2);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFiles(fileList);
  };

  // const handleSendMedias = async () => {
  //   if (!isEmpty(files) && !loading) {
  //     setLoading(true);
  //     const formData = new FormData();
  //     formData.set("messageId", message._id!);
  //     formData.set("mediaPushId", message.mediaPushId!);
  //     const videoFileToChunk: any[] = [];

  //     files.forEach((file: any) => {
  //       if (file?.originFileObj?.type?.includes("image/")) {
  //         formData.append(`files`, file.originFileObj as any);
  //       } else {
  //         videoFileToChunk.push(file);
  //       }
  //     });

  //     if (videoFileToChunk.length > 0) {
  //       const videoFiles: any[] = [];
  //       const totalSize = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
  //       setTotalSize(totalSize);
  //       let totalUploadedBytes = { value: 0 };
  //       for (let j = 0; j < videoFileToChunk.length; j++) {
  //         const file = videoFileToChunk[j];
  //         const { uploadId, filename } = await decryptService.initializeMultiPartUpload({
  //           filename: file.name,
  //           contentType: file.type,
  //         });

  //         const chunkSize = 5 * 1024 * 1024; // 5MB chunks
  //         const chunks = Math.ceil(file.size / chunkSize);
  //         const uploadPromises: Promise<UploadedPart>[] = [];

  //         for (let i = 0; i < chunks; i++) {
  //           const start = i * chunkSize;
  //           const end = Math.min(file.size, start + chunkSize);
  //           const chunk = file.originFileObj.slice(start, end);
  //           uploadPromises.push(uploadChunk(chunk, i + 1, uploadId, filename, totalUploadedBytes));
  //         }

  //         const parts = await Promise.all(uploadPromises);

  //         const result = await decryptService.finalizeMultipartUpload(uploadId, parts, filename);

  //         videoFiles.push({
  //           Location: result.Location,
  //           isPrivate: file.isPrivate,
  //           Key: result.Key,
  //           ETag: result.ETag,
  //           Bucket: result.Bucket,
  //           filename: file.name,
  //           size: file.size,
  //         });
  //       }

  //       formData.append("videoFiles", JSON.stringify(videoFiles));
  //     }

  //     await mediapushService.updateMediaPushFiles(formData).then(() => {
  //       setLoading(false);
  //     });
  //   }
  // };

  const handleSendMedias = async () => {
    if (!isEmpty(files) && !loading) {
      setLoading(true);
      const formData = new FormData();
      formData.set("messageId", message._id!);
      formData.set("mediaPushId", message.mediaPushId!);
      const videoFileToChunk: any[] = [];

      files.forEach((file: any) => {
        if (file?.originFileObj?.type?.includes("image/")) {
          formData.append(`files`, file.originFileObj as any);
        } else {
          videoFileToChunk.push(file);
        }
      });

      if (videoFileToChunk.length > 0) {
        const videoFiles: any[] = [];
        const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
        setTotalSize(totalSizes);
        let totalUploadedBytes = { value: 0 };

        for (let i = 0; i < videoFileToChunk.length; i++) {
          const file = videoFileToChunk[i];
          const { uploadId, filename } = await decryptService.initializeMultiPartUpload({
            filename: file.name,
            contentType: file.type,
          });

          const chunkSize = 5 * 1024 * 1024; // 5MB chunks
          const chunks = Math.ceil(file.size / chunkSize);

          // Helper function to upload a batch of chunks
          const uploadChunkBatch = async (startIndex: number, batchSize: number) => {
            const uploadPromises: Promise<UploadedPart>[] = [];

            for (let i = startIndex; i < Math.min(startIndex + batchSize, chunks); i++) {
              const start = i * chunkSize;
              const end = Math.min(file.size, start + chunkSize);
              const chunk = file.originFileObj.slice(start, end);
              uploadPromises.push(uploadChunk(chunk, i + 1, uploadId, filename, totalUploadedBytes));
            }

            // Wait for the current batch to finish uploading
            return await Promise.all(uploadPromises);
          };

          let allUploadedParts: UploadedPart[] = [];

          // Upload in batches of 6 chunks
          const batchSize = 6;
          for (let i = 0; i < chunks; i += batchSize) {
            const uploadedParts = await uploadChunkBatch(i, batchSize);
            allUploadedParts = [...allUploadedParts, ...uploadedParts];
          }

          // Finalize multipart upload after all chunks are uploaded
          const result = await decryptService.finalizeMultipartUpload(uploadId, allUploadedParts, filename);

          videoFiles.push({
            Location: result.Location,
            isPrivate: file.isPrivate,
            Key: result.Key,
            ETag: result.ETag,
            Bucket: result.Bucket,
            filename: file.name,
            size: file.size,
          });
        }

        formData.append("videoFiles", JSON.stringify(videoFiles));
      }

      await mediapushService.updateMediaPushFiles(formData).then(() => {
        setLoading(false);
      });
    }
  };

  const uploadChunk = async (
    chunk: Blob,
    partNumber: number,
    uploadId: string,
    filename: string,
    totalUploadedBytes: { value: number }
  ): Promise<UploadedPart> => {
    const formData = new FormData();
    formData.append("chunk", chunk);
    formData.append("partNumber", partNumber.toString());
    formData.append("uploadId", uploadId);
    formData.append("filename", filename);
    const data = await decryptService.uploadMultiChunk(formData, setLoadedData).then((response) => {
      //totalUploadedBytes.value += chunk.size;
      //setLoadedData(totalUploadedBytes.value);
      return response;
    });
    return { ETag: data, PartNumber: partNumber };
  };

  return (
    <Space className={`messageMediaItem ${expired ? "expired" : ""}`} direction="vertical">
      <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent progress={true} progressValue={progress} />}>
        <Row>
          <Space className="w-full gap-4" direction="vertical">
            <MessagePriceComponent message={message} income={false} />

            {message.medias && !isEmpty(message.medias) ? (
              <Row>
                {message.medias?.map((media) => {
                  let imageUrl = media.url;
                  let isVideo = false;
                  if (media.url.includes(".mp4")) {
                    imageUrl = media.thumbnails?.find((thumb: any) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath;
                    isVideo = true;
                  }
                  return (
                    <MediaComponent
                      key={media.fileId}
                      width={60}
                      height={60}
                      borderRadius={11}
                      image={imageUrl}
                      url={media.url}
                      isVideo={isVideo}
                      username={""}
                      isPrivate={media.isPrivate}
                      isBlur={isBlur}
                      shouldAddWatermark={false}
                      displayVideo={displayVideo}
                    />
                  );
                })}
              </Row>
            ) : (
              !expired &&
              message.status !== REJECTED && (
                <Row className="mt-5">
                  <AddMediaComponent
                    disabled={message.status === PENDING}
                    onFilesChange={handleFilesChange}
                    showTitle={false}
                    maxCount={MAX_MEDIAS_TO_SEND}
                  />
                  {/* <Space direction="vertical">
                    <Space className={"addMediaWrapper"} wrap>
                      <Tooltip title={t("send")}>
                        <div
                          className={`validationCircle validationBtn ${
                            isEmpty(files) || (message.status !== REJECTED && message.status === PENDING) ? "disabled" : ""
                          }`}
                          onClick={handleSendMedias}
                        >
                          <img src={CheckIcon} width={12} alt={"send"} />
                        </div>
                      </Tooltip>
                    </Space>
                  </Space> */}
                </Row>
              )
            )}
          </Space>
        </Row>
        {message.text && (
          <Row className="mt-7">
            <Typography.Text className="font-15-medium text-black-color">{message.text}</Typography.Text>
          </Row>
        )}
        {isEmpty(message.medias) && !expired && message.status !== REJECTED && (
          <Button
            className="sendMediaButton"
            onClick={handleSendMedias}
            disabled={isEmpty(files) || (message.status !== REJECTED && message.status === PENDING)}
          >
            {t("send")}
          </Button>
        )}
        {!hideStatus ? (
          <Row className="mt-10">
            <Space>
              <img src={StatusBlackIcon} alt={message.status} />
              <Typography.Text className="font-14-bold text-black-color">
                {t(message.status!)} {isEmpty(message.medias) && message.status === ACCEPTED && message.type === FILE ? `(${t(PENDING)})` : ""}
              </Typography.Text>
            </Space>
          </Row>
        ) : null}
      </Spin>
    </Space>
  );
};

export default MessageMediaItemComponent;
