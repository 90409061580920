import React from "react";
import { ILive } from "../../../helpers/types";
import { Button, Row, Space, Tag, Typography } from "antd";
import AvatarComponent from "../../../components/AvatarComponent";
import VerifyIcon from "../../../assets/icons/verify.svg";
import CloseIcon from "../../../assets/icons/close-with-shadow.svg";
import LikeFilledIcon from "../../../assets/icons/like-filled.svg";
import LikeIcon from "../../../assets/icons/like.svg";
import UserIcon from "../../../assets/icons/user-white.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { clearLiveData, editLive } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { reactionService } from "../../../services";
import { clearStreamStats } from "../../../redux/lives/liveDataSlice";
import { formatNumber } from "../../../utls/FunctionsUtil";

interface Props {
  live: ILive | null;
}

export const FanLiveRTMPHeader = (props: Props) => {
  const { live } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPaymentPage = searchParams.get("from");

  const onClickClose = () => {
    dispatch(clearLiveData());
    dispatch(clearStreamStats());
    if (fromPaymentPage) {
      navigate("/p/fan/lives?tab=now");
    } else navigate(-1);
  };

  const navigateToProfile = () => {
    navigate("/p/" + live?.creator?.username);
  };
  return (
    <Row className='justify-content-between pl-15 pr-3 z-fan-live' align="middle">
    {    
      live && <Space className="header-container-left-live gap-1">
        <AvatarComponent image={live?.creator?.avatar} size={30} onClick={navigateToProfile} />
        <Typography.Text className="fullname" onClick={navigateToProfile}>
          LOLY.APP
        </Typography.Text>
      </Space>}
      <Space  className={`gap-20 `}>
        <img src={CloseIcon} width={22} alt="close" onClick={onClickClose} />
      </Space>
    </Row>
  );
};
