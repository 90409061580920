import { Button, Row, Typography } from "antd";
import { ILive } from "../../helpers/types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PAYMENT_TYPES } from "../../helpers/constant";

interface Props {
  live: ILive;
}

export const FanPaymentVerify = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { live } = props;

  const onClickGetTicket = () => {
    navigate(`/fan/payment?creatorId=${live?.creator?._id}&type=${PAYMENT_TYPES.LIVE}&liveId=${live._id}&redirectUrl=fan/live/${live._id}`);
  };

  const onClickSubscribeToCreator = () => {
    navigate(`/fan/subscribe/${live.creator?._id}?redirectUrl=p/fan/lives?tab=now`);
  };

  const displayLiveOverlay = () => {
    if (live && live.isStreaming) {
      if (live.access === "all") {
        if (live.amount !== 0) {
          if (!live.isPaymentVerified) {
            return (
              <div className="liveWhoLikesItPrivateOverlay">
                <Typography.Text className="privateLinkText">{t("private-live")}</Typography.Text>
                <Button className="privateLinkButtonButton" onClick={onClickGetTicket}>
                  {t("go-to-live")}
                </Button>
              </div>
            );
          }
        }
      } else {
        if (!live.isSubscribed) {
          return (
            <div className="liveWhoLikesItPrivateOverlay">
              <Typography.Text className="privateLinkText">{t("private-live")}</Typography.Text>
              <Button className="privateLinkButtonButton" onClick={onClickSubscribeToCreator}>
                {t("go-to-live")}
              </Button>
            </div>
          );
        } else {
          if (!live.isFreeForSubscribers && !live.isPaymentVerified) {
            return (
              <div className="liveWhoLikesItPrivateOverlay">
                <Typography.Text className="privateLinkText">{t("private-live")}</Typography.Text>
                <Button className="privateLinkButtonButton" onClick={onClickGetTicket}>
                  {t("go-to-live")}
                </Button>
              </div>
            );
          }
        }
      }
    }

    /*if (!live.isSubscribed) {
      if (!live.isPaymentVerified) {
        return <Row className="fanLiveSwiperItemEndWrapper"><div className="fanLiveSwiperItemEndContent">{renderSubscriptionContent()}</div></Row>;
      }
    } else if (!live.isFreeForSubscribers && !live.isPaymentVerified) {
      return <Row className="fanLiveSwiperItemEndWrapper"><div className="fanLiveSwiperItemEndContent">{renderTicketContent()}</div></Row>;
    }*/

    return null;
  };

  const renderSubscriptionContent = () => (
    <div className="fanLiveSwiperItemEndLabelWrapper">
      <Typography.Text className="font-24-bold text-white-color">{t("this-is-private-live")}</Typography.Text>
      <Typography.Text className="font-14-regular text-white-color mt-19">{t(`you-need-to-subscribe-access-it`)}</Typography.Text>
      <Button className="whiteButton font-18-bold br-21-5 pt-12 pb-11 mt-33" onClick={onClickSubscribeToCreator}>
        {t(`subscribe`)}
      </Button>
    </div>
  );

  const renderTicketContent = () => (
    <div className="fanLiveSwiperItemEndLabelWrapper">
      <Typography.Text className="font-24-bold text-white-color">{t("this-is-private-live")}</Typography.Text>
      <Typography.Text className="font-14-regular text-white-color mt-19">{t(`you-need-ticket-to-access-it`)}</Typography.Text>
      <Button className="whiteButton font-18-bold br-21-5 pt-12 pb-11 mt-33" onClick={onClickGetTicket}>
        {t(`get-a-ticket`)}
      </Button>
    </div>
  );

  const renderPrivateLive = () => (
    <div className="liveWhoLikesItPrivateOverlay">
      <Typography.Text className="privateLinkText">{t("private-live")}</Typography.Text>
      <Button className="privateLinkButtonButton">{t("go-to-live")}</Button>
    </div>
  );

  return displayLiveOverlay();
};
