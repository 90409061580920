import React from "react";
import { Col, Row, Space, Checkbox, Typography } from "antd";
import AuthGoogleButtonComponent from "../../AuthGoogleButtonComponent";
import AuthOrDividerComponent from "../../AuthOrDividerComponent";
import AuthInputComponent from "../../AuthInputComponent";
import DatePickerComponent from "../../DatePickerComponent";
import InputComponent from "../../InputComponent";
import PhoneInputComponent from "../../PhoneInputCompnent";
import AuthGenderInputComponent from "../../AuthGenderInputComponent";
import AuthButtonComponent from "../../AuthButtonCompnent";
import AuthSelectLanguageComponent from "../../AuthSelectLanguageComponent";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../helpers/types";
import "../index.less";

interface SignUpCreatorStepOneProps {
  user: IUser | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  googleRegister: (accessToken: string) => void;
  register: () => void;
  emailError?: boolean;
  acceptAuthTerms: boolean;
  setAcceptAuthTerms: (value: boolean) => void;
}

const SignUpCreatorStepOneComponent: React.FC<SignUpCreatorStepOneProps> = ({
  user,
  handleChange,
  setUser,
  googleRegister,
  register,
  emailError,
  acceptAuthTerms,
  setAcceptAuthTerms,
}) => {
  const { t } = useTranslation();

  return (
    <Col className="signUpContainer">
      <Row className="mt-5">
        <AuthGoogleButtonComponent onLoginSuccess={(accessToken) => googleRegister(accessToken)} />
      </Row>
      <Row className="mt-5">
        <AuthOrDividerComponent text={t("auth-signUp-divider")} />
      </Row>
      <Row className="mb-10">
        <Space className="w-full" direction="vertical">
          <Row className="mt-7">
            <AuthInputComponent
              label={t("last-name-label")}
              placeholder={t("last-name-label")!}
              type="text"
              name="name"
              value={user?.name || ""}
              handleChange={handleChange}
              infoText={t("displayed-on-your-profile")!}
            />
          </Row>
          <Row className="mt-7">
            <AuthInputComponent
              label={t("user-name-label")}
              placeholder={t("user-name-label")!}
              type="text"
              name="username"
              value={user?.username?.toLocaleLowerCase() || ""}
              handleChange={handleChange}
              infoText={t("displayed-on-your-profile")!}
            />
          </Row>
          <Row gutter={7}>
            <Col span={12}>
              <AuthInputComponent
                label={t("identity")}
                placeholder={t("first-name-label")!}
                type="text"
                name="firstName"
                value={user?.firstName || ""}
                handleChange={handleChange}
              />
            </Col>
            <Col span={12}>
              <div className="mt-3">
                <AuthInputComponent
                  label=""
                  placeholder={t("last-name-label")!}
                  type="text"
                  name="lastName"
                  value={user?.lastName || ""}
                  handleChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-7">
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="authInputLabel">{t("date-of-birth-label")}</Typography.Text>
              <DatePickerComponent
                className="darkDatePickerSignup"
                date={user?.dateOfBirth}
                onChange={(value) => setUser({ ...user, dateOfBirth: value })}
              />
            </Space>
          </Row>
          <Row className="mt-7">
            <AuthInputComponent
              label={t("email-label")}
              placeholder={t("email-label")!}
              type="email"
              name="email"
              value={user?.email || ""}
              handleChange={handleChange}
              inputError={emailError}
            />
          </Row>
          <Row className="mt-7">
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="authInputLabel">{t("password-label")}</Typography.Text>
              <InputComponent
                className="custom-input-signup"
                placeholder={t("password-label")!}
                onChange={handleChange}
                value={user?.password || ""}
                name="password"
                type="password"
              />
            </Space>
          </Row>
          <Row className="mt-7">
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="authInputLabel">{t("phone-number-label")}</Typography.Text>
              <PhoneInputComponent
                className="authDark"
                countryCode={user?.countryCode}
                phoneCode={user?.phoneCode}
                phone={user?.phone}
                onChangePhone={(e) => setUser({ ...user, phone: e.target.value })}
                onChangePhoneCode={(phoneCode, countryCode) => {
                  setUser({ ...user, phoneCode, countryCode });
                }}
              />
            </Space>
          </Row>
          <Row className="mt-7">
            <AuthGenderInputComponent name="gender" value={user?.gender} handleChange={handleChange} />
          </Row>
          <Row className="mt-7">
            <Checkbox className="authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
              <span>{t("auth-terms-1")}</span>
              <span className="text-color-green">{t("auth-terms-2")}</span>
              <span>{t("auth-terms-3")}</span>
              <a href="https://loly.app/conditions-generales-de-vente/" target="_blank">
                {t("terms-and-conditions")}
              </a>
              {t("and")}
              <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank">
                {t("privacy-policy")}
              </a>
            </Checkbox>
          </Row>
          <Row className="mt-14">
            <AuthButtonComponent label={t("auth-signUp-button")} onClick={register} />
          </Row>
        </Space>
      </Row>
      <Row className={`w-full authRowFooter`}>
        <AuthSelectLanguageComponent />
      </Row>
    </Col>
  );
};

export default SignUpCreatorStepOneComponent;
