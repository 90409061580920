import { Col, Layout, Row, Spin, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CompletedIcon from "../../assets/icons/green-icon.svg";
import { CREATOR } from "../../helpers/constant";
import { IBillingAddress, IMonetization, IUser } from "../../helpers/types";
import { authService, userService, utilService } from "../../services";
import { capitalizeFirstLetter } from "../../utls/FunctionsUtil";
import AuthButtonComponent from "../AuthButtonCompnent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import SignUpCreatorStepOneComponent from "./SignUpCreatorSteps/SignUpCreatorStepOneComponents";
import SignUpCreatorStepTwoComponent from "./SignUpCreatorSteps/SignUpCreatorStepTwoComponent";
import SignUpCreatorStepThreeComponent from "./SignUpCreatorSteps/SignUpCreatorStepThreeComponent";
import SignUpCreatorStepFourComponent from "./SignUpCreatorSteps/SignUpCreatorStepFourComponent";
import SignUpCreatorStepFiveComponent from "./SignUpCreatorSteps/SignUpCreatorStepFiveComponent";
import SignUpCreatorStepSixComponent from "./SignUpCreatorSteps/SignUpCreatorStepSixComponent";
import SignUpCreatorStepGoogleComponent from "./SignUpCreatorSteps/SignUpCreatorStepGoogleComponent";
import "./index.less";

const initMonetization: IMonetization = {
  monthly: true,
  monthlyPrice: null,
  trimestrial: false,
  trimestrialPrice: null,
  half_yearly: false,
  half_yearlyPrice: null,
  yearly: false,
  yearlyPrice: null,
};

type props = {
  setCurrentStep: any;
  curentStep?: any;
  usersId: string | null;
  userEmail: string | null;
  currentUser: any | null;
  setUsersId?: any;
  setCurrentUser?: any;
  setUserEmail: any;
};

const AuthSignUpCreatorTabComponent: React.FC<props> = ({
  setCurrentStep,
  curentStep,
  usersId,
  setUsersId,
  userEmail,
  setUserEmail,
  currentUser,
  setCurrentUser,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [curStep, setCurStep] = useState<number>(curentStep);
  const [user, setUser] = useState<IUser | null>(null);
  const [userId, setUserId] = useState<string | null>(usersId);
  const [billingAddress, setBillingAddress] = useState<IBillingAddress | null>(null);
  const [acceptAuthTerms, setAcceptAuthTerms] = useState<boolean>(true);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [monetization, setMonetization] = useState<IMonetization>(initMonetization);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<any>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [sponsorId, setSponsorId] = useState<string | null>();
  const [accountType, setAccountType] = useState<string>("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      setSponsorId(token);
    } else {
      setSponsorId(null);
    }
  }, [token]);

  useEffect(() => {
    if (dropdownOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [dropdownOpen]);

  useEffect(() => {
    setCurrentStep(curStep);
    window.scrollTo({
      top: 0,
    });
  }, [curStep]);

  useEffect(() => {
    setUser({ ...user, ...currentUser });
    const countriesList = utilService.getPhoneCoutries() as any;
    countriesList
      .then((data: any) => {
        let options: any = [];
        data.forEach((element: any) => {
          options.push({ label: element.name, value: element.code });
        });
        setCountries(options);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });
  }, [currentUser]);

  const register = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEmail = emailRegex.test(user?.email || "");
    if (!user) return message.warning(t("empty-form-error-message"));
    if (!user?.name) return message.warning(t("name-required"));
    if (!user?.firstName) return message.warning(t("first-name-required"));
    if (!user?.lastName) return message.warning(t("last-name-required"));
    if (!user?.username || user?.username.length < 3) return message.warning(t("username-required"));
    if (!user?.dateOfBirth) return message.warning(t("dob-required"));
    if (!user?.email || !isEmail) {
      setEmailError(true);
      return message.warning(t("invalid-email"));
    }
    if (!user?.password || user?.password?.length < 6) return message.warning(t("password-required"));
    if (!user?.phone) return message.warning(t("phone-number-required"));
    if (!user.gender) return message.warning(t("gender-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    authService
      .register({
        ...user,
        firstName: capitalizeFirstLetter(user.firstName),
        lastName: capitalizeFirstLetter(user.lastName),
        username: user.username?.toLocaleLowerCase(),
        type: CREATOR,
        sponsorId: sponsorId,
        lang: localStorage.getItem("lng"),
      })
      .then((data: IUser) => {
        if (data._id) {
          setUserId(data._id);
          setUsersId(data._id);
          setUserEmail(data.email ?? "");
          setCurStep(2);
          setUser({ ...user, ...data });
        }
      })
      .catch((error) => message.error(t(error)));
  };

  const updateTypes = () => {
    if (!userId) return;
    if (selectedTypes?.length === 0) {
      message.warning(t("auth-content-types-note"));
      return;
    }
    userService
      .updateCreatorContentTypes(userId, selectedTypes)
      .then(() => setCurStep(3))
      .catch((error) => message.error(error));
  };

  const updateDateOfBirth = () => {
    if (!userId || !userEmail) return message.warning(t("email-required"));
    if (!user?.name) return message.warning(t("name-required"));
    if (!user?.firstName) return message.warning(t("first-name-required"));
    if (!user?.lastName) return message.warning(t("last-name-required"));
    if (!user?.username) return message.warning(t("username-required"));
    if (!user?.dateOfBirth) return message.warning(t("dob-required"));
    if (!user?.phone) return message.warning(t("phone-number-required"));
    if (!user.gender) return message.warning(t("gender-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    userService
      .updateDateOfBirth(
        userId,
        userEmail,
        user?.firstName,
        user?.lastName,
        user?.username,
        user?.dateOfBirth,
        false,
        user?.phone,
        user?.phoneCode || "",
        user?.gender,
        user?.name
      )
      .then((res: any) => setCurStep(2))
      .catch((error: any) => message.error(t(error)));
  };

  const updateMonetization = () => {
    if (!monetization.monthlyPrice) return message.warning(t("set-monthly-price-error-message"));
    if (monetization?.trimestrial && !monetization?.trimestrialPrice) return message.warning(t("set-quaterly-price"));
    if (!userId) return;
    userService
      .updateCreatorMonetization(userId, monetization)
      .then(() => setCurStep(4))
      .catch((error) => message.error(error));
  };

  const updateBillingAddress = () => {
    if (!billingAddress) return message.warning(t("empty-form-error-message"));
    if (!billingAddress?.address) return message.warning(t("address-required"));
    if (!billingAddress?.city) return message.warning(t("city-required"));
    if (!billingAddress?.companyName) return message.warning(t("company-name-required"));
    if (!billingAddress?.country) return message.warning(t("country-required"));
    if (!billingAddress?.postalCode) return message.warning(t("postal-code-required"));
    if (!billingAddress?.vatNumber) return message.warning(t("vat-number-required"));
    if (!userId) return;

    userService
      .updateBillingAddress(userId, billingAddress)
      .then(() => setCurStep(6))
      .catch((error) => message.error(t(error)));
  };

  const handleChange = (e: { target: { name: any; value: any }; code?: any; key?: any; keyCode?: any }) => {
    let filedValue = e.target.value;
    if (e.target.name === "username") {
      filedValue = filedValue.replace(/\s+/g, "");
    }
    if (e.target.name === "name") {
      filedValue = filedValue.replace(/[^a-zA-Z0-9 _.-]/g, "").slice(0, 20);
    }
    if (e.target.name === "email") {
      setEmailError(false);
    }
    setUser({ ...user, [e.target.name]: filedValue });
  };

  const handleBillingChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setBillingAddress((prev) => ({ ...prev, [name]: value }));
  };

  const typeTagClicked = (type: string) => {
    const tempSelectedTypes = [...selectedTypes];

    const index = selectedTypes.indexOf(type);
    if (index === -1) {
      tempSelectedTypes.push(type);
    } else {
      tempSelectedTypes.splice(index, 1);
    }
    setSelectedTypes(tempSelectedTypes);
  };

  const googleRegister = (accessToken: string) => {
    setLoading(true);
    authService
      .googleSignUp(accessToken, CREATOR, sponsorId || "")
      .then(async (response: { data: IUser; status: any; token: string }) => {
        if (response && response.data && response.status === 0) {
          const Data = response.data;
          setCurStep(-1);
          sessionStorage.setItem("fromGoogle", "true");
          setUserId(response.data._id!);
          setUsersId(response.data._id!);
          setUserEmail(response.data.email);
          setUser({
            ...user,
            ...Data,
            username: "",
          });
          setCurrentUser({
            ...currentUser,
            ...Data,
            username: "",
          });
          authService.setToken(response.token || "");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(t(error));
      });
  };

  const selectAccountType = () => {
    if (accountType === "business") {
      setCurStep(5);
    } else {
      setCurStep(6);
    }
  };

  const handleBackStep = () => {
    setCurStep(4);
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setDropdownOpen(open);
  };

  const handleAuthCompletion = () => {
    const fromGoogle = sessionStorage.getItem("fromGoogle") === "true";
    const path = fromGoogle ? "/auth/intro" : "/auth/signIn";
    navigate(path);
    sessionStorage.removeItem("fromGoogle");
  };

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout className={"background-transparent"}>
        <Layout.Content className="authSignupContentWrapper">
          {curStep === -1 && (
            <SignUpCreatorStepGoogleComponent
              user={user}
              handleChange={handleChange}
              setUser={setUser}
              acceptAuthTerms={acceptAuthTerms}
              setAcceptAuthTerms={setAcceptAuthTerms}
              updateDateOfBirth={updateDateOfBirth}
            />
          )}

          {curStep === 1 && (
            <SignUpCreatorStepOneComponent
              user={user}
              handleChange={handleChange}
              setUser={setUser}
              googleRegister={googleRegister}
              register={register}
              emailError={emailError}
              acceptAuthTerms={acceptAuthTerms}
              setAcceptAuthTerms={setAcceptAuthTerms}
            />
          )}

          {curStep === 2 && <SignUpCreatorStepTwoComponent selectedTypes={selectedTypes} typeTagClicked={typeTagClicked} updateTypes={updateTypes} />}

          {curStep === 3 && (
            <SignUpCreatorStepThreeComponent monetization={monetization} setMonetization={setMonetization} updateMonetization={updateMonetization} />
          )}

          {curStep === 4 && (
            <SignUpCreatorStepFourComponent accountType={accountType} setAccountType={setAccountType} selectAccountType={selectAccountType} />
          )}

          {curStep === 5 && (
            <SignUpCreatorStepFiveComponent
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              handleBillingChange={handleBillingChange}
              updateBillingAddress={updateBillingAddress}
              countries={countries}
              user={user}
              handleBackStep={handleBackStep}
              setCurrentStep={setCurrentStep}
              handleDropdownVisibleChange={handleDropdownVisibleChange}
            />
          )}

          {curStep === 6 && <SignUpCreatorStepSixComponent userId={userId} setCurStep={setCurStep} setLoading={setLoading} />}

          {curStep === 7 && (
            <Col className="padding-container">
              <Row className="justify-content-center mt-20">
                <img src={CompletedIcon} alt="completed" />
              </Row>
              <Row className="justify-content-center mt-35">
                <Typography.Text className="authCompletedTitle">
                  <span className="authCompletedTitleSpan">{t("auth-signUp-finish-title-1")}</span>
                  {t("auth-signUp-finish-title-2")}
                </Typography.Text>
              </Row>
              <Row className="mt-40">
                <Typography.Text className="authCompletedContent">{t("auth-signUp-receive-email-notification-label")}</Typography.Text>
              </Row>
              <Row className="mt-40">
                <Typography.Text className="authCompletedContent text-medium-green-color">{userEmail}</Typography.Text>
              </Row>
              <Row className="mt-40">
                <Typography.Text className="authCompletedContent">{t("auth-signUp-with-status")}</Typography.Text>
              </Row>
              <Row className="justify-content-center mt-40">
                <AuthButtonComponent label={t("terminate-label")} onClick={handleAuthCompletion} />
              </Row>
            </Col>
          )}
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default AuthSignUpCreatorTabComponent;
