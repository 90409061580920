import { Input, InputRef, Space, Typography } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MediaIcon from "../../assets/icons/media-2.svg";
import MessageIcon from "../../assets/icons/message-2.svg";
import "./index.less";

type props = {
  isMediaSuffix?: boolean;
  isQuickAnswerSuffix?: boolean;
  sendMedia: boolean;
  sendQuickAnswer: boolean;
  onSendClick?: (value: string) => void;
  onMediaClick?: () => void;
  onQuickAnswerClick?: () => void;
  quickAnswer?: string;
};

const MessageInputComponent: React.FC<props> = ({ sendMedia, sendQuickAnswer, onSendClick, onMediaClick, onQuickAnswerClick, quickAnswer }) => {
  const { t } = useTranslation();
  const [suffix, setSuffix] = useState<ReactNode>(<span />);
  const [value, setValue] = useState<string>("");
  const inputRef = React.useRef<InputRef>(null);

  const handleOnClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (onSendClick) {
      onSendClick(value);
      setValue("");
      inputRef.current?.focus();
    }
  };

  const handleMediaClick = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault();
    e.stopPropagation();
    if (onMediaClick) {
      onMediaClick();
    }
  };

  const handleQuickAnswerClick = (e: { preventDefault: () => void; stopPropagation: () => void }) => {
    e.preventDefault();
    e.stopPropagation();
    if (onQuickAnswerClick) {
      onQuickAnswerClick();
    }
  };

  useEffect(() => {
    if (quickAnswer && !isEmpty(quickAnswer)) {
      setValue(quickAnswer);
    }
  }, [quickAnswer]);

  useEffect(() => {
    let newSuffix: any;
    const mediaSuffix = <img src={MediaIcon} width={18} alt="media" onClick={handleMediaClick} />;
    const quickAnswerSuffix = <img src={MessageIcon} width={18} alt="quick answer" onClick={handleQuickAnswerClick} />;

    if (isEmpty(value)) {
      newSuffix = (
        <Space className="gap-13">
          {sendQuickAnswer && quickAnswerSuffix}
          {sendMedia && mediaSuffix}
        </Space>
      );
    } else {
      newSuffix = (
        <Typography.Text className="messageSendLabel" onClick={handleOnClick}>
          {t("send")}
        </Typography.Text>
      );
    }
    setSuffix(newSuffix);
  }, [value, sendMedia, sendQuickAnswer, onSendClick, onMediaClick, onQuickAnswerClick]);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const handleOnKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleOnClick(e);
    }
  };

  return (
    <Input
      ref={inputRef}
      className="messageInput"
      placeholder={`${t("your-message")}`}
      suffix={suffix}
      value={value}
      onChange={handleChange}
      onKeyUp={handleOnKeyUp}
    />
  );
};

export default MessageInputComponent;
