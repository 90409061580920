import axios from "../helpers/axios";
import { IGetMediaPush, IMediapush, IMediapushHistory, ISegmentations, Media, MediaPushPrice } from "../helpers/types";

class MediapushService {
  public createMediaPush = (data: FormData, setProgress: Function) => {
    return new Promise<IMediapush>((resolve, reject) => {
      axios
        .post(`/mediapush`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            let progress = Math.round((100 * event.loaded) / event.total!);
            progress = Math.round(progress / 2);
            if (progress <= 50) {
              setProgress(progress);
            }
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else if (response?.data?.message) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getSegmentations = () => {
    const url = `/mediapush/segmentation`;
    return new Promise<ISegmentations>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getPrivateGalleryMedia = (conversationId: string, receiverId: string, senderId: string) => {
    const url = `/mediapush/privateGallery/${conversationId}/${receiverId}/${senderId}`;
    return new Promise<Media[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public updateMediaPushFiles = (payload: any) => {
    const url = `/mediapush/updateFiles`;
    return new Promise<Media[]>((resolve, reject) => {
      axios
        .put(url, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getMediaPushPrice = (mediaPushId: string) => {
    const url = `/mediapush/price/${mediaPushId}`;
    return new Promise<MediaPushPrice>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getMediaPush = (id: string | undefined | null) => {
    const url = `/mediapush/details/${id}`;
    return new Promise<IGetMediaPush>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getMediaPushDetailed = (id: string | undefined | null) => {
    const url = `/mediapush/details/${id}`;
    return new Promise<IMediapush>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public mediaPushHistory = async (payload: { limit: number; offset: number }): Promise<IMediapushHistory[]> => {
    const url = `/earning/creator/mediaPushHistory?limit=${payload.limit}&offset=${payload.offset}`;
    return axios
      .get(url)
      .then((response) => {
        if (response?.data?.data) {
          return response.data.data; 
        }
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        throw new Error(error?.response?.data?.message || "Something went wrong.");
      });
  };
  

  public stripeMediaPush = (body: any) => {
    const url = `/payment/stripe/MediaPurchase`;
    return axios
      .post(url, body)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  public applePayMediaPush = (body: any) => {
    const url = `/payment/applePay/MediaPurchase`;
    return axios
      .post(url, body)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  public verifyPaymentStatus = (id: string) => {
    const url = `/payment/psp/verifyPaymentStatus/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public create3dSecureTransaction = (transactionId:any , token:any) => {
    const url = `/payment/shift/oneTimeThree3DSVerification`;
    return axios
      .post(url, {transactionId , token})
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

}

export const mediapushService = new MediapushService();
