import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import { copyToClipboardCustom } from "../../../../utls/FunctionsUtil";
import "./index.less";

type CollectionDetailModalProps = {
  isOpen: boolean;
  link: string;
  onClose: () => void;
  openShareModal: () => void;
  openDeleteModal: () => void;
};

const CollectionDetailModalComponent: React.FC<CollectionDetailModalProps> = ({ isOpen, link, onClose, openShareModal, openDeleteModal }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  const copyCollectionLink = async () => {
    await copyToClipboardCustom(link, t("something-want-wrong"), t("link-copied") as string);
    onClose();
  };

  return (
    <Modal
      className="collection-detail-modal"
      title={t("what-do-you-want-to-do")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <Button className="cancel-btn" type="link" onClick={onClose}>
          {t("cancel")}
        </Button>
      }
    >
      <Row className="g-15 mt-10">
        <Col className="w-100 text-center" onClick={openShareModal}>
          <Typography.Text className="actionBtn">
            <div className="confirm-btn-background">{t("share")}</div>
          </Typography.Text>
        </Col>
        <Col className="w-100 text-center" onClick={copyCollectionLink}>
          <Typography.Text className="actionBtn">
            <div className="confirm-btn-background">{t("copy-link")}</div>
          </Typography.Text>
        </Col>
        <Col className="mt-5 w-100 text-center" onClick={openDeleteModal}>
          <Typography.Text className="actionBtn">
            <div className="confirm-btn-background">{t("delete-collection")}</div>
          </Typography.Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default CollectionDetailModalComponent;
