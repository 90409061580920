import { Button, Modal, Row, Typography } from "antd";
import ConfirmPayementEventImg from "../../../assets/images/live-fight-event/confirmation-live-event.webp";
import "./index.less";

type ConfirmationEventPaymentModalProps = {
  onClose?: () => void;
  paymentStatus?: any;
};

const ConfirmationEventPaymentModal: React.FC<ConfirmationEventPaymentModalProps> = ({ onClose, paymentStatus }) => {
  return (
    <Modal className="confirm-live-event-modal" open={!!paymentStatus} centered closable={false} onCancel={onClose} footer={null}>
      <Row className="justify-content-center">
        <img src={ConfirmPayementEventImg} className="confirm-live-img" alt="Confirm" />
      </Row>
      <Row className="confirm-live-event-modal-row-title">
        <Typography.Text className="confirm-live-event-modal-title">Réservation confirmée</Typography.Text>
      </Row>
      <Row className="confirm-live-event-modal-row-description">
        <Typography.Text className="confirm-live-event-modal-description">
          Merci pour votre commande ! Votre billet a bien été réservé et vous a été envoyé par e-mail. Vous pouvez directement cliquer sur le lien
          pour accéder au combat le 26 octobre à 19h00, ou vous connecter sur Loly avec votre compte dans le menu "Live" de notre application.
        </Typography.Text>
      </Row>
      <Row className="mt-20 confirm-live-event-modal-row-description">
        <Typography.Text className="confirm-live-event-modal-description">
          Si vous avez des questions, contactez-nous via WhatsApp au <strong>+33 6 29 66 10 21</strong>
        </Typography.Text>
      </Row>
      <Row className="confirm-live-event-modal-row-button">
        <Button className="confirm-live-event-modal-button" onClick={onClose}>
          C'est compris
        </Button>
      </Row>
    </Modal>
  );
};

export default ConfirmationEventPaymentModal;
