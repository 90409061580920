import { authService } from "../../../services";
import FanLivesPage from "./FanLivesPage";
import PublicFanLivesPage from "./PublicFanLivePage";

function FanLivesPageWrapper() {
  if (authService.isAuthenticated()) {
    return <FanLivesPage />;
  } else {
    return <PublicFanLivesPage />;
  }
}

export default FanLivesPageWrapper;
