import React, { useEffect, useRef, useState } from "react";
import { Typography, Button, Row, message, Checkbox, Col, Space, InputRef } from "antd";
import MailIcon from "../../../assets/icons/mail.svg";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../helpers/types";
import { authService, userService } from "../../../services";
import { FAN } from "../../../helpers/constant";
import AuthButtonComponent from "../../AuthButtonCompnent";
import AuthInputComponent from "../../AuthInputComponent";
import AuthGoogleButtonComponent from "../../AuthGoogleButtonComponent";
import "../index.less";

interface PublicAuthViewComponentProps {
  onRegisterClick: () => void;
  onLoginClick: () => void;
}

const PublicAuthViewComponent: React.FC<PublicAuthViewComponentProps> = ({ onRegisterClick, onLoginClick }) => {
  const { t } = useTranslation();
  const [curStep, setCurStep] = useState<Number>(0);
  const [user, setUser] = useState<any>(null);
  const [responseData, setResponseData] = useState<IUser | null>(null);
  const [acceptAuthTerms, setAcceptAuthTerms] = useState<boolean>(true);
  const usernameInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (curStep === 1) {
      const element = usernameInputRef.current;
      if (element) {
        setTimeout(() => {
          element.focus();
        }, 0);
      }
    }
  }, [curStep]);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    let filedValue = e.target.value;
    if (e.target.name === "username") {
      filedValue = filedValue.replace(/\s+/g, "");
    }
    setUser({ ...user, [e.target.name]: filedValue });
  };

  const googleRegister = (accessToken: string) => {
    authService
      .googleSignUp(accessToken, FAN)
      .then(async (data: any) => {
        if (data && data.token) {
          setResponseData({ ...data, username: "" });
          setCurStep(1);
          setUser({ ...data.data, username: "" });
          authService.setToken(data.token, true);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  const updateDateOfBirth = () => {
    if (!user?._id || !user?.email) return;
    if (!user?.firstName) return message.warning(t("first-name-required"));
    if (!user?.lastName) return message.warning(t("last-name-required"));
    if (!user?.username) return message.warning(t("username-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    userService
      .updateDateOfBirth(user?._id, user?.email, user?.firstName, user?.lastName, user?.username, user?.dateOfBirth, true, "", "", "")
      .then(async () => {
        await message.success(t("google-register-success-message"));
        window.location.reload();
      })
      .catch((error: any) => {
        message.error(t(error));
      });
  };

  return (
    <div className="auth-view">
      {curStep !== 1 ? (
        <>
          <Row>
            <Button
              className="public-auth-btn"
              onClick={onRegisterClick}
              icon={<img className="ml-10" height={22} width={22} src={MailIcon} alt={"mail"} />}
            >
              {t("with-mail")}
            </Button>
          </Row>
          <Row className="mt-20">
            <AuthGoogleButtonComponent className="publicAuthGoogleBtn" label={t("with-google")!} onLoginSuccess={googleRegister} />
          </Row>
          <Row className="w-full justify-content-center mt-40 mb-10">
            <Typography.Text className="public-auth-bottom-text-label">{t("auth-already-account-question")}</Typography.Text>
            <Typography.Text className="public-auth-bottom-text-register" onClick={onLoginClick}>
              {t("auth-signIn-link")}
            </Typography.Text>
          </Row>
        </>
      ) : (
        <Space className="w-full" direction="vertical">
          <Row gutter={7}>
            <Col span={12}>
              <AuthInputComponent
                label={t("first-name-label")}
                placeholder={t("first-name-label")!}
                type="text"
                name="firstName"
                value={user?.firstName || ""}
                handleChange={handleChange}
              />
            </Col>
            <Col span={12}>
              <div className="">
                <AuthInputComponent
                  label={t("last-name-label")}
                  placeholder={t("last-name-label")!}
                  type="text"
                  name="lastName"
                  value={user?.lastName || ""}
                  handleChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <AuthInputComponent
              label={t("user-name-label")}
              placeholder={t("user-name-label")!}
              type="text"
              name="username"
              value={user?.username?.toLocaleLowerCase() || ""}
              handleChange={handleChange}
              ref={usernameInputRef}
            />
          </Row>
          <Row>
            <AuthInputComponent
              label={t("email-label")}
              placeholder={t("email-label")!}
              type="email"
              name="email"
              value={user?.email || ""}
              disabled
            />
          </Row>
          <Row className="mt-11">
            <Checkbox className="authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
              <span>{t("auth-terms-1")}</span>
              <span className="text-color-green">{t("auth-terms-2")}</span>
              <span>{t("auth-terms-3")}</span>
              <a href="https://loly.app/conditions-generales-de-vente/" target="_blank">
                {t("terms-and-conditions")}
              </a>
              {t("and")}
              <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank">
                {t("privacy-policy")}
              </a>
            </Checkbox>
          </Row>
          <Row className="mt-14">
            <AuthButtonComponent label={t("continue-label")} onClick={updateDateOfBirth} />
          </Row>
        </Space>
      )}
    </div>
  );
};

export default PublicAuthViewComponent;
