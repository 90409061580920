import { PaymentRequestButtonElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentRequest } from "@stripe/stripe-js";
import { Button, Checkbox, Col, Layout, Radio, Row, Space, Spin, Typography, message } from "antd";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NewArrowLeftIcon from "../../../assets/icons/new-arrow-left.svg";
import LockIcon from "../../../assets/icons/payment-lock.svg";
import InfoPaymentImg from "../../../assets/images/info-payment.webp";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import CountryDropdownComponent from "../../../components/CountryDropdownComponent/CountryDropdownComponent";
import FanPaymentPopUp from "../../../components/FanPaymentPopUp";
import InputComponent from "../../../components/InputComponent";
import SaveCards from "../../../components/SaveCards";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { FAN, PAYMENT_TYPES } from "../../../helpers/constant";
import { IFanCollection, ILive, ILiveTip, IStripeLivePayload, IStripeLiveTipPayload, IVerifyPromoCode } from "../../../helpers/types";
import { updateLivePayment } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { getProfileById } from "../../../redux/users/usersSlice";
import { authService, liveService, mediapushService, promotionService, subscriptionsService, userService, utilService } from "../../../services";
import { collectionService } from "../../../services/collection.service";
import { SavedCardServices } from "../../../services/savedCard.service";
import { settingService } from "../../../services/settings.service";
import { socket } from "../../../socket";
import { PAYMENT_ERROR_MESSAGES } from "../../../utls/constants";
import { formatCardNumber } from "../../../utls/FunctionsUtil";
import "./index.less";
import StripeCheckoutForm from "./StripeCheckoutForm";

interface ApplePayData {
  _canMakePaymentAvailability: any;
}

const FanPaymentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [Key, setKey] = useState("");
  const shouldFetchCountry = useRef(true);
  const creatorId = searchParams.get("creatorId");
  const planId = searchParams.get("planId");
  const type = searchParams.get("type");
  const mediaPushId = searchParams.get("mediaPushId");
  const collectionId = searchParams.get("collectionId");
  const isFromCollection = searchParams.get("isFromCollection");
  const messageId = searchParams.get("messageId");
  const liveId = searchParams.get("liveId");
  const liveTipId = searchParams.get("liveTipId");
  const currentRedirectUrl = searchParams.get("redirectUrl");
  const user = useSelector((state: StoreState) => state.users.item);
  const dispatch = useDispatch<AppDispatch>();
  const stripe = useStripe();
  const elements = useElements();
  const [PromoCode, setPromoCode] = useState("");
  const [PromoCodeDetails, setPromoCodeDetails] = useState<IVerifyPromoCode | null>();
  const [promoCodeDiscount, setPromoCodeDiscount] = useState<number>();
  const [NetPaymentAoumt, setNetPaymentAoumt] = useState<number>(0);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | any>();
  const [paymentStatus, setpaymentStatus] = useState<string>();
  const [showApplePay, setShowApplyPay] = useState<string | any>(undefined);
  const [mediaPushDetails, setMediaPushDetails] = useState<any>({});
  const [collectionDetails, setCollectionDetails] = useState<IFanCollection | null>(null);
  const [liveDetails, setLiveDetails] = useState<ILive | null>(null);
  const [liveTipDetails, setLiveTipDetails] = useState<ILiveTip | null>(null);
  const [applePay, setApplePay] = useState<ApplePayData>();
  const [choosedPlan, setchoosedPlan] = useState(Number);
  // card details
  const [cardNumber, setCardNumber] = useState("");
  const [MonthYear, setMonthYear] = useState("");
  const [cardHolderName, setCardHolderName] = useState<any>({ firstname: "", lastname: "" });
  const [cvv, setCvv] = useState("");
  const [error, setError] = useState("");
  // card validation
  const cardnumberReg = /^[0-9\s]*$/;
  const CardMonthYearReg = /^[0-9/]*$/;
  const [buy, setBuy] = useState(false);
  const cvvReg = /^\d*$/;
  const currentYear = new Date().getFullYear().toString().substring(2);
  const expCurrentYear = parseInt(currentYear, 10);
  const [month, year] = MonthYear.split("/");
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // check box
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [paymentCheckBox, setPaymentCheckBox] = useState(true);
  const [allCards, setAllCards] = useState<any>([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState<any[]>([]);
  const [vatCountries, setVatCountries] = useState<any[]>([]);
  const [vatPercentage, setVatPercentage] = useState<any>(0);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [payemntGateway, setPayemntGateway] = useState<any>("shift4");
  const userCountry = Cookies.get("country");

  useEffect(() => {
    if (creatorId) {
      dispatch(getProfileById(creatorId));
    } else {
      navigate("/");
    }
  }, [creatorId]);

  const getMe = async () => {
    await userService.getMyProfile().then((res: any) => {
      setCardHolderName({ firstname: res?.firstName, lastname: res?.lastName });
    });
  };

  // payment option
  // const paymentTypes = [
  //   {
  //     name: t("Bank-card"),
  //     value: "bankCard",
  //     suffix: <img src={BankCardImg} alt="bank card" />,
  //   },
  //   {
  //     name: "Paypal",
  //     value: "paypal",
  //     suffix: <img src={PaypalImg} alt="paypal" />,
  //   },
  // ];

  if (Key !== "Backspace") {
    if (cardNumber.length === 4 || cardNumber.length === 9 || cardNumber.length === 14) {
      setCardNumber(cardNumber + " ");
    }
    if (MonthYear.length === 2) {
      setMonthYear(MonthYear + "/");
    }
  }

  // subsrcibe details
  const subscribeDetails = {
    creatorId: creatorId,
    type: planId,
    cardNumber: selectedCard ? NaN : parseInt(cardNumber.replace(/\s/g, "")),
    expiryMonth: selectedCard ? NaN : parseInt(month),
    expiryYear: selectedCard ? NaN : 2000 + parseInt(year),
    cvv: selectedCard ? NaN : parseInt(cvv),
    paymentGateway: payemntGateway,
    promoCode: PromoCode,
    country: country,
    cardHolderName: selectedCard ? "" : `${cardHolderName?.firstname} ${cardHolderName?.lastname}`,
    savedCard: paymentCheckBox,
    isCardToken: selectedCard ? true : false,
    token: selectedCard ? selectedCard?.id : "",
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };

  useEffect(() => {
    userService
      .getVatCountries()
      .then((data: any) => {
        setVatCountries(data?.list);
      })
      .catch((error: any) => {
        message.error(error || t("something-went-wrong"));
      });
    utilService
      .getPhoneCoutries()
      .then((data: any) => {
        setCountries(data);
      })
      .catch((error: any) => {
        message.error(error || t("something-went-wrong"));
      });
  }, []);

  useEffect(() => {
    if (userCountry && userCountry !== null) {
      setCountry(userCountry);
      const selected = vatCountries?.find((item: any) => item.country === userCountry);
      if (selected) {
        setVatPercentage(selected?.vat);
      } else {
        setVatPercentage(0);
      }
    } else {
      if (vatCountries && countries && !country) {
        if (shouldFetchCountry.current) {
          shouldFetchCountry.current = false;
          utilService.fetchUserCountry().then((res: any) => {
            if (res) {
              setCountry(res);
              Cookies.set("country", res, { expires: 1 });
            }
          });
        }
      }
    }
  }, [vatCountries, countries, userCountry]);

  // choosed plan
  useEffect(() => {
    getCurrentPaymentGateway();
    userService
      .getCreatorMonetizations(creatorId)
      .then((res) => {
        const monetizationData = res as {
          monthlyPrice: 0;
          trimestrialPrice: 0;
          half_yearlyPrice: 0;
          yearlyPrice: 0;
        };
        if (planId === "monthly") {
          setchoosedPlan(monetizationData.monthlyPrice);
        } else if (planId === "trimestrial") {
          setchoosedPlan(monetizationData.trimestrialPrice);
        } else if (planId === "half-yearly") {
          setchoosedPlan(monetizationData.half_yearlyPrice);
        } else if (planId === "yearly") {
          setchoosedPlan(monetizationData.yearlyPrice);
        }
      })
      .catch((error) => {});
    getAllCards();
    getMe();
  }, []);

  const getAllCards = async () => {
    await SavedCardServices.allSavedCards()
      .then((res: any) => {
        setAllCards(res?.data?.cards || []);
      })
      .catch((e: any) => {
        console.log(e?.message);
        message.error(t("something-want-wrong"));
      });
  };

  // apply pay

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    setPaymentRequest(null);
    const applePay = async (Amount: number) => {
      let label = `Payment to ${creatorId} by ${authService.getId()}`;
      let currency = "eur";
      let country = "BE";

      const pr = stripe.paymentRequest({
        currency: currency,
        country: country,
        requestPayerEmail: true,
        requestPayerName: true,
        total: {
          label: label,
          amount: Amount,
        },
      });

      await pr.canMakePayment().then(async (result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      await pr.on("paymentmethod", async (event) => {
        const paymentMethod = event.paymentMethod;

        if (paymentMethod) {
          const paymentMethodId = event.paymentMethod.id;

          const body = {
            creatorId: creatorId,
            type: planId,
            paymentMethodId: paymentMethodId,
          };
          const applePayMediaPushDetails = {
            mediaPushId: mediaPushId,
            paymentMethodId: paymentMethodId,
          };
          if (type === "mediaPush") {
            mediapushService.applePayMediaPush(applePayMediaPushDetails).then((res) => {
              if (res?.data?.transaction?.status) {
                setpaymentStatus("success");
              } else {
                setpaymentStatus("error");
              }
            });
          } else if (type === "subscription") {
            subscriptionsService.applePay(body).then((res) => {
              if (res?.data?.transaction?.status) {
                setpaymentStatus("success");
              } else {
                setpaymentStatus("error");
              }
            });
          }
          // for Server side Stripe-API handling of payment
        }
        event.complete("success"); // Completes the transaction and closes Google/Apple pay
      });
    };

    applePay(promoCodeDiscount ? NetPaymentAoumt * 100 : choosedPlan * 100);
  }, [stripe, elements, choosedPlan, NetPaymentAoumt, promoCodeDiscount]);

  useEffect(() => {
    setPaymentAmount(choosedPlan);
  }, [choosedPlan]);

  useEffect(() => {
    // THIS WILL RUN WHEN THERE'S A CHANGE IN 'quotes'
    setShowApplyPay(JSON.stringify(paymentRequest));
  }, [paymentRequest]);

  useEffect(() => {
    if (showApplePay) {
      setApplePay(JSON.parse(showApplePay));
    }
  }, [showApplePay]);

  // stripe media push details
  const stripeMediaPushDetails = {
    mediaPushId: mediaPushId,
    cardNumber: selectedCard ? NaN : parseInt(cardNumber.replace(/\s/g, "")),
    expiryMonth: selectedCard ? NaN : parseInt(month),
    expiryYear: selectedCard ? NaN : 2000 + parseInt(year),
    cvv: selectedCard ? NaN : parseInt(cvv),
    paymentGateway: payemntGateway,
    promoCode: PromoCode,
    country: country,
    cardHolderName: selectedCard ? "" : `${cardHolderName?.firstname} ${cardHolderName?.lastname}`,
    savedCard: paymentCheckBox,
    isCardToken: selectedCard ? true : false,
    token: selectedCard ? selectedCard?.id : "",
  };

  // stripe media push details
  const stripeCollectionDetails = {
    collectionId: collectionId,
    cardNumber: selectedCard ? NaN : parseInt(cardNumber.replace(/\s/g, "")),
    expiryMonth: selectedCard ? NaN : parseInt(month),
    expiryYear: selectedCard ? NaN : 2000 + parseInt(year),
    cvv: selectedCard ? NaN : parseInt(cvv),
    paymentGateway: payemntGateway,
    promoCode: PromoCode,
    country: country,
    cardHolderName: selectedCard ? "" : `${cardHolderName?.firstname} ${cardHolderName?.lastname}`,
    savedCard: paymentCheckBox,
    isCardToken: selectedCard ? true : false,
    token: selectedCard ? selectedCard?.id : "",
  };

  const livePaymentDetails: IStripeLivePayload = {
    liveId,
    cardNumber: selectedCard ? NaN : parseInt(cardNumber.replace(/\s/g, "")),
    expiryMonth: selectedCard ? NaN : parseInt(month),
    expiryYear: selectedCard ? NaN : 2000 + parseInt(year),
    cvv: selectedCard ? NaN : parseInt(cvv),
    paymentGateway: payemntGateway,
    country: country,
    cardHolderName: selectedCard ? "" : `${cardHolderName?.firstname} ${cardHolderName?.lastname}`,
    savedCard: paymentCheckBox,
    isCardToken: selectedCard ? true : false,
    token: selectedCard ? selectedCard?.id : "",
  };

  const liveTipPaymentDetails: IStripeLiveTipPayload = {
    liveTipId,
    cardNumber: selectedCard ? NaN : parseInt(cardNumber.replace(/\s/g, "")),
    expiryMonth: selectedCard ? NaN : parseInt(month),
    expiryYear: selectedCard ? NaN : 2000 + parseInt(year),
    cvv: selectedCard ? NaN : parseInt(cvv),
    paymentGateway: payemntGateway,
    country: country,
    cardHolderName: selectedCard ? "" : `${cardHolderName?.firstname} ${cardHolderName?.lastname}`,
    savedCard: paymentCheckBox,
    isCardToken: selectedCard ? true : false,
    token: selectedCard ? selectedCard?.id : "",
  };

  // subscribe btn
  const subscribe = (token: any) => {
    let mainAmount = calculateAmount();
    if (!mainAmount || Number(mainAmount) < 1) {
      message.error(t("no-amount"));
      setIsLoading(false);
      return;
    }
    if (selectedCard === null && payemntGateway !== "stripe") {
      if (!cardNumber || !cvv || !MonthYear) {
        return message.warning("Please Fill all Fields");
      }
      if (cvv.length < 3) {
        return message.warning("Enter Valid CVV");
      }
    }
    if (error) {
      setIsLoading(false);
      return message.warning(error);
    }
    setIsLoading(true);
    if (type === "mediaPush") {
      mediapushService.stripeMediaPush({ ...stripeMediaPushDetails, token }).then((res) => {
        setIsLoading(false);
        if (res.statusCode === 400 && PAYMENT_ERROR_MESSAGES.includes(res.message)) {
          return message.error(t(res.message));
        }
        if (res?.data?.transaction?.redirectUrl) {
          const { token, transactionId, redirectUrl } = res.data.transaction;
          Cookies.set(
            "subscription_data",
            JSON.stringify({
              token: token?.id,
              transactionId,
              creatorId,
              planId,
              type,
              username: user?.username,
              messageId: messageId,
              userType: FAN,
              isToDeliverMedia: true,
              mediaPushId: stripeMediaPushDetails?.mediaPushId,
              redirectUrl: currentRedirectUrl,
            })
          );
          window.location.href = redirectUrl;
        } else {
          if (res?.data?.transaction?.status === "success") {
            setpaymentStatus(res?.data?.transaction?.status);
            socket.emit("accept-message", {
              messageId,
              userType: FAN,
              isToDeliverMedia: true,
            });
          } else {
            verifyPspPaymentStatus(res?.data?.transaction?._id);
          }
        }
      });
    } else if (type === "collection") {
      collectionService.stripeCollection({ ...stripeCollectionDetails, token }).then((res) => {
        setIsLoading(false);
        if (res.statusCode === 400 && PAYMENT_ERROR_MESSAGES.includes(res.message)) {
          return message.error(t(res.message));
        }
        if (res?.data?.transaction?.redirectUrl) {
          const { token, transactionId, redirectUrl } = res.data.transaction;
          Cookies.set(
            "subscription_data",
            JSON.stringify({
              token: token?.id,
              transactionId,
              creatorId,
              planId,
              type,
              username: user?.username,
              userType: FAN,
              redirectUrl: currentRedirectUrl,
            })
          );
          window.location.href = redirectUrl;
        } else {
          if (res?.data?.transaction?.status === "success") {
            setpaymentStatus(res?.data?.transaction?.status);
          } else {
            verifyPspPaymentStatus(res?.data?.transaction?._id);
          }
        }
      });
    } else if (type === "subscription") {
      const subscribeCreators = async (subscribeDetails: any) => {
        setIsLoading(true);
        await subscriptionsService.subscribeCreator({ ...subscribeDetails, token }).then((response: any) => {
          setIsLoading(false);
          if (response.statusCode === 400 && PAYMENT_ERROR_MESSAGES.includes(response.message)) {
            return message.error(t(response.message));
          }
          if (payemntGateway === "stripe") {
            if (response?.transaction?.status === "success") {
              setpaymentStatus(response?.transaction?.status);
            } else {
              setpaymentStatus("error");
            }
          } else {
            if (response?.enrolled) {
              const { token, transactionId, redirectUrl } = response;
              Cookies.set(
                "subscription_data",
                JSON.stringify({ token: token?.id, transactionId, creatorId, planId, type, username: user?.username, isFromCollection, redirectUrl:currentRedirectUrl })
              );
              window.location.href = redirectUrl;
            } else {
              setpaymentStatus("error");
            }
          }
        });
      };
      subscribeCreators(subscribeDetails);
    } else if (type === PAYMENT_TYPES.LIVE) {
      if (livePaymentDetails.liveId) {
        liveService.stripeLive({ ...livePaymentDetails, token }).then((res: any) => {
          setIsLoading(false);
          if (res.statusCode === 400 && PAYMENT_ERROR_MESSAGES.includes(res.message)) {
            return message.error(t(res.message));
          }
          if (res?.transaction?.redirectUrl) {
            const { token, transactionId, redirectUrl } = res.transaction;
            Cookies.set(
              "subscription_data",
              JSON.stringify({
                token: token?.id,
                transactionId,
                creatorId,
                planId,
                type,
                username: user?.username,
                liveId: livePaymentDetails.liveId,
                isSuggested: liveDetails?.isSuggested,
                isScheduled: liveDetails?.startType === "schedule" && !liveDetails?.isStreaming,
              })
            );
            window.location.href = redirectUrl;
          } else {
            if (res?.transaction?.status === "success") {
              setpaymentStatus(res?.transaction?.status);
              dispatch(updateLivePayment({ id: livePaymentDetails.liveId, isSuggested: liveDetails?.isSuggested }));
            } else {
              setpaymentStatus("error");
            }
          }
        });
      }
    } else if (type === PAYMENT_TYPES.LIVE_TIP) {
      if (liveTipPaymentDetails.liveTipId) {
        liveService.stripeLiveTip({ ...liveTipPaymentDetails, token }).then((res: any) => {
          setIsLoading(false);
          if (res.statusCode === 400 && PAYMENT_ERROR_MESSAGES.includes(res.message)) {
            return message.error(t(res.message));
          }
          if (res?.transaction?.redirectUrl) {
            const { token, transactionId, redirectUrl } = res.transaction;
            Cookies.set(
              "subscription_data",
              JSON.stringify({
                token: token?.id,
                transactionId,
                creatorId,
                planId,
                type,
                username: user?.username,
                streamId: liveTipDetails?.streamId,
                amount: liveTipDetails?.amount,
                liveTipId: liveTipPaymentDetails.liveTipId,
              })
            );
            window.location.href = redirectUrl;
          } else {
            if (res?.transaction?.status === "success") {
              socket.emit("public-stream/update-tip", {
                id: liveTipDetails?.streamId,
                amount: liveTipDetails?.amount,
              });
              setpaymentStatus(res?.transaction?.status);
            } else {
              setpaymentStatus("error");
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    if (liveTipDetails?.streamId) {
      liveService
        .getLiveById(liveTipDetails?.streamId)
        .then((data: ILive) => setLiveDetails(data))
        .catch((error) => console.log(error));
    }
  }, [liveTipDetails]);

  useEffect(() => {
    if (liveTipId) {
      liveService
        .getTipById(liveTipId)
        .then((data: ILiveTip) => setLiveTipDetails(data))
        .catch((error) => console.log(error));
    }
  }, [liveTipId]);

  useEffect(() => {
    if (liveId) {
      liveService
        .getLiveById(liveId)
        .then((data: ILive) => setLiveDetails(data))
        .catch((error) => console.log(error));
    }
  }, [liveId]);

  useEffect(() => {
    if (collectionId) {
      collectionService
        .getFanCollectionDetail(collectionId)
        .then((data: IFanCollection) => setCollectionDetails(data))
        .catch((error) => console.log(error));
    }
  }, [collectionId]);

  useEffect(() => {
    if (mediaPushId) {
      mediapushService
        .getMediaPushPrice(mediaPushId)
        .then((response) => {
          setMediaPushDetails(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [mediaPushId]);

  useEffect(() => {
    if (type === "mediaPush") {
      let price = mediaPushDetails.price;
      if (mediaPushDetails.promo) {
        price = mediaPushDetails.resultPrice;
      }
      setPaymentAmount(price);
    } else if (type === "subscription") {
      setPaymentAmount(choosedPlan);
    }
  });

  useEffect(() => {
    if (selectedCard !== null && checkboxChecked && country) {
      setBuy(true);
    } else {
      if (
        cardNumber.length === 19 &&
        cvv.length === 3 &&
        MonthYear.length === 5 &&
        checkboxChecked &&
        country &&
        cardHolderName.firstname &&
        cardHolderName.lastname
      ) {
        setBuy(true);
      } else {
        setBuy(false);
      }
    }
  }, [cardNumber, cvv, MonthYear, checkboxChecked, country, cardHolderName.firstname, cardHolderName.lastname, selectedCard]);

  const PromoCodeApply = async () => {
    if (type === 'mediaPush' && mediaPushDetails?.promo) {
      message.error(t("promo-not-applicable"));
      setPromoCode("");
      return;
    }
    await promotionService
      .verifyPromoCode(PromoCode, creatorId!)
      .then((response) => {
        if ("promoOn" in response) {
          if ((response?.promoOn === "SUBSCRIBERS" && type === "subscription") || (response?.promoOn === "MEDIA_PUSH" && (type === "mediaPush" || type === 'collection'))) {
            if (response?.discountType === "PERCENTAGE") {
              if (type === "subscription" && choosedPlan - (choosedPlan * response.discount) / 100 < 1) {
                message.warning(t("promo-price-exceed"));
                setPromoCode("");
                setPromoCodeDetails(null);
                setPromoCodeDiscount(0);
                return;
              } else if (type === "mediaPush" && mediaPushDetails.price - (mediaPushDetails.price * response.discount) / 100 < 1) {
                setPromoCode("");
                setPromoCodeDetails(null);
                setPromoCodeDiscount(0);
                message.warning(t("promo-price-exceed"));
                return;
              } else if (type === "collection" && collectionDetails && collectionDetails.price - (collectionDetails.price * response.discount) / 100 < 1) {
                setPromoCode("");
                setPromoCodeDetails(null);
                setPromoCodeDiscount(0);
                message.warning(t("promo-price-exceed"));
                return;
              } else {
                setPromoCodeDetails(response as IVerifyPromoCode);
                message.success(t("promo-code-applied"));
              }
            } else {
              if (type === "subscription" && choosedPlan - response.discount < 1) {
                setPromoCode("");
                setPromoCodeDetails(null);
                setPromoCodeDiscount(0);
                message.warning(t("promo-price-exceed"));
                setPromoCodeDetails(null);
                return;
              } else if (
                type === "mediaPush" && mediaPushDetails.promo ? mediaPushDetails.resultPrice : mediaPushDetails.price - response.discount < 1
              ) {
                setPromoCode("");
                setPromoCodeDetails(null);
                setPromoCodeDiscount(0);
                message.warning(t("promo-price-exceed"));
                return;
              }  else if (
                type === "collection" && collectionDetails && collectionDetails.price - response.discount < 1
              ) {
                setPromoCode("");
                setPromoCodeDetails(null);
                setPromoCodeDiscount(0);
                message.warning(t("promo-price-exceed"));
                return;
              } else {
                setPromoCodeDetails(response as IVerifyPromoCode);
                message.success(t("promo-code-applied"));
              }
            }
          } else {
            message.error(t("enter-valid-promo-code"));
            setPromoCode("");
            setPromoCodeDiscount(0);
            setPromoCodeDetails(null);
          }
        } else if ("error" in response) {
          setPromoCode("");
          setPromoCodeDiscount(0);
          setPromoCodeDetails(null);
          if (response.error) {
            message.error(t(response.error.toLocaleLowerCase().replaceAll("_", "-")));
          } else {
            message.error(t("enter-valid-promo-code"));
          }
        }
      })
      .catch((error) => {
        setPromoCode("");
        message.error(t(error));
      });
  };

  useEffect(() => {
    if (PromoCodeDetails?.discountType === "PERCENTAGE") {
      if (type === "subscription") {
        setPromoCodeDiscount((choosedPlan * PromoCodeDetails.discount) / 100);
        setNetPaymentAoumt(choosedPlan - (choosedPlan * PromoCodeDetails.discount) / 100);
      } else if (type === "mediaPush") {
        setPromoCodeDiscount((mediaPushDetails.price * PromoCodeDetails.discount) / 100);
        setNetPaymentAoumt(mediaPushDetails.price - (mediaPushDetails.price * PromoCodeDetails.discount) / 100);
      }
      else if (type === "collection" && collectionDetails) {
        setPromoCodeDiscount((collectionDetails.price * PromoCodeDetails.discount) / 100);
        setNetPaymentAoumt(collectionDetails.price - (collectionDetails.price * PromoCodeDetails.discount) / 100);
      }
    } else if (PromoCodeDetails?.discountType === "CURRENCY") {
      if (type === "subscription") {
        setPromoCodeDiscount(PromoCodeDetails.discount);
        setNetPaymentAoumt(choosedPlan - PromoCodeDetails.discount);
      } else if (type === "mediaPush") {
        setPromoCodeDiscount(PromoCodeDetails.discount);
        setNetPaymentAoumt(mediaPushDetails.price - PromoCodeDetails.discount);
      }
      else if (type === "collection" && collectionDetails) {
        setPromoCodeDiscount(PromoCodeDetails.discount);
        setNetPaymentAoumt(collectionDetails.price - PromoCodeDetails.discount);
      }
    }
  }, [PromoCodeDetails, applePay]);

  function getLabel(type: any) {
    switch (type) {
      case "mediaPush":
        return "Media-Push-label-to";
      case "subscription":
        return "subscribe-label-to";
      case PAYMENT_TYPES.LIVE:
        return "live";
      case PAYMENT_TYPES.LIVE_TIP:
        return "give-a-tip-to";
      default:
        return "";
    }
  }

  useEffect(() => {
    if (country) {
      const selected = vatCountries?.find((item: any) => item.country === country);
      if (selected) {
        setVatPercentage(selected?.vat);
      } else {
        setVatPercentage(0);
      }
    }
  }, [country]);

  const handleSelectCard = (item: any) => {
    if (selectedCard === item) {
      setSelectedCard(null);
    } else {
      setSelectedCard(item);
    }
  };

  const verifyPspPaymentStatus = async (id: any) => {
    await mediapushService
      .verifyPaymentStatus(id)
      .then((response) => {
        if (response?.status === "success") {
          setpaymentStatus(response?.data?.transaction?.status);
          socket.emit("accept-message", {
            messageId,
            userType: FAN,
            isToDeliverMedia: true,
          });
        } else {
          setpaymentStatus("error");
        }
      })
      .catch((error) => {
        message.error(t(error));
      });
  };

  const fromPayment = sessionStorage.getItem("payment-page") === "True";

  const handleNavigate = () => {
    if (fromPayment) {
      if (type === "subscription") {
        sessionStorage.removeItem("payment-page");
        return navigate(`/p/${user?.username}`);
      } else {
        return navigate(-1);
      }
    } else {
      return navigate(-1);
    }
  };

  const calculateAmount = () => {
    let baseAmount;
    if (type === "subscription") {
      baseAmount = choosedPlan;
    } else if (type === "mediaPush") {
      baseAmount = mediaPushDetails.promo ? mediaPushDetails.resultPrice : mediaPushDetails.price;
    } else if (type === "collection") {
      baseAmount = collectionDetails && collectionDetails.price;
    } else if (type === PAYMENT_TYPES.LIVE) {
      baseAmount = liveDetails?.amount || 0;
    } else if (type === PAYMENT_TYPES.LIVE_TIP) {
      baseAmount = liveTipDetails?.amount || 0;
    } else {
      baseAmount = 0;
    }

    if (promoCodeDiscount) {
      baseAmount -= promoCodeDiscount;
    }

    return baseAmount?.toFixed(2);
  };

  const getCurrentPaymentGateway = async () => {
    await settingService.getPayentGateway().then((res: any) => {
      switch (type) {
        case "subscription":
          setPayemntGateway(res?.subscription);
          break;
        case "mediaPush":
          setPayemntGateway(res?.mediaPush);
          break;
        case PAYMENT_TYPES.LIVE:
          setPayemntGateway(res?.stream);
          break;
        case PAYMENT_TYPES.COLLECTION:
          setPayemntGateway(res?.collection);
          break;
        case PAYMENT_TYPES.LIVE_TIP:
          setPayemntGateway(res?.streamTip);
          break;
        default:
          setPayemntGateway("shift4");
          break;
      }
    });
  };

  const formatNumber = (number: string) => {
    return number
      .replace(/\s?/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  useEffect(() => {
    const formattedCardNumber = formatCardNumber(cardNumber);
    if (formattedCardNumber !== cardNumber) {
      setCardNumber(formattedCardNumber);
    }
  }, [cardNumber]);

  return (
    <Spin spinning={isLoading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout>
        <Layout.Content className="content fanSubscribeContentWrap gradient-background">
          <Col>
            <Row>
              {/* <div className="fanSubscribeHeaderBg"></div> */}
              <div className="fanSubscribeHeaderContent">
                <Row className="relative justify-content-center">
                  {/* <ArrowLeftIconComponent top={6} navigateTo={handleNavigate()} /> */}
                  <img className="header-arrow-left" src={NewArrowLeftIcon} alt="arrow left" style={{ top: 6 }} onClick={handleNavigate} />
                  <Row className="ml-20" align="middle">
                    <img src={LockIcon} width={20} alt="arrow left" />
                    <Typography.Text className="ml-10 header-title-black-28">{t("secure-payment")}</Typography.Text>
                  </Row>
                </Row>
                <Row className="mt-20">
                  <Typography.Text className="fanPaymentRecapLabel">{t("summary")}</Typography.Text>
                </Row>
                <Row className="d-flex justify-content-between items-center mt-10">
                  <Typography.Text className="fanPaymentMiddleLabel">
                    {`${
                      planId === "monthly"
                        ? t("monthly-label") + " "
                        : planId === "trimestrial"
                          ? t("three-months-label") + " "
                          : planId === "half-yearly"
                            ? t("six-months-label") + " "
                            : planId === "yearly"
                              ? t("yearly-label") + " "
                              : ""
                    }${
                      type === "mediaPush"
                        ? t("Media-Push-label")
                        : type === "subscription"
                          ? t("subscribe-label")
                          : type === PAYMENT_TYPES.COLLECTION
                            ? t("collection")
                            : type === PAYMENT_TYPES.LIVE
                              ? t("live")
                              : type === PAYMENT_TYPES.LIVE_TIP
                                ? t("live-tip-to-label")
                                : ""
                    } ` + `${user ? user.name : ""}`}
                  </Typography.Text>
                  <Typography.Text className="fanPaymentMiddleLabel">
                    {type === "subscription"
                      ? choosedPlan
                      : type === "mediaPush"
                        ? mediaPushDetails.promo
                          ? mediaPushDetails.resultPrice
                          : mediaPushDetails.price
                        : type === PAYMENT_TYPES.COLLECTION
                          ? collectionDetails?.price
                          : type === PAYMENT_TYPES.LIVE
                            ? liveDetails?.amount
                            : type === PAYMENT_TYPES.LIVE_TIP
                              ? liveTipDetails?.amount
                              : ""}{" "}
                    €
                  </Typography.Text>
                </Row>
                {/* {PromoCodeDetails && (
                <Row className="d-flex justify-content-between items-center mt-3">
                  <Typography.Text className="fanPaymentSmallLabel">
                    {t("promo-code-discount")}
                  </Typography.Text>
                  <Typography.Text className="fanPaymentSmallLabel">
                    {promoCodeDiscount && promoCodeDiscount + "€"}
                  </Typography.Text>
                </Row>
              )} */}
                {PromoCodeDetails && (
                  <Row className="d-flex justify-content-between items-center mt-3">
                    <Typography.Text className="fanPaymentMiddleLabel">{t("promo-code-discount")}</Typography.Text>
                    <Typography.Text className="fanPaymentMiddleLabel">{promoCodeDiscount && "-" + promoCodeDiscount + " €"}</Typography.Text>
                  </Row>
                )}
                {country && vatPercentage !== 0 && (
                  <Row className="d-flex justify-content-between items-center mt-3">
                    <Typography.Text className="fanPaymentMiddleLabel tva">{t("including-vat")}</Typography.Text>
                    <Typography.Text className="fanPaymentMiddleLabel tva">
                      {NetPaymentAoumt
                        ? ((NetPaymentAoumt * vatPercentage) / 100).toFixed(2)
                        : type === "subscription"
                          ? !promoCodeDiscount
                            ? ((choosedPlan * vatPercentage) / 100).toFixed(2)
                            : ((NetPaymentAoumt * vatPercentage) / 100).toFixed(2)
                          : type === "mediaPush"
                            ? mediaPushDetails.promo
                              ? !promoCodeDiscount
                                ? ((mediaPushDetails.resultPrice * vatPercentage) / 100).toFixed(2)
                                : ((NetPaymentAoumt * vatPercentage) / 100).toFixed(2)
                              : !promoCodeDiscount
                                ? ((mediaPushDetails.price * vatPercentage) / 100).toFixed(2)
                                : ((NetPaymentAoumt * vatPercentage) / 100).toFixed(2)
                            : type === PAYMENT_TYPES.LIVE
                              ? !promoCodeDiscount
                                ? (((liveDetails?.amount || 0) * vatPercentage) / 100).toFixed(2)
                                : ""
                              : type === PAYMENT_TYPES.COLLECTION
                                ? !promoCodeDiscount
                                  ? (((collectionDetails?.price || 0) * vatPercentage) / 100).toFixed(2)
                                  : ""
                                : type === PAYMENT_TYPES.LIVE_TIP
                                  ? !promoCodeDiscount
                                    ? (((liveTipDetails?.amount || 0) * vatPercentage) / 100).toFixed(2)
                                    : ""
                                  : ""}{" "}
                      €
                    </Typography.Text>
                  </Row>
                )}
                {/* {promoCodeDiscount && vatPercentage !== 0 && (
                  <>
                    <hr></hr>
                    <Row className="d-flex justify-content-between items-center mt-3">
                      <Typography.Text className="fanPaymentMiddleLabel">{t("total-amount")}</Typography.Text>
                      <Typography.Text className="fanPaymentMiddleLabel">
                        {(NetPaymentAoumt + (NetPaymentAoumt * vatPercentage) / 100).toFixed(2) + " €"}
                      </Typography.Text>
                    </Row>
                  </>
                )}
                {!promoCodeDiscount && vatPercentage !== 0 && (
                  <>
                    <hr></hr>
                    <Row className="d-flex justify-content-between items-center mt-3">
                      <Typography.Text className="fanPaymentMiddleLabel">{t("total-amount")}</Typography.Text>
                      <Typography.Text className="fanPaymentMiddleLabel">
                        {type === "subscription"
                          ? !promoCodeDiscount
                            ? (choosedPlan * (1 + vatPercentage / 100)).toFixed(2)
                            : (NetPaymentAoumt * (1 + vatPercentage / 100)).toFixed(2)
                          : type === "mediaPush"
                            ? mediaPushDetails.promo
                              ? !promoCodeDiscount
                                ? (mediaPushDetails.resultPrice * (1 + vatPercentage / 100)).toFixed(2)
                                : (NetPaymentAoumt * (1 + vatPercentage / 100)).toFixed(2)
                              : !promoCodeDiscount
                                ? (mediaPushDetails.price * (1 + vatPercentage / 100)).toFixed(2)
                                : (NetPaymentAoumt * (1 + vatPercentage / 100)).toFixed(2)
                            : type === PAYMENT_TYPES.LIVE
                              ? !promoCodeDiscount
                                ? ((liveDetails?.amount || 0) * (1 + vatPercentage / 100)).toFixed(2)
                                : ""
                              : type === PAYMENT_TYPES.LIVE_TIP
                                ? !promoCodeDiscount
                                  ? ((liveTipDetails?.amount || 0) * (1 + vatPercentage / 100)).toFixed(2)
                                  : ""
                                : ""}{" "}
                        €{" "}
                      </Typography.Text>
                    </Row>
                  </>
                )} */}
                <Row className="mt-20">
                  <Typography.Text className="fanPaymentLargeLabel">{t("promo-code")}</Typography.Text>
                </Row>
                <Row className="mt-7">
                  <InputComponent
                    className="authInput uppercaseInput"
                    placeholder="EX : AB123"
                    value={PromoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    //onBlur={PromoCodeApply}
                    suffix={
                      <Button onClick={PromoCodeApply} className="fanPaymentCodeBtnSuffix">
                        {t("apply-label")}
                      </Button>
                    }
                  />
                </Row>
              </div>
            </Row>
            {payemntGateway === "shift4" && type !== "subscription" && allCards.length > 0 && (
              <Row className="SaveCardContainer">
                {allCards?.map((item: any, index: any) => {
                  return (
                    <SaveCards
                      key={index}
                      data={item}
                      totalData={allCards?.length}
                      selectedCard={selectedCard}
                      handleClick={() => handleSelectCard(item)}
                    />
                  );
                })}
              </Row>
            )}
            <Row className="fanPaymentMainContent">
              <Row className="w-full">
                <Space className="w-full" direction="vertical">
                  {/* <Typography.Text className="fanPaymentLargeLabel">{t("payment-method")}</Typography.Text> */}
                  <Radio.Group onChange={(e) => setPaymentMethod(e.target.value)} className="w-full">
                    <Space className="w-full" direction="vertical">
                      {/* {paymentTypes.map((type, index) => {
                        return <FanPaymentRadioButtonComponent key={index} name={type.name} value={type.value} suffix={type.suffix} />;
                      })} */}

                      {applePay && applePay?._canMakePaymentAvailability.APPLE_PAY === true
                        ? paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />
                        : ""}
                    </Space>
                  </Radio.Group>
                </Space>
              </Row>
              <Row className="w-full ">
                <Space className="w-full" direction="vertical">
                  <Typography.Text className="fanPaymentLargeLabel">{t("country")}</Typography.Text>
                  <CountryDropdownComponent currentValue={country} setCurrentValue={setCountry} countries={countries || []} />
                </Space>
              </Row>
              {payemntGateway === "shift4" && (
                <>
                  {selectedCard === null && (
                    <>
                      {" "}
                      <Row className="w-full">
                        <Typography.Text className="fanPaymentLargeLabel">{t("holder-name")}</Typography.Text>
                      </Row>
                      <Row className="w-full mt-5" gutter={8}>
                        <Col span={12}>
                          <Space className="w-full" direction="vertical">
                            <InputComponent
                              maxLength={19}
                              className="authInput"
                              onChange={(e) => setCardHolderName({ ...cardHolderName, firstname: e.target.value })}
                              value={cardHolderName?.firstname}
                              placeholder={t("first-name-label") || ""}
                              disabled={selectedCard !== null}
                            />
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space className="w-full" direction="vertical">
                            <InputComponent
                              maxLength={19}
                              className="authInput"
                              onChange={(e) => setCardHolderName({ ...cardHolderName, lastname: e.target.value })}
                              value={cardHolderName?.lastname}
                              placeholder={t("last-name-label") || ""}
                              disabled={selectedCard !== null}
                            />
                          </Space>
                        </Col>
                      </Row>
                      <Row className="w-full mt-20">
                        <Space className="w-full" direction="vertical">
                          <Typography.Text className="fanPaymentLargeLabel">{t("card-number")}</Typography.Text>
                          <InputComponent
                            maxLength={19}
                            className="authInput"
                            onChange={(e) => {
                              if (e.target.value.match(cardnumberReg)) {
                                setCardNumber(formatNumber(e.target.value));
                              }
                            }}
                            value={cardNumber}
                            placeholder="1234 1234 1234"
                            onKeyDown={(e) => setKey(e.key)}
                            disabled={selectedCard !== null}
                            inputMode="numeric"
                          />
                        </Space>
                      </Row>
                      <Row className="w-full mt-20 mb-20">
                        <Col span={12} className="custom-col">
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Typography.Text className="fanPaymentLargeLabel">{t("expiration-date")}</Typography.Text>
                            <InputComponent
                              className="authInput"
                              inputMode="numeric"
                              placeholder="MM/YY"
                              maxLength={5}
                              onChange={(e) => {
                                if (e.target.value.match(CardMonthYearReg)) {
                                  const [month, year] = e.target.value.split("/");
                                  const inputYear = parseInt(year, 10);
                                  const inputMonth = parseInt(month, 10);

                                  setMonthYear(e.target.value);
                                  if (inputYear) {
                                    if (inputYear >= expCurrentYear && inputMonth <= 12) {
                                      setMonthYear(e.target.value);
                                      setError("");
                                    } else {
                                      setError("Enter Valid Expiry Month and Year");
                                    }
                                  }
                                }
                              }}
                              value={MonthYear}
                              onKeyDown={(e) => setKey(e.key)}
                              disabled={selectedCard !== null}
                            />
                          </Space>
                        </Col>
                        <Col span={12} className="custom-col">
                          <Space direction="vertical" style={{ width: "100%" }}>
                            <Typography.Text className="fanPaymentLargeLabel">{t("cryptogram")}</Typography.Text>
                            <InputComponent
                              className="authInput"
                              inputMode="numeric"
                              placeholder="CVC"
                              onChange={(e) => {
                                if (e.target.value.match(cvvReg)) {
                                  setCvv(e.target.value);
                                }
                              }}
                              value={cvv}
                              maxLength={3}
                              disabled={selectedCard !== null}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row className="">
                    <Checkbox className="paymentTermsCheckBox" checked={checkboxChecked} onChange={handleCheckboxChange}>
                      {t("payment-terms")}
                    </Checkbox>
                  </Row>
                  <Row className="mt-20 w-full justify-content-center">
                    <Typography.Text className="transaction-text">{t("transaction-appear-bank-statement-universal-media")}</Typography.Text>
                  </Row>

                  <Row className="mt-20 w-full justify-content-center">
                    <img src={InfoPaymentImg} width={200} alt="" />
                  </Row>
                  <Row className="mt-10 w-full justify-content-center">
                    <Typography.Text className="info-company-text">ITDW SRL</Typography.Text>
                  </Row>
                  <Row className="w-full justify-content-center">
                    <Typography.Text className="info-company-text">VAT: BE0789767565</Typography.Text>
                  </Row>
                  <Row className="w-full justify-content-center mb-60">
                    <Typography.Text className="info-company-text">6280, Gerpinnes, Belgium</Typography.Text>
                  </Row>
                  <Row className="fanSubscribeContinueButtonWrap stickyButtonWrapper">
                    <div className="w-button">
                      <AuthButtonComponent onClick={() => subscribe(selectedCard ? selectedCard?.id : null)} label={t("confirm")} disabled={!buy} />
                    </div>
                  </Row>
                </>
              )}
              {payemntGateway === "stripe" && (
                <StripeCheckoutForm
                  setLoading={setIsLoading}
                  checkboxChecked={checkboxChecked}
                  handleCheckboxChange={handleCheckboxChange}
                  loading={isLoading}
                  handlePayment={subscribe}
                  paymentAmount={calculateAmount}
                />
              )}

              {/* {paymentMethod === "bankCard" ? (
                <>
                  {" "} */}
              {/* </>
              ) : (
                ""
              )} */}

              {/* <Row className="fanSubscribeContinueButtonWrap mt-40">
                <Typography.Text className="fanPaymentSmallLabel">{t("fan-payment-end-text")}</Typography.Text>
              </Row> */}
            </Row>
          </Col>
          <FanPaymentPopUp paymentStatus={paymentStatus} setPaymentStatus={setpaymentStatus} username={user?.username!} />
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default FanPaymentPage;
