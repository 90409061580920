import React, { useEffect } from "react";
import { Modal, Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import "./index.less";

type props = {
  isOpen: boolean;
  onClose?: () => void;
};

const UnlockLinkInfoModalComponent: React.FC<props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="unlockLinkInfoModalModal"
      title={t("important")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <div className="w-full">
          <Row className="row">
            <Button className="confirmBtn" type="link" onClick={onClose}>
              <div className="confirm-btn-background">{t("confirm-btn")}</div>
            </Button>
          </Row>
        </div>
      }
    >
      <div className="w-full">
        <Row className="row">
          <p>
            {t("unlock-link-info-modal-message")}
            {" → "}“{t("unlock-link")}”
          </p>
        </Row>
      </div>
    </Modal>
  );
};

export default UnlockLinkInfoModalComponent;
