import { Button, Typography } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LiveEventImg from "../../../assets/images/live-fight-event/live-fight-event-2.webp";
import LiveEventIsLiveImg from "../../../assets/images/live-fight-event/live-fight-event-4.webp";
import LiveIconGift from "../../../assets/videos/live-gift-icon.mp4";
import { StoreState } from "../../../redux/store";
import "./index.less";

interface FightEventComponentProps {
  isFromNow?: boolean;
  isFromPublic?: boolean;
  showModal?: () => void;
  isLive?: boolean;
}

const FightEventComponent: React.FC<FightEventComponentProps> = ({ isFromNow, isFromPublic, showModal, isLive = false }) => {
  const navigate = useNavigate();

  const rtmpStream = useSelector((state: StoreState) => state.lives.rtmpLive);

  const handleGoLive = () => {
    if (rtmpStream && rtmpStream.isPaymentVerified) {
      navigate(`/fan/live/${rtmpStream._id}`);
    } else {
      navigate("/common/event-payment?fln=rtlf");
    }
  };

  const handleShowVideo = () => {
    navigate(`/fan/live/sebbyvsillan`);
  };

  return (
    <>
      {isFromPublic && !isLive ? (
        <div className="live-event-container" onClick={showModal}>
          <div>
            <img className="live-event-img" src={LiveEventImg} alt="Fight" />
            <div className="live-event-overlay">
              <Button className="live-event-btn billeterie-btn">Billetterie</Button>
              <Button className="live-event-btn more-info-btn">Plus d'info</Button>
            </div>
          </div>
        </div>
      ) : rtmpStream ? (
        <div className="live-event-container">
          {rtmpStream.isStreaming && isFromNow ? (
            <div onClick={() => handleGoLive()}>
              <img className="live-event-img" src={LiveEventIsLiveImg} alt="Fight" />
              <div className="is-live-event-overlay">
                <div className="is-live-event-btn">
                  <video src={LiveIconGift} width={34} autoPlay loop muted playsInline />
                  <Typography.Text className="is-live-event-text">EN DIRECT</Typography.Text>
                </div>
              </div>
            </div>
          ) : (
            !rtmpStream.isStreaming && (
              <div onClick={() => navigate("/fan/ticket")}>
                <img className="live-event-img" src={LiveEventImg} alt="Fight" />
                <div className="live-event-overlay">
                  <Button className="live-event-btn billeterie-btn">Billetterie</Button>
                  <Button className="live-event-btn more-info-btn">Plus d'info</Button>
                </div>
              </div>
            )
          )}
        </div>
      ) : isLive ? (
        <div className="live-event-container">
          <div onClick={() => handleShowVideo()}>
            <img className="live-event-img" src={LiveEventIsLiveImg} alt="Fight" />
            <div className="is-live-event-overlay">
              <div className="is-live-event-btn cursor-pointer">
                <Typography.Text className="is-live-event-text">Clique pour voir le combat</Typography.Text>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {}
    </>
  );
};

export default FightEventComponent;
