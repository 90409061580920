import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import "./index.less";

type CollectionDeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deleteCollection: () => void;
};

const CollectionDeleteModalComponent: React.FC<CollectionDeleteModalProps> = ({ isOpen, onClose, deleteCollection }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Modal
      className="collection-delete-modal"
      title={t("delete-collection-modal-title")}
      open={isOpen}
      centered
      closable={false}
      onCancel={onClose}
      footer={
        <Button className="cancel-btn" type="link" onClick={onClose}>
          {t("cancel")}
        </Button>
      }
    >
      <Row className="g-15 mt-10">
        <Col className="mt-5 w-100 text-center" onClick={deleteCollection}>
          <Typography.Text className="actionBtn">
            <div className="confirm-btn-background">{t("delete-collection")}</div>
          </Typography.Text>
        </Col>
      </Row>
    </Modal>
  );
};

export default CollectionDeleteModalComponent;
