import React, {useEffect, useRef, useState} from "react";
import Player from "video.js/dist/types/player";
import videoJs from "video.js";
import { IAgoraTrack } from "../../helpers/types";
import "video.js/dist/video-js.min.css";
import "./index.less";

interface props extends IAgoraTrack {
  isBlur?: boolean,
  index?: number,
  sessionId?: string,
  isFromMobile: boolean
}
const options = {
  bigPlayButton: false,
  controls: false,
  autoPlay: true,
  playsInline: true,
  muted: true,
  controlBar: {
    playToggle: false,
    pictureInPictureToggle: false,
    volumePanel: false,
  },
}

const AgoraRTMPPlayerComponent: React.FC<props> = ({
 tracks,
 isBlur,
 index,
 sessionId,
 isFromMobile
}) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const ref = useRef<Player>();
  const [isRefUpdated, setIsRefUpdated] = useState<boolean>(false);
  const [orientation, setOrientation] = useState<string | null>(null);

  useEffect(() => {
    if (!playerRef?.current)
      return;
    ref.current = videoJs(playerRef.current, options);
    setIsRefUpdated(true);
  }, [])
  
  useEffect(() => {
    if (!isRefUpdated || !ref?.current)
      return;

    if (tracks.length) {
      const mediaStreamTracks: MediaStreamTrack[] = tracks.map((track) =>  track.getMediaStreamTrack());           
      (ref.current.tech().el() as HTMLVideoElement).srcObject = new MediaStream(mediaStreamTracks);
      ref.current.tech_.el_.style.objectFit = isFromMobile ? 'cover' : 'contain';
    } else {
      (ref.current.tech().el() as HTMLVideoElement).srcObject = null;
      ref.current.tech_.el_.style.objectFit = 'contain';
    }
  }, [isRefUpdated, tracks])

  useEffect(() => {
    if (index !== undefined && !isNaN(index)) {
      const videoElement = document.getElementById(`video-rtmp-${index}`);
      if (videoElement) {
        if (isBlur && !videoElement.classList.contains("blur"))
          videoElement.classList.add("blur");
        if (!isBlur && videoElement.classList.contains("blur"))
          videoElement.classList.remove("blur");
      }
    }
  }, [isBlur])

  // Handle orientation changes and fullscreen behavior
useEffect(() => {
    const handleOrientationChange = () => {
      const newOrientation = window.screen.orientation?.type || null;
      setOrientation(newOrientation);
  
      const videoElement = ref.current?.tech().el() as HTMLVideoElement;
  
      if (videoElement) {
        if (newOrientation?.startsWith("landscape")) {
          // Enter fullscreen for landscape
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if ((videoElement as any).webkitEnterFullscreen) {
            // For Safari on iOS
            (videoElement as any).webkitEnterFullscreen();
          }
        } else if (newOrientation?.startsWith("portrait")) {
          // Exit fullscreen for portrait
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
          }
        }
      }
    };
  
    const handleResize = () => {
      // Fallback for older iOS versions that do not support screen.orientation
      const isLandscape = window.innerWidth > window.innerHeight;
      setOrientation(isLandscape ? 'landscape' : 'portrait');
  
      const videoElement = ref.current?.tech().el() as HTMLVideoElement;
  
      if (videoElement) {
        if (isLandscape) {
          // Enter fullscreen for landscape mode
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if ((videoElement as any).webkitEnterFullscreen) {
            // For Safari on iOS
            (videoElement as any).webkitEnterFullscreen();
          }
        } else {
          // Exit fullscreen for portrait mode
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
          }
        }
      }
    };
  
    if (window.screen.orientation) {
      // Modern browsers with screen.orientation support
      window.screen.orientation.addEventListener("change", handleOrientationChange);
    } else {
      // Fallback for older browsers (iOS 15 and below)
      window.addEventListener('resize', handleResize);
    }
  
    // Cleanup event listeners on component unmount
    return () => {
      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener("change", handleOrientationChange);
      } else {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [ref]);

  return <div className="rtmp-jvs"><video
    id={`video-rtmp-${index}`}
    className={`vjs-matrix video-js vjs-fill`}
    ref={playerRef}
    autoPlay
    width="100%"
    height="100%"
    playsInline
    muted
  /></div>;
};

export default AgoraRTMPPlayerComponent;