import { Col, Layout, message, Row, Typography } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import OptionIcon from "../../../../assets/icons/collection-option.svg";
import VideoIcon from "../../../../assets/icons/jouer.svg";
import LockIcon from "../../../../assets/icons/lock-outlined-without-point.svg";
import ArrowLeftIconComponent from "../../../../components/ArrowLeftIconComponent";
import CollectionDeleteModalComponent from "../../../../components/Collections/Modals/CollectionDeleteModalComponent";
import CollectionDetailModalComponent from "../../../../components/Collections/Modals/CollectionDetailModalComponent";
import ShareModalComponent from "../../../../components/ShareModalComponent";
import SpinnerComponent from "../../../../components/SpinnerComponent/SpinnerComponent";
import { ICreatorCollection } from "../../../../helpers/types";
import { deleteCollectionById } from "../../../../redux/collections/collectionsSlice";
import { AppDispatch } from "../../../../redux/store";
import { collectionService } from "../../../../services/collection.service";
import "./index.less";

const dailyPreviousReport = Array.from({ length: 15 }).map((_, index) => ({
  revenue: {
    total_cost_month: 10 + index * 2,
    stats: index % 2 === 0 ? "+5%" : "-3%",
    date: "29/09/24",
  },
}));

const CreatorDetailCollectionPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const [collectionData, setCollectionData] = useState<ICreatorCollection | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openOptionModal, setOpenOptionModal] = useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const collectionLink = `${window.location.origin}/common/collection/${collectionId}`;

  useEffect(() => {
    if (collectionId) {
      setIsLoading(true);
      collectionService
        .getCreatorCollectionDetail(collectionId)
        .then((data: ICreatorCollection) => {
          setCollectionData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("error:", error);
          setIsLoading(false);
        });
    }
  }, [collectionId]);

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [collectionId]);

  const openModal = (value: string) => {
    switch (value) {
      case "options":
        setOpenOptionModal(true);
        break;
      case "share":
        setOpenOptionModal(false);
        setOpenShareModal(true);
        break;
      case "delete":
        setOpenOptionModal(false);
        setOpenDeleteModal(true);
        break;
      default:
        break;
    }
  };

  const closeModal = (value: string) => {
    switch (value) {
      case "options":
        setOpenOptionModal(false);
        break;
      case "share":
        setOpenShareModal(false);
        break;
      case "delete":
        setOpenDeleteModal(false);
        break;
      default:
        break;
    }
  };

  const deleteCollection = async () => {
    if (collectionId) {
      try {
        setIsLoading(true);
        const resultAction = await dispatch(deleteCollectionById(collectionId));
        if (deleteCollectionById.fulfilled.match(resultAction)) {
          message.success(t("collection-successfully-deleted"));
          setOpenDeleteModal(false);
          navigate("/creator/profile");
        } else {
          console.error("Error deleting collection:", resultAction.payload);
        }
      } catch (error) {
        console.error("Error deleting collection:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Layout className="gradient-background-profile">
      <Layout.Content className="content creator-detail-collection-page-wrapper background-transparent">
        <Row className="collection-header">
          <ArrowLeftIconComponent top={5} left={11} />
          <div className="title-container">
            <Typography.Text className="header-title-black-28">{t("details")}</Typography.Text>
          </div>
          <div className="right-icon">
            <img src={OptionIcon} width={24} onClick={() => openModal("options")} />
          </div>
        </Row>
        {isLoading ? (
          <Row className="w-full justify-content-center mt-20">
            <SpinnerComponent />
          </Row>
        ) : (
          <>
            {/* Files row */}
            <Row className="files-row">
              {collectionData?.files.map((file) => (
                <Col key={file.url} className="file-item">
                  <div className="file-wrapper">
                    {file.type.includes("video") && <img className="play-icon-overlay" src={VideoIcon} />}
                    {file.isPrivate && <img className="private-icon-overlay" src={LockIcon} />}
                    <img src={file?.type.includes("video") ? file.thumbnailUrl : file?.url} alt={file.type} className="file-image" />
                  </div>
                </Col>
              ))}
            </Row>
            <div className="collection-resume-padding">
              {/* Price, revenue and sales row */}
              <Row className="mt-20 container stats-container">
                <Col className="collection-stats-col">
                  <Typography.Text className="principal-text">{`${collectionData?.price.toFixed(2) || "0.00"} €`}</Typography.Text>
                  <Typography.Text className="subtitle-text">{t("price-per-unit")}</Typography.Text>
                </Col>
                <Col className="collection-stats-col">
                  <Typography.Text className="principal-text">{`${collectionData?.totalRevenue.toFixed(2) || "0.00"} €`}</Typography.Text>
                  <Typography.Text className="subtitle-text">{t("income-generated")}</Typography.Text>
                </Col>
                <Col className="collection-stats-col">
                  <Typography.Text className="principal-text">{collectionData?.totalPurchases || 0}</Typography.Text>
                  <Typography.Text className="subtitle-text">{t("total-unlock")}</Typography.Text>
                </Col>
              </Row>
              {/* Title and description row */}
              <Row className="mt-20 container info-container">
                <Col className="collection-info-col">
                  <Typography.Text className="title-text">{collectionData?.title}</Typography.Text>
                  <Typography.Text className="mt-7 description-text">{collectionData?.description}</Typography.Text>
                </Col>
              </Row>
              {/* Report row */}
              {/* <Row className="mt-20 container report-container">
                <Row className="report-text-container">
                  <Typography.Text className="report-text">{t("daily-report")}</Typography.Text>
                </Row>
                {dailyPreviousReport.map((report, index) => (
                  <Row className="report-row" key={index}>
                    <Space className="gap-23">
                      <Typography.Text className="font-16-regular text-white-color">{report.revenue.date}</Typography.Text>
                      <Tag className={`revenueTag ${report.revenue.stats.includes("+") ? "increased" : "decreased"}`}>{report.revenue.stats}</Tag>
                    </Space>
                    <Typography.Text className="font-16-bold text-white-color">
                      {report.revenue.total_cost_month.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                      €
                    </Typography.Text>
                  </Row>
                ))}
              </Row> */}
            </div>
            <CollectionDetailModalComponent
              isOpen={openOptionModal}
              link={collectionLink}
              onClose={() => closeModal("options")}
              openShareModal={() => openModal("share")}
              openDeleteModal={() => openModal("delete")}
            />
            <CollectionDeleteModalComponent isOpen={openDeleteModal} onClose={() => closeModal("delete")} deleteCollection={deleteCollection} />
            <ShareModalComponent isOpen={openShareModal} onClose={() => closeModal("share")} link={collectionLink} />
          </>
        )}
      </Layout.Content>
    </Layout>
  );
};

export default CreatorDetailCollectionPage;
