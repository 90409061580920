import { Button, Col, Layout, message, Row, Space, Spin, Typography } from "antd";
import dayjs from "dayjs";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import ArrowChatIcon from "../../../assets/icons/arrow-chat.svg";
import AvatarComponent from "../../../components/AvatarComponent";
import ConversationThreeDotsModalComponent from "../../../components/ConversationThreeDotsModalComponent";
import CountDownComponent from "../../../components/CountDownComponent";
import CreatorMessageAcceptMediaModalComponent from "../../../components/CreatorMessageAcceptMediaModalComponent";
import CreatorSavedResponsesModalComponent from "../../../components/CreatorSavedResponsesModalComponent";
import FanAvatarComponent from "../../../components/FanAvatarComponent";
import FanRequireSubscribeModalComponent from "../../../components/FanRequireSubscribeModalComponent";
import IncomeMessageComponent from "../../../components/IncomeMessageItemComponent";
import MailboxMediaModal from "../../../components/MailboxMediaModal";
import MessageInputComponent from "../../../components/MessageInputComponent";
import MessageMediaItemComponent from "../../../components/MessageMediaItemComponent";
import MessageTextItemComponent from "../../../components/MessageTextItemComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import ThreeDotsComponent from "../../../components/ThreeDotsComponent";
import VerifiedUserIconComponent from "../../../components/VerifiedUserIconComponent/VerifiyedUserIconComponent";
import {
  ACCEPTED,
  C_CURRENT_TAB,
  CONVERSATION_DATE_FORMAT,
  CREATOR,
  FAN,
  FILE,
  HELLO_MESSAGE,
  HOURS_TO_EXPIRE,
  MESSAGES_PER_PAGE,
  PENDING,
  TEXT,
} from "../../../helpers/constant";
import { usePreviousLocationContext } from "../../../helpers/PreviousLocationContext";
import { IConversationDTO, IMediapush, IMessage, ModalMediaType, PageableData, VerifySubscriptionPayload } from "../../../helpers/types";
import { createMediapush } from "../../../redux/mediaPush/mediaPushSlice";
import {
  getConversationById,
  readAllMessagesInConversation,
  updateConversationsMediaPush,
  updateMessagesData,
} from "../../../redux/messages/messagesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { authService, fileService, subscriptionsService } from "../../../services";
import { socket } from "../../../socket";
import "./index.less";

const CreatorConversationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const previousLocation = usePreviousLocationContext();
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const { conversationId } = useParams();
  const { selectedConversation, error } = useSelector((state: StoreState) => state.messages);
  const [isConversationStep, setIsConversationStep] = useState<boolean>(selectedConversation.currentUser?.role === CREATOR);
  const [isSubscribed, setIsSubscribed] = useState<boolean | null>(null);
  //const [messages, setMessages] = useState<IMessage[]>([]);
  const [isSavedResponsesModalOpen, setIsSavedResponsesModalOpen] = useState<boolean>(false);
  const [isConversationModalOpen, setIsConversationModalOpen] = useState<boolean>(false);
  const [quickAnswer, setQuickAnswer] = useState<string>("");
  const [messageIdToAccept, setMessageIdToAccept] = useState<string>("");
  const [isRequireSubscribeModalOpen, setIsRequireSubscribeModalOpen] = useState<boolean>(false);
  const [isAcceptMediaModalOpen, setIsAcceptMediaModalOpen] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  // const [page, setPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(-1);
  //const [hasNextPage, setHasNextPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [oldestMessageId, setOldestMessageId] = useState<string | null>(null);
  const [openMediaModal, setOpenMediaModal] = useState<boolean | false>(false);
  const [openFileSelector, { plainFiles, clear }] = useFilePicker({
    readAs: "ArrayBuffer",
    multiple: false,
    accept: ["image/*", "video/*"],
    limitFilesConfig: { max: 1 },
  });
  const params: any = new URLSearchParams(location.search);
  const paramsFiles: Array<any[]> = JSON.parse(params.get("selected"));
  const [ids, setIds] = useState<any>([]);

  const messageData = useSelector((state: StoreState) =>
    state.messages.messagesData.find((conversation) => conversation.conversationId === selectedConversation._id)
  );
  const page = messageData?.page || 1;
  const hasNextPage = (messageData && messageData.messages.length > 0 && messageData?.page < messageData?.totalPages) || false;
  //const oldestMessageId = messageData?.oldestMessageId || null;
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState<number | null>(null);

  useEffect(() => {
    if (paramsFiles?.length > 0) {
      setIds(paramsFiles);
    }
    return () => {
      setIds([]);
      //TODO refactor this code
      //window.history.replaceState(null, "", window.location.pathname);
    };
  }, []);

  useEffect(() => {
    if (ids.length !== 0) {
      getAllSelectedFiles(ids);
    }
  }, [ids]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      if (conversationId !== "new") {
        if (!messageData?.messages || messageData?.messages.length < MESSAGES_PER_PAGE) {
          socket.emit("find-all-messages", { conversationId: conversationId, page: page });
          setLoading(true);
        }
        socket.emit("read-all-messages", conversationId);
        dispatch(readAllMessagesInConversation({ conversationId, conversationType: window.localStorage.getItem(C_CURRENT_TAB) }));
        if (!isEmpty(selectedConversation)) {
          updateSubscription();
        } else {
          if (conversationId !== undefined) {
            dispatch(getConversationById(conversationId));
          }
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   if (totalPages !== -1 && page >= totalPages) {
  //     setHasNextPage(false);
  //   }
  // }, [totalPages]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollPosition ? scrollRef.current.scrollHeight - scrollPosition : scrollRef.current.scrollHeight;
      scrollPosition && setScrollPosition(null);
    }
  };

  useEffect(() => {
    // if (oldestMessageId !== null) {
    //   // setTimeout(() => {
    //   //   const oldestMessage = document.getElementById(oldestMessageId);
    //   //   if (oldestMessage !== null) {
    //   //     oldestMessage.scrollIntoView({ behavior: "auto" });
    //   //   }

    //   //   }, 250)
    // }

    if (scrollRef.current) {
      scrollToBottom();
    } else {
      const timeoutId = setTimeout(() => {
        scrollToBottom();
      }, 0);

      return () => clearTimeout(timeoutId);
    }

    // You can adjust the delay if necessary

    // TODO: remove from here to handleMessages and handleMessage
    /* if (!isEmpty(messages)) {
      const groups = messages.reduce((groups, message) => {
        const date = message.createdAt?.split("T")[0];
        // @ts-ignore
        if (date && !groups[date]) {
          // @ts-ignore
          groups[date] = [];
        }
        // @ts-ignore
        groups[date].push(message);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          // @ts-ignore
          messages: groups[date],
        };
      });
    } */
  }, [messageData?.messages]);

  useEffect(() => {
    if (!isEmpty(plainFiles)) {
      setIsLoading(true);
      let newMessage: IMessage = {
        conversationId: conversationId,
        senderId: selectedConversation.currentUser?.userId,
        receiverId: selectedConversation.interlocutor?.userId,
        type: FILE,
        status: ACCEPTED,
        medias: [],
      };
      const formData = new FormData();
      formData.append("media", plainFiles[0]);
      fileService
        .uploadMedia(formData)
        .then((file) => {
          newMessage.medias?.push({
            fileId: file._id !== undefined ? file._id : "",
            url: file?.absolutePath !== undefined ? file?.absolutePath : "",
            thumbnails: file.thumbnails,
          });
          socket.emit("send-message", newMessage);
          // dispatch(updateConversationsOrder({
          //   conversationId,
          //   createdAt: new Date().toISOString(),
          //   conversationType: window.localStorage.getItem(C_CURRENT_TAB)
          // }))
          clear();
          setIsLoading(false);
        })
        .catch(() => {
          message.error("Something went wrong, could not send file !");
        });
    }
  }, [plainFiles]);

  function loadMoreMessages() {
    const nextPage = page + 1;
    //if (nextPage <= totalPages) {
    if (hasNextPage) {
      setLoading(true);
      //setPage(nextPage);
      socket.emit("find-all-messages", { conversationId: conversationId, page: nextPage });
    }
    // else {
    //   setHasNextPage(false);
    // }
  }

  function handleMessages(payload: PageableData<IMessage>) {
    if (!isEmpty(payload.data)) {
      //setOldestMessageId(payload.data[0]._id !== undefined ? payload.data[0]._id.toString() : null);

      dispatch(updateMessagesData(payload));
      setIsConversationStep(true);
      // setMessages((prevState) => {
      //   return uniqBy([...prevState, ...payload.data], "_id").sort((a, b) => {
      //     if (a.createdAt && b.createdAt) {
      //       let da: any = new Date(a.createdAt),
      //         db: any = new Date(b.createdAt);
      //       return da - db;
      //     }
      //     return 1;
      //   });
      // });

      //setTotalPages(payload.totalPages);
    }
    setLoading(false);
  }

  function handleMessage(data: IMessage) {
    if (data.conversationId && selectedConversation._id && data.conversationId.toString() === selectedConversation._id.toString()) {
      //dispatch(handleNewMessageData(data));
      if (data.type === FILE) {
        dispatch(updateConversationsMediaPush(data));
      }
      //console.log('inside update ', data )
      //setOldestMessageId(data._id !== undefined ? data._id.toString() : null);
      //setMessages((prevState) => uniqBy([...prevState, data], "_id"));
      socket.emit("read-all-messages", data.conversationId);
    }
  }

  function handleConversationCreated(data: IConversationDTO) {
    dispatch({
      type: getConversationById.fulfilled.type,
      payload: data,
    });
    navigate(`/creator/conversation/${data._id}`, { replace: true });
    socket.emit("find-all-messages", { conversationId: data._id, page: page });
    setLoading(true);
  }

  // function handleConversationUpdated(data: IConversationDTO) {
  //   if (data._id && selectedConversation._id && data._id.toString() === selectedConversation._id.toString()) {
  //     dispatch({
  //       type: getConversationById.fulfilled.type,
  //       payload: data,
  //     });
  //   }
  // }

  // function handleUpdateMessage(data: IMessage) {
  //   if (data.conversationId && selectedConversation._id && data.conversationId.toString() === selectedConversation._id.toString()) {
  //     dispatch(handleEditMessage(data));
  //     // setMessages((messages) =>
  //     //   messages.map((message) => {
  //     //     if (message._id && data._id && message._id.toString() === data._id.toString()) {
  //     //       message = data;
  //     //     }
  //     //     return message;
  //     //   })
  //     // );
  //   }
  // }

  async function handleRemoveSubscriber() {
    if (selectedConversation && selectedConversation.interlocutor?.role === FAN) {
      const userId: any = selectedConversation.interlocutor?.userId;
      await subscriptionsService.removeSubscriber(userId).then((res) => {
        if (res?.data) {
          message.success("Fan removed");
          navigate("/creator/message");
        } else {
          message.error(t("something-want-wrong"));
        }
      });
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedConversation)) {
      updateSubscription();
    }

    socket.on("messages", handleMessages);
    socket.on("message", handleMessage);
    socket.on("conversation-created", handleConversationCreated);
    //socket.on("conversation-updated", handleConversationUpdated);
    //socket.on("update-message", handleUpdateMessage);

    return () => {
      socket.off("messages", handleMessages);
      socket.off("message", handleMessage);
      socket.off("conversation-created", handleConversationCreated);
      //socket.off("conversation-updated", handleConversationUpdated);
      //socket.off("update-message", handleUpdateMessage);
    };
  }, [selectedConversation]);

  const updateSubscription = () => {
    if (selectedConversation.currentUser?.role === FAN) {
      const payload: VerifySubscriptionPayload = {
        fanId: selectedConversation.currentUser.userId,
        creatorId: selectedConversation.interlocutor?.userId,
      };
      if (!payload.creatorId || !payload.fanId) {
        return;
      }
      subscriptionsService
        .verifySubscription(payload)
        .then((data) => {
          setIsSubscribed(data);
        })
        .catch((error) => message.error(error));
    }
  };

  const requestMediaButtonClicked = () => {
    if (isSubscribed) {
      setIsConversationStep(true);
    } else {
      setIsRequireSubscribeModalOpen(true);
    }
  };

  const messageTextSendClicked = (newMessage: string) => {
    if (isEmpty(newMessage) || newMessage.trim() === "") return;

    const messageToSend: IMessage = {
      conversationId: conversationId,
      senderId: selectedConversation.currentUser?.userId,
      receiverId: selectedConversation.interlocutor?.userId,
      type: TEXT,
      text: newMessage,
      status: selectedConversation.currentUser?.role === CREATOR ? ACCEPTED : PENDING,
      testSub: selectedConversation.currentUser?.role === FAN,
    };
    socket.emit("send-message", messageToSend);
    if (quickAnswer !== "") {
      setQuickAnswer("");
    }
  };

  const sendHelloMessage = () => {
    const helloMessage = t(HELLO_MESSAGE);
    const messageToSend: IMessage = {
      conversationId: conversationId,
      senderId: selectedConversation.currentUser?.userId,
      receiverId: selectedConversation.interlocutor?.userId,
      type: TEXT,
      text: helloMessage,
      status: PENDING,
    };
    socket.emit("send-message", messageToSend);
  };

  const acceptMessage = (message: IMessage) => {
    if (selectedConversation.currentUser?.role === CREATOR) {
      setIsAcceptMediaModalOpen(true);
      setMessageIdToAccept(message._id !== undefined ? message._id : "");
    } else {
      if (message.price !== undefined) {
        if (parseInt(message.price, 10) === 0) {
          socket.emit("accept-message", {
            messageId: message._id,
            userType: FAN,
          });
        } else {
          navigate(
            `/common/payment?creatorId=${message.senderId}&type=mediaPush&mediaPushId=${message.mediaPushId}&messageId=${message._id}&redirectUrl=creator/conversation/${selectedConversation._id}`
          );
        }
      }
    }
  };

  const rejectMessage = (messageId: string) => {
    socket.emit("reject-message", {
      messageId,
      userType: CREATOR,
    });
  };

  const selectQuickMessage = (message: string) => {
    if (message && !isEmpty(message)) {
      setQuickAnswer(message);
    }
  };

  const submitMedia = async (message: ModalMediaType) => {
    let mediaToCreate: IMediapush = {
      price: message.customAmount !== "" ? parseInt(message.customAmount, 10) : parseInt(message.amount, 10),
      text: message.message,
      isConversation: true,
      conversationId: conversationId,
      receivers: selectedConversation.interlocutor?.userId !== undefined ? [selectedConversation.interlocutor?.userId] : [],
      fileIds: [],
      promo: false,
    };
    let formData = new FormData();
    for (const [key, value] of Object.entries(mediaToCreate)) {
      formData.set(key, value);
    }
    formData.set("receivers", JSON.stringify(mediaToCreate.receivers));
    formData.set("fileIds", JSON.stringify(mediaToCreate.fileIds));
    const resp = (await dispatch(createMediapush(formData))) as any;
    if (resp.meta.requestStatus === "fulfilled") {
      if (messageIdToAccept && messageIdToAccept !== "") {
        socket.emit("accept-message", {
          messageId: messageIdToAccept,
          userType: CREATOR,
        });
      }
      setIsAcceptMediaModalOpen(false);
      setMessageIdToAccept("");
    }
  };

  const sendMediaButtonClicked = (messageId?: string) => {
    navigate(
      `/creator/mediaPush?conversation=${true}&conversationId=${selectedConversation._id}&receiverId=${selectedConversation.interlocutor?.userId}&messageId=${messageId}`
    );
  };

  function handleSendMedia() {
    openFileSelector();
    setOpenMediaModal(false);
  }

  function onPrivateGalleryClick() {
    let receiverId: string;
    let senderId: string;
    if (selectedConversation.interlocutor?.role === FAN) {
      receiverId = selectedConversation.interlocutor?.userId !== undefined ? selectedConversation.interlocutor?.userId : "";
      senderId = selectedConversation.currentUser?.userId !== undefined ? selectedConversation.currentUser?.userId : "";
    } else {
      receiverId = selectedConversation.currentUser?.userId !== undefined ? selectedConversation.currentUser?.userId : "";
      senderId = selectedConversation.interlocutor?.userId !== undefined ? selectedConversation.interlocutor?.userId : "";
    }
    navigate(
      `/common/privateGallery?conversationId=${selectedConversation._id}&receiverId=${receiverId}&senderId=${senderId}&shouldAddWatermark=${selectedConversation.currentUser?.role === FAN}`
    );
  }

  const onShareProfileClick = () => {
    setIsConversationModalOpen(false);
    setIsShareModalOpen(true);
  };

  const handleScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (e.currentTarget.scrollTop === 0) {
      setScrollPosition(e.currentTarget.scrollHeight);
      loadMoreMessages();
    }
  };

  const goToSubscriptionPage = () => {
    let url = location.pathname.substring(1);
    const param = url.split("/")[2];
    if (param === "new") {
      url = "creator/message";
    }
    navigate(`/common/subscribe/${selectedConversation?.interlocutor?.userId}?redirectUrl=${url}`);
  };

  const goToProfilePage = () => {
    if (selectedConversation?.interlocutor?.role === CREATOR) {
      navigate(`/p/${selectedConversation?.interlocutor?.username}`);
    }
  };

  const getAllSelectedFiles = async (fileIds: Array<any>) => {
    await fileService.allSelectedFiles(fileIds).then((response) => {
      let newMessage: IMessage = {
        conversationId: conversationId,
        senderId: selectedConversation.currentUser?.userId,
        receiverId: selectedConversation.interlocutor?.userId,
        type: FILE,
        status: ACCEPTED,
        medias: [],
      };
      response?.forEach((item: any) => {
        newMessage.medias?.push({
          fileId: item?._id,
          url: item?.url,
        });
      });
      socket.emit("send-message", newMessage);
    });
  };

  const handleNavigation = () => {
    /* if (
      previousLocation?.pathname !== undefined &&
      ["/creator/mediaPush", "/creator/message", "/common/payment"].includes(previousLocation?.pathname)
    ) {
      navigate("/creator/message");
    } else {
      navigate(-1);
    } */
    if (previousLocation?.pathname !== undefined && previousLocation?.pathname.includes("/p/")) {
      navigate(-1);
    } else {
      navigate("/creator/message");
    }
  };

  return (
    <>
      <Spin spinning={isLoading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
        <Layout>
          <Layout.Header className="header-chat background-transparent">
            <div className="header-chat-row">
              <div className="header-icon mt-10" onClick={handleNavigation}>
                <img width={11} src={ArrowChatIcon} alt="Loly messages" />
              </div>
              <div className="header-content">
                {selectedConversation.interlocutor?.avatar ? (
                  <AvatarComponent image={selectedConversation.interlocutor?.avatar} size={35} onClick={goToProfilePage} />
                ) : (
                  <FanAvatarComponent name={selectedConversation.interlocutor?.name} size={35} />
                )}
                <div className="header-text-wrapper">
                  <Typography.Text className="title-name-messagerie-header text-white-color" onClick={goToProfilePage}>
                    {selectedConversation.interlocutor?.name === "user-not-found" ? t("user-not-found") : selectedConversation.interlocutor?.name}
                  </Typography.Text>
                  <VerifiedUserIconComponent
                    userId={selectedConversation.interlocutor?.userId}
                    verified={selectedConversation.interlocutor?.isVerified}
                    fetch={false}
                  />
                  {isSubscribed !== null && !isSubscribed && selectedConversation.interlocutor?.name === "user-not-found" && (
                    <Button className="fanRequestMediaSubscribeBtn" onClick={goToSubscriptionPage}>
                      {t("subscribe")}
                    </Button>
                  )}
                </div>
              </div>
              <div className="three-dots-component">
                <ThreeDotsComponent onClick={() => setIsConversationModalOpen(true)} />
              </div>
            </div>
          </Layout.Header>

          <Layout.Content className={`content creatorConversationContentWrapper gradient-background-conversation ${loading && "loading"}`}>
            {loading && <SpinnerComponent />}
            {isConversationStep && (
              <Col className="messageListContent" ref={scrollRef} onScroll={handleScroll}>
                <Row className="mt-17" style={{ display: "flex", flexDirection: "column-reverse" }}>
                  <Space className="w-full" direction="vertical">
                    {messageData?.messages.map((message, index) => {
                      const isIncome = message.receiverId === selectedConversation.currentUser?.userId;
                      const target = dayjs(message.updatedAt).add(HOURS_TO_EXPIRE, "hours");
                      const remainingHours = target.diff(dayjs(), "hours");
                      const remainingMinutes = target.diff(dayjs(), "minutes");
                      const remainingSeconds = target.diff(dayjs(), "seconds");
                      const messageDate = dayjs(message.createdAt);
                      const showDate = index === 0 || messageDate.diff(dayjs(messageData.messages[index - 1].createdAt), "hour") >= 1;
                      return (
                        <>
                          {showDate && (
                            <Row className="justify-content-center mt-10 mb-10">
                              <Typography.Text className="messageDateLabel">{messageDate.format(CONVERSATION_DATE_FORMAT)}</Typography.Text>
                            </Row>
                          )}
                          <Row id={message._id} key={message._id} className={isIncome ? "justify-content-start" : "justify-content-end"}>
                            {message.type === TEXT && !isIncome && (
                              <MessageTextItemComponent message={message} userRole={selectedConversation.currentUser?.role} />
                            )}
                            {message.type === FILE && !isIncome && (
                              <>
                                {message.status === ACCEPTED && isEmpty(message.medias) ? (
                                  <CountDownComponent targetDate={message.updatedAt!} />
                                ) : null}
                                <MessageMediaItemComponent
                                  displayVideo={true}
                                  message={message}
                                  expired={
                                    message.status === ACCEPTED &&
                                    isEmpty(message.medias) &&
                                    !(remainingHours > 0) &&
                                    !(remainingMinutes > 0) &&
                                    !(remainingSeconds > 0)
                                  }
                                  isBlur={false}
                                />
                              </>
                            )}
                            {isIncome && (
                              <IncomeMessageComponent
                                message={message}
                                user={selectedConversation.interlocutor?.avatar}
                                userRole={selectedConversation.currentUser?.role}
                                acceptMessage={acceptMessage}
                                rejectMessage={rejectMessage}
                                hideStatus={message.type === FILE && selectedConversation.interlocutor?.role === FAN}
                              />
                            )}
                          </Row>
                        </>
                      );
                    })}
                  </Space>
                </Row>
              </Col>
            )}

            {selectedConversation.interlocutor?.name === "user-not-found" ? (
              ""
            ) : (
              <>
                {isConversationStep && selectedConversation?.currentUser?.role === CREATOR && (
                  <Row className="message-input-row">
                    <MessageInputComponent
                      onSendClick={messageTextSendClicked}
                      onMediaClick={() => setOpenMediaModal(true)}
                      onQuickAnswerClick={() => setIsSavedResponsesModalOpen(true)}
                      sendMedia={true}
                      sendQuickAnswer={true}
                      quickAnswer={quickAnswer}
                    />
                    <Button
                      className="mediaConversationBtn font-20-bold"
                      style={{ width: "fit-content" }}
                      onClick={() => {
                        sendMediaButtonClicked();
                      }}
                    >
                      {t("media")}
                    </Button>
                  </Row>
                )}
                {isConversationStep && selectedConversation?.currentUser?.role === FAN && selectedConversation?.fanCanSendMessage && isSubscribed && (
                  <Row>
                    <MessageInputComponent
                      onSendClick={messageTextSendClicked}
                      onMediaClick={handleSendMedia}
                      onQuickAnswerClick={() => setIsSavedResponsesModalOpen(true)}
                      sendMedia={true}
                      sendQuickAnswer={authService.getRole() === CREATOR}
                      quickAnswer={quickAnswer}
                    />
                  </Row>
                )}
                {isConversationStep &&
                  selectedConversation?.currentUser?.role === FAN &&
                  !selectedConversation?.fanCanSendMessage &&
                  isSubscribed && (
                    <>
                      <Row className={"justify-content-center"}>
                        <Button
                          disabled={!selectedConversation?.fanCanSendHelloMessage}
                          className={`whiteButton big max-w-273 font-16-bold br-21-5 mb-6 ${!selectedConversation?.fanCanSendHelloMessage ? "disabled" : ""}`}
                          onClick={sendHelloMessage}
                        >
                          Hello
                        </Button>
                      </Row>
                      <Row className={"justify-content-center"}>
                        <Typography.Text className="creatorAcceptInfoText">{t("hello-btn-message")}</Typography.Text>
                      </Row>
                    </>
                  )}
                {(!isConversationStep || (selectedConversation?.currentUser?.role === FAN && !isSubscribed)) && !loading && (
                  <div className={"d-flex justify-content-center"}>
                    <Button className="mediaConversationBtn" onClick={requestMediaButtonClicked}>
                      {t("request-private-media")}
                    </Button>
                  </div>
                )}
              </>
            )}
          </Layout.Content>
          <FanRequireSubscribeModalComponent
            isOpen={isRequireSubscribeModalOpen && selectedConversation.interlocutor?.name !== "user-not-found"}
            onClose={() => setIsRequireSubscribeModalOpen(false)}
            onSubmit={goToSubscriptionPage}
            creatorName={selectedConversation.interlocutor?.name}
          />
          <CreatorSavedResponsesModalComponent
            isOpen={isSavedResponsesModalOpen && selectedConversation.interlocutor?.name !== "user-not-found"}
            onCloseClick={() => setIsSavedResponsesModalOpen(false)}
            selectMessage={selectQuickMessage}
          />
          <ConversationThreeDotsModalComponent
            isOpen={isConversationModalOpen}
            onClose={() => setIsConversationModalOpen(false)}
            onPrivateGalleryClick={onPrivateGalleryClick}
            onShareProfileClick={onShareProfileClick}
            onCancelSubscriptionClick={handleRemoveSubscriber}
            hideShareButton={selectedConversation?.interlocutor?.role === FAN}
            hideCancelSubButton={selectedConversation?.interlocutor?.role !== FAN}
          />
          <CreatorMessageAcceptMediaModalComponent
            isOpen={isAcceptMediaModalOpen && selectedConversation.interlocutor?.name !== "user-not-found"}
            onClose={() => {
              setIsAcceptMediaModalOpen(false);
              setMessageIdToAccept("");
            }}
            onSubmit={submitMedia}
          />
          <ShareModalComponent
            isOpen={isShareModalOpen && selectedConversation.interlocutor?.name !== "user-not-found"}
            onClose={() => setIsShareModalOpen(false)}
            id={selectedConversation?.interlocutor?.userId}
            username={selectedConversation.interlocutor?.username}
          />
          <MailboxMediaModal
            isOpen={openMediaModal && selectedConversation.interlocutor?.name !== "user-not-found"}
            onClose={() => setOpenMediaModal(false)}
            onMyDevice={handleSendMedia}
            onMyAlbums={() => navigate("/creator/quickLink?id=conversation&conversationId=" + conversationId)}
          />
        </Layout>
      </Spin>
    </>
  );
};

export default CreatorConversationPage;
