import { Button, Checkbox, Col, Input, Layout, message, Radio, Row, Space, Spin, Switch, Tag, Typography, UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronBackIcon from "../../../../assets/icons/chevron-back-other-profile..svg";
import ThumbnailIcon from "../../../../assets/icons/collection-thumbnail.svg";
import LockIcon from "../../../../assets/icons/lock-icon-with-point.svg";
import ArrowLeftIcon from "../../../../assets/icons/new-arrow-left.svg";
import MediaPushMediaComponent from "../../../../components/AddMediaComponent/MediaPushMediaComponent";
import AuthButtonComponent from "../../../../components/AuthButtonCompnent";
import InputComponent from "../../../../components/InputComponent";
import MediaPushLimitReachedModalComponent from "../../../../components/MediaPushLimitReachedModalComponent";
import MessageTextAreaComponent from "../../../../components/MessageTextAreaComponent";
import PriceInputComponent from "../../../../components/PriceInputComponent";
import SpinnerComponent from "../../../../components/SpinnerComponent/SpinnerComponent";
import { getCollectionAmounts, getDiscountExpirePeriods, MAX_MEDIAS_TO_SEND, MAX_PRICE } from "../../../../helpers/constant";
import { ICollection, ISegmentations } from "../../../../helpers/types";
import { mediapushService } from "../../../../services";
import { collectionService } from "../../../../services/collection.service";
import { decryptService } from "../../../../services/decrypt.service";
import { socket } from "../../../../socket";
import { generateThumbnailURL, generateURL } from "../../../../utls/FunctionsUtil";
import { UploadedPart } from "../../CreatorCreateDecryptPage/CreatorCreateDecryptPage";
import "./index.less";

type MediaPushFiles = UploadFile & { isPrivate: boolean; isThumbnail: boolean };

type PrivateType = {
  showDialog: boolean;
  fileId: string;
  fileSrc: any;
  fileType: string;
  isThumbnail: boolean;
};

const CreatorCreateCollectionPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const shouldFetch = useRef(true);
  const collectionAmounts = getCollectionAmounts();
  const discountExpirePeriods = getDiscountExpirePeriods(t);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [collection, setCollection] = useState<Partial<ICollection>>({
    mediaPush: undefined,
  });
  const [title, setTitle] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [customPrice, setCustomPrice] = useState<number | undefined>(undefined);
  const [segmentations, setSegmentations] = useState<ISegmentations | null>(null);
  const [radioValue, setRadioValue] = useState<string | undefined>(undefined);
  const [promoCodeChecked, setPromoCodeChecked] = useState<boolean>(false);
  const [promoPercentage, setPromoPercentage] = useState<boolean>(false);
  const [promoCurrency, setPromoCurrency] = useState<boolean>(false);
  const [discountPercentage, setDiscountPercentage] = useState<string | undefined>(undefined);
  const [discountCurrency, setDiscountCurrency] = useState<string | undefined>(undefined);
  const [isMediaPushChecked, setIsMediaPushChecked] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<any>();
  const [selectedValues, setSelectedValues] = useState<string[]>(collection.mediaPush?.segmentation || []);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [componentKey, setComponentKey] = useState(0);
  const [privateFiles, setPrivateFiles] = useState<PrivateType[]>([]);
  const [files, setFiles] = useState<MediaPushFiles[]>([]);
  const [quickFiles, setQuickFiles] = useState<any[]>([]);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });

    const allFiles: any = sessionStorage.getItem("files");
    const allQuickFiles: any = sessionStorage.getItem("quickFiles");
    if (allFiles && allFiles !== null) {
      const parsedData = JSON.parse(allFiles);
      if (new Date().getTime() < parsedData?.expiry) {
        setFiles((prev: any) => [...prev, ...parsedData?.value]);
        sessionStorage.removeItem("files");
      }
    }
    if (allQuickFiles && allQuickFiles !== null) {
      const parsedData = JSON.parse(allQuickFiles);
      if (new Date().getTime() < parsedData?.expiry) {
        setQuickFiles((prev: any) => [...prev, ...parsedData?.value]);
        sessionStorage.removeItem("quickFiles");
      }
    }
  }, []);

  useEffect(() => {
    if (!collection.price || collection.price <= 0) {
      setPromoCodeChecked(false);
      delete collection.mediaPush?.discount;
      delete collection.mediaPush?.discountType;
      setPromoCurrency(false);
      setPromoPercentage(false);
      setDiscountCurrency(undefined);
      setDiscountPercentage(undefined);
    }
  }, [collection.price]);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress / 2);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  useEffect(() => {
    function onProgressUpdate(data: number) {
      setProgress((prev) => {
        const prog = prev + 1;
        if (prog <= 99) {
          return prog;
        }
        return prev;
      });
    }

    function onProcessEnd(data: any) {
      setIsLoading(false);
      message.success(t("collection-created-success"));
      navigate("/");
    }
    socket.on("file-updated", onProcessEnd);
    socket.on("progress-update", onProgressUpdate);
    return () => {
      socket.off("file-updated", onProcessEnd);
      socket.off("progress-update", onProgressUpdate);
    };
  }, []);

  const getSegmentations = () => {
    shouldFetch.current = false;
    mediapushService
      .getSegmentations()
      .then((data: ISegmentations) => setSegmentations(data))
      .catch((error) => {
        message.error(error || "Something went wrong.");
      });
  };

  const handleNavigate = useCallback(() => {
    switch (currentStep) {
      case 0:
        navigate("/creator/profile");
        break;
      case 1:
        setTitle("");
        setCurrentStep(0);
        break;
      default:
        break;
    }
  }, [currentStep, navigate]);

  const changedItem = (field: keyof ICollection, value: any) => {
    setCollection((prevCollection) => ({
      ...prevCollection,
      [field]: value,
    }));
  };

  const handleCustomPriceChange = (value: number | null) => {
    if (value !== null) {
      setCustomPrice(value);
      setRadioValue(undefined);
      changedItem("price", value);

      if (value > MAX_PRICE) {
        message.warning(t("maximum-price-alert"));
      }
    }
  };

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setRadioValue(value);
    setCustomPrice(parseInt(value));
    changedItem("price", parseInt(value));

    // maximum price alert 500€
    if (value > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
    }
  };

  const handleDiscountPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);

    if (!isNaN(value)) {
      if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }
    } else {
      value = 0;
    }

    setPromoPercentage(true);
    setPromoCurrency(false);
    setDiscountPercentage(value.toString());
    setDiscountCurrency(undefined);
    changedItem("mediaPush", {
      ...collection.mediaPush,
      discount: value,
      discountType: "PERCENTAGE",
    });
  };

  const handleDiscountCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPromoCurrency(true);
    setPromoPercentage(false);
    setDiscountCurrency(value);
    setDiscountPercentage(undefined);
    changedItem("mediaPush", {
      ...collection.mediaPush,
      discount: parseInt(value),
      discountType: "CURRENCY",
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    if (e.ctrlKey || e.metaKey || key === "Backspace" || key === "Tab" || key === "ArrowLeft" || key === "ArrowRight" || key === "Delete") {
      return;
    }

    if (key < "0" || key > "9") {
      e.preventDefault();
    }
  };

  const handleExpireTimeChange = (value: string, timeType: string) => {
    changedItem("mediaPush", {
      ...collection.mediaPush,
      expireTime: parseInt(value),
      expireTimeType: timeType,
    });
  };

  const handleCheckboxChange = (values: any) => {
    const zeroValues = values.filter((value: any) => {
      if (value === "subscribers" && segmentations?.subscribers === 0) return true;
      if (value === "followers" && segmentations?.followers === 0) return true;
      if (value === "oldFollowers" && segmentations?.oldFollowers === 0) return true;
      return false;
    });

    if (zeroValues.length > 0) {
      zeroValues
        .map((value: any) => {
          if (value === "subscribers") return message.warning(t("no-subscribers"));
          if (value === "followers") return message.warning(t("no-interested-audience"));
          if (value === "oldFollowers") return message.warning(t("no-former-subscribers"));
          return "";
        })
        .join(", ");
      return;
    }

    setSelectedValues(values);
    changedItem("mediaPush", {
      ...collection.mediaPush,
      segmentation: values,
    });
  };

  const handleRemoveFile = (fileId: string) => {
    setPrivateFiles((prev: any) => prev.filter((f: any) => f.fileId !== fileId));
    setFiles((prev: any) => prev.filter((f: any) => f.uid !== fileId));
    setComponentKey((prevKey) => prevKey + 1);
    removeFileFromSelected(fileId);
  };

  const removeFileFromSelected = (fileId: string) => {
    const params = new URLSearchParams(location.search);
    const selectedParam = params.get("selected");

    let selectedFiles: string[] = selectedParam ? JSON.parse(selectedParam) : [];

    selectedFiles = selectedFiles.filter((id) => id !== fileId);

    if (selectedFiles.length > 0) {
      params.set("selected", JSON.stringify(selectedFiles));
    } else {
      params.delete("selected");
    }

    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      { replace: true }
    );
  };

  const getThumbnailId = (): string => {
    let thumbnailId = "";
    let thumbFile = files?.find((file) => file.isThumbnail);
    let thumbQuickFile = quickFiles?.find((file) => file.isThumbnail);
    if (thumbFile) {
      thumbnailId = thumbFile.uid;
    }
    if (thumbQuickFile) {
      thumbnailId = thumbQuickFile.id;
    }
    return thumbnailId;
  };

  const handleFilesChange = async (fileList: UploadFile<[]>[]) => {
    const newQuickFiles: any[] = [];
    let thumbnailId = getThumbnailId();
    if (fileList.length > 0 && thumbnailId === "") {
      thumbnailId = fileList[fileList.length - 1].uid;
    }
    let isThumbnailFound = false;
    const newFileList: MediaPushFiles[] = fileList?.map((file: any) => {
      const isThumbnail = file.uid === thumbnailId;
      if (isThumbnail) {
        isThumbnailFound = true;
      }
      const fileIndex = files.findIndex((currentFile) => {
        return currentFile.uid === file.uid;
      });
      if (file.imgType !== undefined && file.imgType === "quick-file") {
        newQuickFiles.push({
          id: file.uid,
          fileId: file.uid,
          isPrivate: fileIndex !== -1 ? files[fileIndex].isPrivate : true,
          isThumbnail,
        });
      }
      return {
        ...file,
        isPrivate: fileIndex !== -1 ? files[fileIndex].isPrivate : true,
        isThumbnail,
      };
    });

    const filesToSetPrivacy = differenceBy(fileList, files, "uid").filter((file) => file.status === "done");
    if (filesToSetPrivacy.length > 0) {
      const filesTSP: PrivateType[] = await Promise.all(
        filesToSetPrivacy.map(async (file: any) => {
          let src = file.url as string;
          if (file.imgType !== "quick-file") {
            if (file.type?.includes("video")) {
              src = await generateThumbnailURL(file.originFileObj as RcFile);
            } else {
              src = await generateURL(file.originFileObj as RcFile);
            }
          } else {
            src = file.thumbUrl!;
          }
          return {
            showDialog: true,
            fileId: file.uid,
            fileSrc: src,
            fileType: file.type!,
            isThumbnail: file.uid === thumbnailId,
          };
        })
      );
      setPrivateFiles((prev: PrivateType[]) =>
        uniqBy([...prev, ...filesTSP], "fileId").sort((a, b) => Number(b.isThumbnail) - Number(a.isThumbnail))
      );
    }

    if (!isThumbnailFound) {
      if (newFileList.length > 0) {
        newFileList[newFileList.length - 1] = {
          ...newFileList[newFileList.length - 1],
          isThumbnail: true,
        };
      } else if (newQuickFiles.length > 0) {
        newQuickFiles[newQuickFiles.length - 1] = {
          ...newQuickFiles[newQuickFiles.length - 1],
          isThumbnail: true,
        };
      }
    }

    setFiles(newFileList);
    setQuickFiles(newQuickFiles);
  };

  const processQuickFiles = (array: any[]): any[] => {
    let uniqueItems: any[] = [];
    array.forEach((item) => {
      if (typeof item === "object" && item.id) {
        const id: string = item.id;
        const isAlready = uniqueItems.find((items: any) => items.id === id);
        if (!isAlready) {
          uniqueItems.push(item);
        }
      } else {
        const isAlready = uniqueItems.find((items: any) => items === item);
        if (!isAlready) {
          uniqueItems.push(item);
        }
      }
    });
    return uniqueItems;
  };

  const onNextClick = (privateFile: PrivateType) => {
    setPrivateFiles((prev: PrivateType[]) => {
      const result = prev.filter((file) => file.fileId !== privateFile.fileId);
      if (result.length === 0) {
        let thumbnailId = getThumbnailId();
        if (thumbnailId === "") {
          if (files.length > 0) {
            setFiles((prevList) => {
              if (prevList.length === 0) return prevList;
              const newList = [...prevList];
              newList[newList.length - 1] = {
                ...newList[newList.length - 1],
                isThumbnail: true,
              };
              return newList;
            });
          } else if (quickFiles.length > 0) {
            setQuickFiles((prevList) => {
              if (prevList.length === 0) return prevList;
              const newList = [...prevList];
              newList[newList.length - 1] = {
                ...newList[newList.length - 1],
                isThumbnail: true,
              };
              return newList;
            });
          }
        }
      }
      return result;
    });
  };

  const handleLockSwitchChange = async (value: boolean, privateFile: PrivateType) => {
    const newQuickFileList: MediaPushFiles[] = quickFiles?.map((file: any) => {
      if (file.id === privateFile.fileId) {
        return {
          ...file,
          isPrivate: value,
        };
      }
      return file;
    });
    const newFileList: MediaPushFiles[] = files?.map((file) => {
      if (file.uid === privateFile.fileId) {
        return {
          ...file,
          isPrivate: value,
        };
      }
      return file;
    });
    setFiles(newFileList);
    setQuickFiles(newQuickFileList);
  };

  const handleThumbSwitchChange = async (value: boolean, privateFile: PrivateType) => {
    const newQuickFileList: MediaPushFiles[] = quickFiles?.map((file: any) => {
      if (file.id === privateFile.fileId) {
        return {
          ...file,
          isThumbnail: value,
        };
      }
      if (value) {
        return {
          ...file,
          isThumbnail: false,
        };
      }
      return file;
    });
    const newFileList: MediaPushFiles[] = files?.map((file) => {
      if (file.uid === privateFile.fileId) {
        return {
          ...file,
          isThumbnail: value,
        };
      }
      if (value) {
        return {
          ...file,
          isThumbnail: false,
        };
      }
      return file;
    });
    setFiles(newFileList);
    setQuickFiles(newQuickFileList);
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }, 100);
  };

  const handleCreateCollection = async () => {
    if (isMediaPushChecked) {
      if (Number(segmentations?.subscribers) === 0 && Number(segmentations?.oldFollowers) === 0 && Number(segmentations?.followers) === 0) {
        message.error(t("message-no-target-audience"));
        return;
      }

      if (collection.mediaPush?.segmentation?.includes("subscribers") && Number(segmentations?.subscribers) === 0) {
        message.warning(t("message-no-subscribers"));
        return;
      } else if (collection.mediaPush?.segmentation?.includes("followers") && Number(segmentations?.followers) === 0) {
        message.warning(t("message-no-interested-audience"));
        return;
      } else if (collection.mediaPush?.segmentation?.includes("oldFollowers") && Number(segmentations?.oldFollowers) === 0) {
        message.warning(t("message-no-former-subscribers"));
        return;
      }
    }

    if (files.length === 0 && quickFiles.length === 0) {
      message.warning(t("files-required"));
      return;
    }

    type FilePrivacy = {
      fileId: string;
      isPrivate: boolean;
      isThumbnail: boolean;
    };

    setIsLoading(true);
    let collectionToCreate: ICollection = {
      ...collection,
      title,
      isMediaPush: isMediaPushChecked,
    };

    const uniqueQuickFiles = processQuickFiles(quickFiles);
    let formData = new FormData();
    const filesPrivacy: FilePrivacy[] = [];
    const videoFileToChunk: any[] = [];

    files?.forEach((file: any) => {
      const isObject = isPlainObject(file);
      if (isObject && file?.imgType !== "quick-file") {
        if (file?.originFileObj?.type?.includes("image/")) {
          formData.append(`files`, file.originFileObj as any);
          filesPrivacy.push({
            fileId: file.uid,
            isPrivate: file.isPrivate,
            isThumbnail: file.isThumbnail,
          });
        } else {
          videoFileToChunk.push(file);
        }
      }
    });

    for (const [key, value] of Object.entries(collectionToCreate)) {
      formData.set(key, value);
    }
    formData.set("fileIds", JSON.stringify(filesPrivacy));
    formData.set("quickFiles", JSON.stringify(uniqueQuickFiles));

    if (isMediaPushChecked && collection.mediaPush && collection.mediaPush.segmentation !== undefined) {
      formData.set("mediaPush", JSON.stringify(collection.mediaPush));
    }

    if (videoFileToChunk.length > 0) {
      const videoFiles: any[] = [];
      const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
      setTotalSize(totalSizes);
      let totalUploadedBytes = { value: 0 };

      for (let i = 0; i < videoFileToChunk.length; i++) {
        const file = videoFileToChunk[i];
        const { uploadId, filename } = await decryptService.initializeMultiPartUpload({
          filename: file.name,
          contentType: file.type,
        });

        const chunkSize = 5 * 1024 * 1024; // 5MB chunks
        const chunks = Math.ceil(file.size / chunkSize);

        // Helper function to upload a batch of chunks
        const uploadChunkBatch = async (startIndex: number, batchSize: number) => {
          const uploadPromises: Promise<UploadedPart>[] = [];

          for (let i = startIndex; i < Math.min(startIndex + batchSize, chunks); i++) {
            const start = i * chunkSize;
            const end = Math.min(file.size, start + chunkSize);
            const chunk = file.originFileObj.slice(start, end);
            uploadPromises.push(uploadChunk(chunk, i + 1, uploadId, filename, totalUploadedBytes));
          }

          // Wait for the current batch to finish uploading
          return await Promise.all(uploadPromises);
        };

        let allUploadedParts: UploadedPart[] = [];

        // Upload in batches of 6 chunks
        const batchSize = 6;
        for (let i = 0; i < chunks; i += batchSize) {
          const uploadedParts = await uploadChunkBatch(i, batchSize);
          allUploadedParts = [...allUploadedParts, ...uploadedParts];
        }

        // Finalize multipart upload after all chunks are uploaded
        const result = await decryptService.finalizeMultipartUpload(uploadId, allUploadedParts, filename);

        videoFiles.push({
          Location: result.Location,
          isThumbnail: file.isThumbnail,
          isPrivate: file.isPrivate,
          Key: result.Key,
          ETag: result.ETag,
          Bucket: result.Bucket,
          filename: file.name,
          size: file.size,
        });
      }

      formData.append("videoFiles", JSON.stringify(videoFiles));
    }

    await collectionService.createCollection(formData, setProgress).then((resp: any) => {
      if (resp.message) {
        if (resp.message === "send-push-media-followers-oldFollowers-once-a-week") {
          setOpenModal(true);
        } else {
          message.error(t(resp.message));
        }
      } else {
        // TODO: fix navigation after success
        navigate("/");
      }
      setIsLoading(false);
    });
  };

  const uploadChunk = async (
    chunk: Blob,
    partNumber: number,
    uploadId: string,
    filename: string,
    totalUploadedBytes: { value: number }
  ): Promise<UploadedPart> => {
    const formData = new FormData();
    formData.append("chunk", chunk);
    formData.append("partNumber", partNumber.toString());
    formData.append("uploadId", uploadId);
    formData.append("filename", filename);
    const data = await decryptService.uploadMultiChunk(formData, setLoadedData).then((response) => {
      //totalUploadedBytes.value += chunk.size;
      //setLoadedData(totalUploadedBytes.value);
      return response;
    });
    return { ETag: data, PartNumber: partNumber };
  };

  function isPlainObject(value: any) {
    if (value === null || typeof value !== "object") {
      return false;
    }
    return Object.getPrototypeOf(value) === Object.prototype;
  }

  const isButtonDisabled = () => {
    const hasDescription = !!collection.description;
    const hasPrice = !!collection.price;
    const hasFiles = files.length > 0;
    const isSegmentationValid =
      collection.mediaPush?.segmentation?.includes("subscribers") ||
      collection.mediaPush?.segmentation?.includes("followers") ||
      collection.mediaPush?.segmentation?.includes("oldFollowers");

    if (!isMediaPushChecked) {
      return !(hasDescription && hasPrice && hasFiles);
    } else {
      if (!promoCodeChecked) {
        return !(hasDescription && hasPrice && hasFiles && isSegmentationValid);
      } else {
        return !(hasDescription && hasPrice && hasFiles && collection.mediaPush?.discount && collection.mediaPush?.expireTime !== undefined);
      }
    }
  };

  return (
    <Spin
      spinning={isLoading}
      style={{ maxHeight: "100%" }}
      indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
    >
      <Layout className="gradient-background">
        <div style={{ display: privateFiles.length > 0 ? "none" : "" }}>
          <Layout.Content className="content creator-collection-page-wrapper background-transparent">
            <Row className="relative justify-content-center header-title-container-60">
              <img className="header-arrow-left-media" style={{ top: 10, left: 10 }} src={ArrowLeftIcon} alt="arrow left" onClick={handleNavigate} />
              <Typography.Text className="header-title-black-30">{t("collection")}</Typography.Text>
            </Row>
            {currentStep === 0 && (
              <div className="collection-title-container">
                <Row className="collection-title-row">
                  <Typography.Text className="font-18-bold text-white-color">{t("give-your-collection-a-name")}</Typography.Text>
                </Row>
                <Input
                  className="titleInput"
                  bordered={false}
                  size="large"
                  value={title}
                  onChange={(e) => {
                    if (e.target.value.length <= 40) {
                      setTitle(e.target.value);
                    }
                  }}
                  placeholder={t("title")!}
                  autoFocus={false}
                />
                <div className={"mt-10 divider-input collection-title-row"} />
                <Row className="w-full justify-content-center">
                  <Button
                    className="authBtn mt-40"
                    style={{ width: "50%", fontSize: 20, height: "60px" }}
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                    disabled={!title}
                  >
                    {t("create")}
                  </Button>
                </Row>
              </div>
            )}

            {currentStep === 1 && (
              <div ref={scrollRef} className="collection-data-wrapper">
                <Row>
                  <Row className="w-full justify-content-center mt-20 mb-20">
                    <Typography.Text className="font-13-regular text-grey-color mb-3">{t("send-file-description")}</Typography.Text>
                  </Row>
                  <MediaPushMediaComponent
                    key={componentKey}
                    maxCount={MAX_MEDIAS_TO_SEND}
                    onFilesChange={handleFilesChange}
                    defaultList={files}
                    allFiles={files}
                    quickFiles={quickFiles}
                    fromCollection={true}
                  />
                </Row>
                <Row className="mt-20">
                  <MessageTextAreaComponent
                    title={t("description")!}
                    value={collection?.description || ""}
                    handleChange={(value) => {
                      changedItem("description", value.target.value);
                    }}
                  />
                </Row>
                <Row>
                  <Space className="w-full gap-10" direction="vertical">
                    <PriceInputComponent customPrice={customPrice} handleCustomPriceChange={handleCustomPriceChange} />
                    <Radio.Group className="w-full mt-10" value={radioValue} onChange={handleRadioChange}>
                      <Row gutter={10}>
                        {collectionAmounts.map((amount, index) => {
                          return (
                            <Col className="mt-3" key={index} span={6}>
                              <Radio.Button className="mediaAmountRadioButton mediaPush" value={amount.value}>
                                {amount.label}
                              </Radio.Button>
                            </Col>
                          );
                        })}
                      </Row>
                    </Radio.Group>
                  </Space>
                </Row>
                <Row className="justify-content-between mt-20" align="middle">
                  <Col style={{ flex: 1 }}>
                    <Space direction="vertical">
                      <Typography.Text className="font-22-bold text-white-color">{t("make-it-push-media")}</Typography.Text>
                      <Typography.Text className="font-12-regular text-grey-color">{t("make-it-push-media-desc")}</Typography.Text>
                    </Space>
                  </Col>
                  <Col>
                    <Switch
                      className="switch mediaPushSwitch"
                      defaultChecked={isMediaPushChecked}
                      checked={isMediaPushChecked}
                      onChange={(checked) => {
                        setIsMediaPushChecked(checked);
                        changedItem("isMediaPush", checked);
                        if (shouldFetch.current) {
                          getSegmentations();
                        }
                        if (checked) {
                          scrollToBottom();
                        } else if (!checked) {
                          changedItem("mediaPush", undefined);
                        }
                      }}
                    />
                  </Col>
                </Row>

                {isMediaPushChecked && (
                  <div className="mt-40">
                    <Row>
                      <Row className="w-full">
                        <Typography.Text className="font-22-bold text-white-color">{t("select-your-segments")}</Typography.Text>
                      </Row>
                      <Checkbox.Group className="w-full mt-10" onChange={handleCheckboxChange} value={selectedValues}>
                        <Col className="w-full">
                          <Row className="mt-7">
                            <Checkbox value={"subscribers"} className="segmentationCollectionCheckBox" id="subscribersCheckBox" />
                            <label htmlFor="subscribersCheckBox">
                              <Row className="w-full justify-content-between items-center">
                                <span
                                  className={
                                    collection.mediaPush?.segmentation?.includes("subscribers")
                                      ? "text-black-color font-14-bold"
                                      : "text-white-color font-14-bold"
                                  }
                                >
                                  {t("subscribers")}
                                </span>
                                <Tag className="segmentationTag">{segmentations?.subscribers}</Tag>
                              </Row>
                            </label>
                          </Row>
                          <Row className="mt-7">
                            <Checkbox value={"followers"} className="segmentationCollectionCheckBox" id="followersCheckBox" />
                            <label htmlFor="followersCheckBox">
                              <Row className="w-full justify-content-between items-center">
                                <span
                                  className={
                                    !collection.mediaPush?.segmentation?.includes("followers")
                                      ? "text-white-color font-14-bold"
                                      : "text-black-color font-14-bold"
                                  }
                                >
                                  {t("interested")}
                                </span>
                                <Tag className="segmentationTag">{segmentations?.followers}</Tag>
                              </Row>
                            </label>
                          </Row>
                          <Row className="mt-7">
                            <Checkbox value={"oldFollowers"} className="segmentationCollectionCheckBox " id="oldSubscribersCheckBox" />
                            <label htmlFor="oldSubscribersCheckBox">
                              <Row className="w-full justify-content-between items-center">
                                <span
                                  className={
                                    !collection.mediaPush?.segmentation?.includes("oldFollowers")
                                      ? "text-white-color font-14-bold"
                                      : "text-black-color font-14-bold"
                                  }
                                >
                                  {t("former-subscriber")}
                                </span>
                                <Tag className="segmentationTag">{segmentations?.oldFollowers}</Tag>
                              </Row>
                            </label>
                          </Row>
                        </Col>
                      </Checkbox.Group>
                    </Row>

                    <Row className="justify-content-between mt-20" align="middle">
                      <Typography.Text className="font-18-bold text-white-color">{t("add-a-promotion")}</Typography.Text>
                      <Switch
                        disabled={collection?.price === undefined || collection?.price <= 0 || Number.isNaN(collection.price)}
                        className="switch mediaPushSwitch"
                        defaultChecked={promoCodeChecked}
                        checked={promoCodeChecked}
                        onChange={(checked) => {
                          setPromoCodeChecked(checked);
                          const updatedMediaPush = { ...collection.mediaPush, promo: checked };

                          if (!checked) {
                            delete updatedMediaPush.discount;
                            delete updatedMediaPush.discountType;
                            delete updatedMediaPush.expireTime;
                            delete updatedMediaPush.expireTimeType;
                            setPromoCurrency(false);
                            setPromoPercentage(false);
                            setDiscountCurrency(undefined);
                            setDiscountPercentage(undefined);
                            setSelectedTime(undefined);
                          }

                          changedItem("mediaPush", updatedMediaPush);

                          if (checked) {
                            setTimeout(scrollToBottom, 100);
                          }
                        }}
                      />
                    </Row>

                    {promoCodeChecked && (
                      <div className="mt-20">
                        <Row className="justify-content-between">
                          <Space className="w-full gap-10" direction="vertical">
                            <Typography.Text className="font-30-bold text-white-color">{t("discount")}</Typography.Text>
                            <Row>
                              <Col span={8}>
                                <InputComponent
                                  className={`${promoCurrency ? "mediaPushMediumGreenInput" : "mediaPushDarkInput"}`}
                                  suffix="€"
                                  onChange={handleDiscountCurrencyChange}
                                  onKeyDown={handleKeyDown}
                                  value={discountCurrency}
                                  inputMode="numeric"
                                />
                              </Col>
                              <Col className="d-flex items-center justify-content-center" span={8}>
                                <Typography.Text className="font-13-bold text-grey-color">{t("auth-signUp-divider")}</Typography.Text>
                              </Col>
                              <Col span={8}>
                                <InputComponent
                                  className={`${promoPercentage ? "mediaPushMediumGreenInput" : "mediaPushDarkInput"}`}
                                  suffix="%"
                                  onChange={handleDiscountPercentageChange}
                                  onKeyDown={handleKeyDown}
                                  value={discountPercentage}
                                  inputMode="numeric"
                                />
                              </Col>
                            </Row>
                          </Space>
                        </Row>

                        <Row className="justify-content-between mt-16">
                          <Space className="w-full gap-10" direction="vertical">
                            <Typography.Text className="font-30-bold text-white-color">{t("duration-of-validity")}</Typography.Text>
                            <Radio.Group
                              className="w-full"
                              value={discountExpirePeriods[selectedTime]?.value}
                              onChange={(e) => {
                                handleExpireTimeChange(e.target.value.value, e.target.value.timeType);
                              }}
                            >
                              <Row gutter={15}>
                                {discountExpirePeriods.map((period, index) => {
                                  return (
                                    <Col className="mt-3" key={index} span={8}>
                                      <Radio.Button
                                        className="mediaPushAmountPromoButton"
                                        value={period.value}
                                        onClick={() => {
                                          setSelectedTime(index);
                                        }}
                                      >
                                        {period.label}
                                      </Radio.Button>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Radio.Group>
                          </Space>
                        </Row>
                      </div>
                    )}
                  </div>
                )}

                <Row className="collection-btn-sticky-row mt-30">
                  <AuthButtonComponent label={t("validate-the-collection")} onClick={handleCreateCollection} disabled={isButtonDisabled()} />
                </Row>
              </div>
            )}
            <MediaPushLimitReachedModalComponent isOpen={openModal} onClose={() => setOpenModal(false)} />
          </Layout.Content>
        </div>
        {privateFiles.length > 0 &&
          privateFiles.map((privateFile) => (
            <Layout.Content key={privateFile.fileId} className="content creator-add-file-wrapper gradient-background">
              <Row className="relative justify-content-center header-title-container-60">
                <img
                  className="header-arrow-left-media"
                  style={{ top: 5, left: 10, width: 10 }}
                  src={ChevronBackIcon}
                  alt="arrow left"
                  onClick={() => handleRemoveFile(privateFile.fileId)}
                />
                <Typography.Text className="font-20-bold text-white-color">{t("add-media-collection")}</Typography.Text>
              </Row>
              <Row className="w-full justify-content-center">
                <img className="img-collection-design" src={privateFile.fileSrc} alt="Loly" />
              </Row>
              <div className="add-file-switch-blur-container ">
                <Row className="w-full justify-content-between">
                  <div className="icon-title-blur-container">
                    <img className="lock-icon" src={LockIcon} alt="lock" />
                    <Typography.Text className="blur-title">{t("want-blur-media")}</Typography.Text>
                  </div>
                  <Switch className="collection-file-switch" defaultChecked={true} onChange={(value) => handleLockSwitchChange(value, privateFile)} />
                </Row>
                <Row className="divider-collection"></Row>
                <Row className="w-full justify-content-between mt-20">
                  <div className="icon-title-thumb-container">
                    <img className="thumb-icon" src={ThumbnailIcon} alt="lock" />
                    <div className="title-subtitle-row">
                      <Typography.Text className="thumbnail-title">{t("miniature")}</Typography.Text>
                      <Typography.Text className="thumbnail-subtitle">{t("thumbnail-collection-desc")}</Typography.Text>
                    </div>
                  </div>
                  <Switch
                    className="collection-file-switch"
                    defaultChecked={privateFile.isThumbnail}
                    onChange={(value) => handleThumbSwitchChange(value, privateFile)}
                  />
                </Row>
                <Row className="divider-collection"></Row>

                <Row className="w-full justify-content-center mt-30">
                  <AuthButtonComponent height="50px" fontSize="18px" borderRadius="20px" label={t("next")} onClick={() => onNextClick(privateFile)} />
                </Row>
              </div>
            </Layout.Content>
          ))}
      </Layout>
    </Spin>
  );
};

export default CreatorCreateCollectionPage;
