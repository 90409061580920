import { Typography } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDirectLinks } from "../../redux/CreatorSuggestion/CreatorSuggestion";
import { AppDispatch, StoreState } from "../../redux/store";
import "./index.less";

const DirectLinksComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const directLinks = useSelector((state: StoreState) => state.suggestion.directLinks);

  useEffect(() => {
    if (!directLinks.length) {
      dispatch(getDirectLinks());
    }
  }, [dispatch, directLinks.length]);

  const handleClick = (link: string) => {
    window.location.href = link;
  };

  return (
    <div className="direct-links-container">
      {directLinks.map((directLink, index) => (
        <div key={index} className="direct-link-item cursor-pointer" onClick={() => handleClick(directLink.link)}>
          <Typography.Text className="direct-link-text">{directLink.name}</Typography.Text>
        </div>
      ))}
    </div>
  );
};

export default DirectLinksComponent;
