import { t } from "i18next";
import axios from "../helpers/axios";
import { IBillingAddress, IHelpContactRequest, IMonetization, IRevenue, ISponsorship, IUser, PageableData } from "../helpers/types";
import { authService } from "./auth.service";

class UserService {
  public getMyProfile = (token?: string) => {
    const url = `/users/me`;
    return new Promise<IUser>((resolve, reject) => {
      const config = token
        ? {
            headers: {
              Authorization: token,
            },
          }
        : {};
      axios
        .get(url, config)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong.");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getProfile = (id: string) => {
    const url = `/users/${id}/view`;
    return new Promise<IUser>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else if (response?.data?.message) reject(response.data.message);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getPublicProfile = (username: string) => {
    const url = `/users/public/${username}`;
    return new Promise<IUser>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else if (response?.data?.message) reject(response.data.message);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public updateMyProfile = (data: IUser) => {
    const url = `/users/me`;
    return new Promise<IUser>((resolve, reject) => {
      axios
        .put(url, data)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject(response.data.message);
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public changePassword = (currentPassword: string, newPassword: string, confirmPassword: string) => {
    const url = `/users/me/change-password`;
    return new Promise<void>((resolve, reject) => {
      axios
        .put(url, { currentPassword, newPassword, confirmPassword })
        .then((response) => {
          if (response?.data) resolve(response?.data);
          else if (response?.data?.message) resolve(response?.data?.message);
          else reject("Something went wrong.");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public updateCoverImage = (formData: FormData) => {
    const url = `/users/me/cover`;
    return new Promise<void>((resolve, reject) => {
      axios
        .put(url, formData)
        .then((response) => {
          if (response?.data?.data?.success && response?.data?.data?.url) resolve();
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public updateAvatarImage = (formData: FormData) => {
    const url = `/users/me/avatar`;
    return new Promise<void>((resolve, reject) => {
      axios
        .put(url, formData)
        .then((response) => {
          if (response?.data?.data?.success && response?.data?.data?.url) resolve();
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public updateCreatorContentTypes(userId: string, contentTypes: string[]) {
    const url = `/auth/register/creator/${userId}/content-types`;
    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, { contentTypes })
        .then((response) => {
          if (response?.data?.data) resolve();
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  }

  public updateCreatorMonetization(userId: string, monetization: IMonetization) {
    const url = `/auth/register/creator/${userId}/monetization`;
    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, monetization)
        .then((response) => {
          if (response?.data?.data) resolve();
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  updateBillingAddress(userId: string, billingAddress: IBillingAddress) {
    const url = `/auth/register/creator/${userId}/billingAddress`;
    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, billingAddress)
        .then((response) => {
          if (response?.data?.data) resolve();
          else reject(t("something-want-wrong"));
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject(t("something-want-wrong"));
        });
    });
  }

  public uploadIdFiles = (userId: string, formData: FormData) => {
    const url = `/auth/register/creator/${userId}/id-files`;
    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, formData)
        .then((response) => {
          if (response?.data?.data) resolve();
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public search = (param: any) => {
    const url = `/users/search/creators?q=${param.q ? param.q : ""}&limit=${param.limit ? param.limit : ""}&offset=${
      param.offset ? param.offset : ""
    }&sortBy=${param.sortBy ? param.sortBy : ""}&sort=${param.sort ? param.sort : ""}&category=${param.category ? param.category : ""}&getSelf=${
      param.getSelf ? param.getSelf : "true"
    }`;
    return new Promise<PageableData<IUser>>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else reject("Something went wrong.");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getCreatorMonetizations = (creatorId: any) => {
    const url = `/users/creator/${creatorId}/monetizations`;
    return new Promise<IMonetization>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public creatorSponsorship = () => {
    const url = `/earning/creator/sponsorship`;
    return new Promise<ISponsorship>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public isVerified = (id: string) => {
    const url = `/users/${id}/isVerified`;
    return new Promise<boolean>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.status === 0) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public Revenue = (graphData: string) => {
    const url = `/earning/creator/stats?type=${graphData}`;
    return new Promise<IRevenue>((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public updateMonetizations = (monetizations: IMonetization) => {
    const url = `/users/creator/monetizations`;
    return new Promise<IMonetization>((resolve, reject) => {
      axios
        .put(url, monetizations)
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getCreatorAllBank = () => {
    const url = `/users/creator/all-bank`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public updatePayoutFrequency = (payload: any) => {
    const url = `/users/creator/paymentfrequency`;
    return new Promise<any>((resolve, reject) => {
      axios
        .put(url, payload)
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public setDefaultBankAccount = (id: string) => {
    const url = `/users/creator/setDefaultBankAccount/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public createBankingSetting = (id: string, payload: any) => {
    const url = `/users/creator/${id}/banking-settings`;
    return new Promise<any>((resolve, reject) => {
      axios
        .post(url, payload)
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public deleteBankAccount = (id: string) => {
    const url = `/users/creator/bankAccount/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(url, {
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getRecentSearch = () => {
    const url = `/users/search/searchHistory/:seeAll`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public addRecentSeacrchUser = (id: String) => {
    const url = `/users/search/searchHistory/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .put(url, {
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data) resolve(response.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public deleteRecetSearchUser = (id: String) => {
    const url = `/users/search/searchHistory/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(url, {
          headers: {
            Authorization: authService.getToken(),
          },
        })
        .then((response) => {
          if (response?.data.creators) resolve(response.data.creators);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public updateDateOfBirth = (
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    username: string,
    date: any,
    isFan: boolean,
    phone: string,
    phoneCode: string,
    gender: string,
    name?: string
  ) => {
    const url = `/users/update/dob/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .patch(
          url,
          {
            email,
            name,
            firstName,
            lastName,
            username,
            dateOfBirth: date,
            isFan,
            phone,
            phoneCode,
            gender,
          },
          {
            headers: {
              Authorization: authService.getToken(),
            },
          }
        )
        .then((response) => {
          if (response?.data?.data) resolve(response?.data?.data);
          else reject(response?.data?.message);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getVatCountries = () => {
    const url = `/countries/vatCountry`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getUserIdByUsername = (username: string) => {
    const url = `/users/getId/${username}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else if (response?.data?.message) reject(response.data.message);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public helpContactSupport = (helpContactRequest: IHelpContactRequest) => {
    const url = `/support/create`;
    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, helpContactRequest)
        .then((response) => {
          if (response?.data) resolve(response?.data);
          else if (response?.data?.message) resolve(response?.data?.message);
          else reject("Something went wrong.");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public updatePreferredLanguage = (preferredLang: string) => {
    const url = `/users/update/preferredLang`;
    return new Promise<any>((resolve, reject) => {
      axios
        .patch(url, {
          preferredLang,
        })
        .then((response) => {
          if (response?.data?.data?.preferredLang) resolve(response?.data?.data);
          else reject(response?.data?.message);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getFollowedCreator = () => {
    const url = `/reactions/creators/followed`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data !== undefined) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public addFanPhoneNumber = (phone: string, phoneCode: string, countryCode: string, acceptsPromotionsBySms: boolean) => {
    const url = `/users/fan/phone/number`;
    return new Promise<any>((resolve, reject) => {
      axios
        .put(url, {
          phone,
          phoneCode,
          countryCode,
          acceptsPromotionsBySms,
        })
        .then((response) => {
          if (response?.data?.data.message) resolve(response.data.data);
          else reject(response?.data?.message || "Une erreur s'est produite");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public updatePhoneModalShown = (phoneModalShown: boolean) => {
    const url = `/users/update/phone/modal/shown`;
    return new Promise<any>((resolve, reject) => {
      axios
        .put(url, {
          phoneModalShown,
        })
        .then((response) => {
          if (response?.data?.data.message) resolve(response.data.data);
          else reject(response?.data?.message || "Une erreur s'est produite");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getPhoneModalShown = () => {
    const url = `/users/phone/modal/shown`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data !== undefined) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };
}

export const userService = new UserService();
