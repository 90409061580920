import React from "react";
import { Col, Row, Space, Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import AuthButtonComponent from "../../AuthButtonCompnent";
import "../index.less";

type SignUpCreatorStepFourProps = {
  accountType: string;
  setAccountType: (type: string) => void;
  selectAccountType: () => void;
};

const SignUpCreatorStepFourComponent: React.FC<SignUpCreatorStepFourProps> = ({ accountType, setAccountType, selectAccountType }) => {
  const { t } = useTranslation();

  return (
    <Col className="padding-container">
      <Row className="justify-content-center mt-20">
        <Typography.Text className="authHeader authStatusHeader">{t("your-status")}</Typography.Text>
      </Row>
      <Row className="justify-content-center mt-7">
        <Typography.Text className="authSelectTypeDescription">{t("professional-company-number")}</Typography.Text>
      </Row>
      <Space className="w-full mt-100 gap-20" direction="vertical">
        <Button className={`btn-status ${accountType === "private" ? "active" : ""}`} onClick={() => setAccountType("private")}>
          {t("private-individual")}
        </Button>
        <Button className={`btn-status mt-10 ${accountType === "business" ? "active" : ""}`} onClick={() => setAccountType("business")}>
          {t("business-number")}
        </Button>
      </Space>
      <Row className="mt-130">
        <AuthButtonComponent label={t("continue-label")} onClick={selectAccountType} disabled={accountType === ""} />
      </Row>
    </Col>
  );
};

export default SignUpCreatorStepFourComponent;
