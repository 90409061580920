import React, { useEffect, useRef } from "react";
import { Col, Row, Space, Typography, Checkbox, InputRef } from "antd";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../helpers/types";
import AuthInputComponent from "../../AuthInputComponent";
import DatePickerComponent from "../../DatePickerComponent";
import PhoneInputComponent from "../../PhoneInputCompnent";
import AuthGenderInputComponent from "../../AuthGenderInputComponent";
import AuthButtonComponent from "../../AuthButtonCompnent";
import "../index.less";

type SignUpCreatorStepGoogleProps = {
  user: IUser | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setUser: (user: any) => void;
  acceptAuthTerms: boolean;
  setAcceptAuthTerms: (accept: boolean) => void;
  updateDateOfBirth: () => void;
};

const SignUpCreatorStepGoogleComponent: React.FC<SignUpCreatorStepGoogleProps> = ({
  user,
  handleChange,
  setUser,
  acceptAuthTerms,
  setAcceptAuthTerms,
  updateDateOfBirth,
}) => {
  const { t } = useTranslation();
  const usernameInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (usernameInputRef.current) {
      usernameInputRef.current.focus();
    }
  }, []);

  return (
    <Col className="pl-28 pr-28">
      <Row className="justify-content-center mt-40">
        <Typography.Text className="authHeader authSelectTypeHeader">{`${t("details")}`}</Typography.Text>
      </Row>
      <Row className="mb-10 mt-20">
        <Space className="w-full" direction="vertical">
          <Row className="mt-7">
            <AuthInputComponent
              label={t("last-name-label")}
              placeholder={t("last-name-label")!}
              type="text"
              name="name"
              value={user?.name || ""}
              handleChange={handleChange}
              infoText={t("displayed-on-your-profile")!}
            />
          </Row>
          <Row>
            <AuthInputComponent
              label={t("user-name-label")}
              placeholder={t("user-name-label")!}
              type="text"
              name="username"
              value={user?.username?.toLocaleLowerCase() || ""}
              handleChange={handleChange}
              ref={usernameInputRef}
              infoText={t("displayed-on-your-profile")!}
            />
          </Row>
          <Row gutter={7}>
            <Col span={12}>
              <AuthInputComponent
                label={t("first-name-label")}
                placeholder={t("first-name-label")!}
                type="text"
                name="firstName"
                value={user?.firstName || ""}
                handleChange={handleChange}
              />
            </Col>
            <Col span={12}>
              <AuthInputComponent
                label={t("last-name-label")}
                placeholder={t("last-name-label")!}
                type="text"
                name="lastName"
                value={user?.lastName || ""}
                handleChange={handleChange}
              />
            </Col>
          </Row>
          <Row>
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="authInputLabel">{t("date-of-birth-label")}</Typography.Text>
              <DatePickerComponent
                className="darkDatePickerSignup"
                date={user?.dateOfBirth}
                onChange={(value) => setUser({ ...user, dateOfBirth: value })}
              />
            </Space>
          </Row>
          <Row>
            <AuthInputComponent
              label={t("email-label")}
              placeholder={t("email-label")!}
              type="email"
              name="email"
              value={user?.email || ""}
              disabled
            />
          </Row>
          <Row>
            <Space className="w-full gap-6" direction="vertical">
              <Typography.Text className="authInputLabel">{t("phone-number-label")}</Typography.Text>
              <PhoneInputComponent
                className="authDark"
                countryCode={user?.countryCode}
                phoneCode={user?.phoneCode}
                phone={user?.phone}
                onChangePhone={(e) => setUser({ ...user, phone: e.target.value })}
                onChangePhoneCode={(phoneCode, countryCode) => {
                  setUser({ ...user, phoneCode, countryCode });
                }}
              />
            </Space>
          </Row>
          <Row>
            <AuthGenderInputComponent name="gender" value={user?.gender} handleChange={handleChange} />
          </Row>
          <Row className="mt-11">
            <Checkbox className="authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
              <span>{t("auth-terms-1")}</span>
              <span className="text-color-green">{t("auth-terms-2")}</span>
              <span>{t("auth-terms-3")}</span>
              <a href="https://loly.app/conditions-generales-de-vente/" target="_blank" rel="noopener noreferrer">
                {t("terms-and-conditions")}
              </a>
              {t("and")}
              <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank" rel="noopener noreferrer">
                {t("privacy-policy")}
              </a>
            </Checkbox>
          </Row>
          <Row className="mt-14">
            <AuthButtonComponent label={t("continue-label")} onClick={updateDateOfBirth} />
          </Row>
        </Space>
      </Row>
    </Col>
  );
};

export default SignUpCreatorStepGoogleComponent;
