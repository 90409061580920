import { Button, Col, Layout, Row, Space, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "../../../assets/icons/lock-grey.svg";
import ArrowRight from "../../../assets/icons/arrow-right-white.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import DatePickerComponent from "../../../components/DatePickerComponent";
import InputComponent from "../../../components/InputComponent";
import PhoneInputComponent from "../../../components/PhoneInputCompnent";
import { IUser } from "../../../helpers/types";
import { AppDispatch, StoreState } from "../../../redux/store";
import { editProfile, getMyProfile, updateProfile } from "../../../redux/users/usersSlice";
import { isDOBPlus18, validateEmail } from "../../../utls/FunctionsUtil";
import { Link, useNavigate } from "react-router-dom";
import BecomingCreatorModalComponent from "../../../components/BecomingCreatorModalComponent";
import { authService } from "../../../services";
import "./index.less";

const PersonalInformationsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const shouldFetch = useRef(true);
  const user = useSelector((state: StoreState) => state.users.currentUserProfile);
  const [userType, setUserType] = useState(user?.type);
  const [change, setChange] = useState<boolean>(false);
  const [isBecomingCreatorModalOpen, setIsBecomingCreatorModalOpen] = useState<boolean>(false);
  const [isBecomingProfessionalModalOpen, setIsBecomingProfessionalModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      getProfile();
    }
  }, []);

  const getProfile = () => {
    dispatch(getMyProfile());
  };

  const itemChanged = (key: keyof IUser, value: string | null) => {
    if (key === "dateOfBirth" && value && !isDOBPlus18(value)) {
      message.warning(t("minimum-age"));
      return;
    }
    setChange(true);
    dispatch(editProfile({ ...user, [key]: value }));
  };

  const phoneCodeChanged = (phoneCode: string, countryCode: string) => {
    setChange(true);
    dispatch(editProfile({ ...user, phoneCode, countryCode }));
  };

  const registerClicked = async () => {
    setUserType(user?.type);
    const data: IUser = {
      dateOfBirth: user?.dateOfBirth,
      email: user?.email,
      phone: user?.phone,
      phoneCode: user?.phoneCode,
      countryCode: user?.countryCode,
    };

    if (!validateEmail(data.email!)) {
      message.error(t("email-invalid"));
      return;
    }
    // else {
    // if (!validatePhoneNumber(data.phone!)) {
    //   message.error(t("phone-invalid"));
    //   return;
    // }
    // }

    const resp: any = await dispatch(updateProfile(data));
    if (resp.meta.requestStatus === "fulfilled") {
      getProfile();
      message.success(t("updated-personal-information-successfully"));
    } else if (resp.meta.requestStatus === "rejected") {
      message.error(t(resp.error.message));
    }
    setChange(false);
  };

  const goToBecomingCreator = () => {
    authService.signOut();
    window.location.reload();
    // navigate("/auth/signUp");
  };

  const goToBecomingProfessional = () => {
    navigate("/creator/billingAddress");
  };

  return (
    <Layout>
      <Layout.Content className="content creatorPersonalInformationContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-60">
          <ArrowLeftIconComponent left={-5} top={1} />
          <Typography.Text className="ml-20 header-title-black-20">{t("personal-information")}</Typography.Text>
        </Row>
        <Row className="mt-20">
          <Space className="w-full gap-13" direction="vertical">
            <Row gutter={7} align="middle">
              <Col span={12}>
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="font-16-bold text-white-color">{t("identity")}</Typography.Text>
                  <InputComponent
                    className="darkInput"
                    type="text"
                    value={user?.firstName || ""}
                    suffix={<img src={LockIcon} alt="lock" />}
                    disabled={true}
                  />
                </Space>
              </Col>
              <Col className="mt-25" span={12}>
                <Space className="w-full gap-6" direction="vertical">
                  <InputComponent
                    className="darkInput"
                    type="text"
                    value={user?.lastName || ""}
                    suffix={<img src={LockIcon} alt="lock" />}
                    disabled={true}
                  />
                </Space>
              </Col>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("user-name-label")}</Typography.Text>
                <InputComponent
                  className="darkInput"
                  type="text"
                  value={user?.username || ""}
                  suffix={<img src={LockIcon} alt="lock" />}
                  disabled={true}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("date-of-birth-label")}</Typography.Text>
                <DatePickerComponent
                  className="darkDatePickerSignup"
                  date={user?.dateOfBirth || ""}
                  onChange={(value) => itemChanged("dateOfBirth", value)}
                />
              </Space>
            </Row>
            <Row>
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("email-label")}</Typography.Text>
                <InputComponent className="darkInput" type="email" value={user?.email || ""} onChange={(e) => itemChanged("email", e.target.value)} />
              </Space>
            </Row>
            <Row className="creator-phone-details">
              <Space className="w-full gap-6" direction="vertical">
                <Typography.Text className="font-16-bold text-white-color">{t("phone-number-label")}</Typography.Text>
                <PhoneInputComponent
                  className="authDark"
                  countryCode={user?.countryCode}
                  phoneCode={user?.phoneCode}
                  phone={user?.phone}
                  onChangePhone={(e) => itemChanged("phone", e.target.value)}
                  onChangePhoneCode={(phoneCode, countryCode) => {
                    phoneCodeChanged(phoneCode, countryCode);
                  }}
                />
              </Space>
            </Row>
            {user?.type === "fan" && (
              <Row className="mt-10">
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="font-16-bold text-white-color">{t("account-type")}</Typography.Text>
                  <Typography.Text className="font-13-bold user-type text-medium-green-color">
                    <Space>
                      <Row className="accountType" align="middle">
                        <Typography.Text className="font-13-bold text-black-color">{t("auth-tab-fan")}</Typography.Text>
                      </Row>
                      <Row className="accountTypeBecomingCreator" align="middle" onClick={() => setIsBecomingCreatorModalOpen(true)}>
                        <Space>
                          <Typography.Text className="font-13-bold text-white-color">{t("become-creator")}</Typography.Text>
                          <img src={ArrowRight} alt="" />
                        </Space>
                      </Row>
                    </Space>
                  </Typography.Text>
                </Space>
              </Row>
            )}

            {user?.type === "creator" && (
              <Row className="mt-10">
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="font-16-bold text-white-color">{t("account-type")}</Typography.Text>
                  <div className="typeAccountRow">
                    <div className="individual-div">
                      <Typography.Text className="font-14-bold text-black-color">
                        {user?.billingAddress?.vatNumber ? t("professional") : t("individual")}
                      </Typography.Text>
                    </div>
                    {user?.billingAddress?.vatNumber && (
                      <Button className="individual-btn professional" onClick={() => navigate("/creator/billingAddress")}>
                        {t("modify-my-information")}
                      </Button>
                    )}
                    {!user?.billingAddress?.vatNumber && (
                      <Button className="individual-btn" onClick={() => setIsBecomingProfessionalModalOpen(true)}>
                        {t("becoming-a-professional")}
                      </Button>
                    )}
                  </div>
                </Space>
              </Row>
            )}
          </Space>
        </Row>
        <Row className="mt-58">
          <Button disabled={!change} className="saveBtn" onClick={registerClicked}>
            {t("register")}
          </Button>
        </Row>
      </Layout.Content>
      <BecomingCreatorModalComponent
        isOpen={isBecomingCreatorModalOpen}
        title={t("what-is-a-creator")}
        paragraphs={[t("what-is-a-creator-text")]}
        submitText={t("i-want-to-be-a-creator")}
        onClose={() => setIsBecomingCreatorModalOpen(false)}
        onSubmit={goToBecomingCreator}
      />
      <BecomingCreatorModalComponent
        isOpen={isBecomingProfessionalModalOpen}
        title={t("what-does-it-mean-to-become-a-professional")}
        paragraphs={[t("what-does-it-mean-to-become-a-professional-text-1"), t("what-does-it-mean-to-become-a-professional-text-2")]}
        submitText={t("confirm-btn")}
        onClose={() => setIsBecomingProfessionalModalOpen(false)}
        onSubmit={goToBecomingProfessional}
      />
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default PersonalInformationsPage;
