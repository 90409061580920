import axios from "axios";
import { authService } from "../services";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080",
  headers: {
    Authorization: authService.getToken(),
  },
  validateStatus: function (status) {
    if (status === 403) {
      authService.signOut();
      window.location.href = "/auth/signIn";
    }
    // Resolve only if the status code is less than 500
    return status < 500;
  },
  timeout: 10 * 60000,
});

// // Add a request interceptor to dynamically update the Authorization header
// instance.interceptors.request.use(
//   (config) => {
//     const token = authService.getToken(); // Get the latest token
//     if (token) {
//       config.headers.Authorization = token; // Set the token in the Authorization header
//     }
//     return config;
//   },
//   (error) => {
//     // Handle request error here
//     return Promise.reject(error);
//   }
// );

export default instance;
