import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { Layout, Row, Typography, Space, Input, message, Col } from "antd";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { IQuickMessage } from "../../../helpers/types";
import { createQuickMessage } from "../../../redux/messages/quickMessagesSlice";
import "./index.less";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";

const CreatorNewMessageResponsePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [quickMessage, setQuickMessage] = useState<IQuickMessage>({
    title: "",
    text: "",
  });

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setQuickMessage((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (isEmpty(quickMessage.title) && isEmpty(quickMessage.text)) {
      message.error(t("all-fields-are-required"));
      return;
    }
    if (isEmpty(quickMessage.title)) {
      message.error(t("no-title-validation"));
      return;
    }
    if (isEmpty(quickMessage.text)) {
      message.error(t("no-message-validation"));
      return;
    }
    dispatch(createQuickMessage(quickMessage));
    navigate(-1);
  };

  return (
    <Layout>
      <Layout.Content className="creatorNewResponseContent gradient-background">
        <Row className="creatorNewResponseTitle w-full justify-content-center">
          <Typography.Text className="header-title-black-22">{t("new-response-registered")}</Typography.Text>
        </Row>
        <Row className="creatorNewResponseBody w-full justify-content-center mt-14">
          <Typography.Text className="font-12-light text-white-color text-center">{t("new-response-register-terms")}</Typography.Text>
        </Row>
        <Row className="creatorNewResponseBody">
          <Row className="w-full mt-49">
            <Row className="w-full input-border-bottom">
              <Input
                name="title"
                className="titleInputNewResponse"
                bordered={false}
                size="large"
                value={quickMessage.title}
                onChange={handleChange}
                placeholder={t("title").toString()}
              />
            </Row>
            <Row className="w-full mt-50 input-border-bottom">
              <Input
                name="text"
                className="textInputNewResponse"
                bordered={false}
                size="large"
                onChange={handleChange}
                value={quickMessage.text}
                placeholder={t("text").toString()}
              />
            </Row>
          </Row>
        </Row>
        <Row className="creatorNewResponseBody mt-79 justify-content-center">
          <AuthButtonComponent label={t("register")} onClick={handleSubmit} />
          <Row className="mt-30">
            <Typography.Text className="font-16-bold text-white-color" onClick={() => navigate(-1)}>
              {t("cancel")}
            </Typography.Text>
          </Row>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default CreatorNewMessageResponsePage;
