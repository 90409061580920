import React from "react";
import { Col, Row, Space, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import AuthButtonComponent from "../../AuthButtonCompnent";
import "../index.less";

const types = [
  "model",
  "influencer",
  "lgbtq",
  "artist",
  "cooking",
  "celebrity-backstage",
  "fitness-coaching",
  "gambler",
  "curvy-woman",
  "no-limit",
  "in-relationship",
  "foot-lover",
  "entrepreneurship",
  "asmr",
  "lingerie",
];

type SignUpCreatorStepTwoProps = {
  selectedTypes: string[];
  typeTagClicked: (type: string) => void;
  updateTypes: () => void;
};

const SignUpCreatorStepTwoComponent: React.FC<SignUpCreatorStepTwoProps> = ({ selectedTypes, typeTagClicked, updateTypes }) => {
  const { t } = useTranslation();
  return (
    <Col className="padding-container">
      <Row className="justify-content-center mt-20">
        <Typography.Text className="authHeader authSelectTypeHeader">{t("auth-content-types-title")}</Typography.Text>
      </Row>
      <Row className="justify-content-center mt-30">
        <Typography.Text className="authSelectTypeDescription">{t("auth-content-types-note")}</Typography.Text>
      </Row>
      <Row className="mt-40">
        <Space className="flex-wrap justify-content-center" direction="horizontal">
          {types.map((type, index) => (
            <Tag
              key={index}
              className={`authTypeTag ${selectedTypes.includes(type) ? "authSelectedTypeTag" : ""}`}
              onClick={() => typeTagClicked(type)}
            >
              {t(type)}
            </Tag>
          ))}
        </Space>
      </Row>
      <Row className="mt-40 padding-container">
        <AuthButtonComponent label={t("continue-label")} onClick={updateTypes} />
      </Row>
    </Col>
  );
};

export default SignUpCreatorStepTwoComponent;
