import { LoadingOutlined } from "@ant-design/icons";
import { Progress, Spin } from "antd";
import React from "react";
import "./index.less";

type props = {
  refProp?: any;
  progress?: boolean;
  progressValue?: number;
  message?: string;
};

const SpinnerComponent: React.FC<props> = ({ refProp, progress, progressValue, message = "" }) => {
  return (
    <div ref={refProp} style={{ display: "flex", flexDirection: "column" }}>
      <Spin
        tip={message}
        indicator={
          progress ? (
            <Progress percent={progressValue} type="circle" style={{ fontSize: 60, color: "#fff" }} />
          ) : (
            <LoadingOutlined style={{ fontSize: 60, color: "#fff" }} spin />
          )
        }
      />
    </div>
  );
};

export default SpinnerComponent;
