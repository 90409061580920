import React from "react";
import { Col, Row, Space, Switch, Typography, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { IMonetization } from "../../../helpers/types";
import AuthButtonComponent from "../../AuthButtonCompnent";
import { SUBSCRIPTION_PRICING_LIMITS } from "../../../utls/constants";
import { getMonetizationsInformations } from "../../../helpers/constant";
import "../index.less";

type SignUpCreatorStepThreeProps = {
  monetization: IMonetization;
  setMonetization: React.Dispatch<React.SetStateAction<IMonetization>>;
  updateMonetization: () => void;
};

const SignUpCreatorStepThreeComponent: React.FC<SignUpCreatorStepThreeProps> = ({ monetization, setMonetization, updateMonetization }) => {
  const { t } = useTranslation();
  const monetizations = getMonetizationsInformations(t);

  const monetizationSelectedChanged = (frequency: string, value: boolean) => {
    switch (frequency) {
      case "trimestrial":
        setMonetization({
          ...monetization,
          trimestrial: value,
          trimestrialPrice: !value ? null : monetization.trimestrialPrice,
        });
        break;
      case "half_yearly":
        setMonetization({
          ...monetization,
          half_yearly: value,
          half_yearlyPrice: !value ? null : monetization.half_yearlyPrice,
        });
        break;
      case "yearly":
        setMonetization({
          ...monetization,
          yearly: value,
          yearlyPrice: !value ? null : monetization.yearlyPrice,
        });
        break;
      default:
        setMonetization(monetization);
    }
  };

  const monetizationPriceChanged = (frequency: string, value: string) => {
    const numberValue = value.replaceAll(/\D/g, "");

    if (frequency === "monthly" && Number(numberValue) > SUBSCRIPTION_PRICING_LIMITS.MONTHLY) {
      message.warning(t("monthly-price-cannot-exceed-300-euros"));
      return;
    } else if (frequency === "trimestrial" && Number(numberValue) > SUBSCRIPTION_PRICING_LIMITS.TRIMESTRIAL) {
      message.warning(t("quarterly-price-cannot-exceed-500-euros"));
      return;
    }

    switch (frequency) {
      case "monthly":
        if (monetization.monthly) {
          setMonetization({
            ...monetization,
            monthlyPrice: Number(numberValue),
          });
        }
        break;
      case "trimestrial":
        if (monetization.trimestrial) {
          setMonetization({
            ...monetization,
            trimestrialPrice: Number(numberValue),
          });
        }
        break;
      default:
        setMonetization(monetization);
    }
  };

  return (
    <Col className="padding-container">
      <Row className="justify-content-center mt-20 padding-container">
        <Typography.Text className="authHeader authRevenueHeader">{t("subscription-price")}</Typography.Text>
      </Row>
      <Row className="justify-content-center mt-7">
        <Typography.Text className="authSelectTypeDescription">{t("you-can-change-it-later")}</Typography.Text>
      </Row>
      <Row className="mt-40">
        <Space className="w-full gap-39" direction="vertical">
          {monetizations.map((item, index) => (
            <Col key={index}>
              <Row>
                <Space>
                  <Typography.Text className="authRevenueItemTitle">{item.title}</Typography.Text>
                  {item.important && <Typography.Text className="authRevenueItemImportantTitle">*</Typography.Text>}
                </Space>
              </Row>
              <Row className="mt-10 justify-content-between items-center">
                <Space className="d-flex items-end">
                  <Input
                    className="authInput authRevenueInput"
                    value={
                      item.frequency === "monthly"
                        ? monetization.monthlyPrice || ""
                        : item.frequency === "trimestrial"
                          ? monetization.trimestrialPrice || ""
                          : item.frequency === "half_yearly"
                            ? monetization.half_yearlyPrice || ""
                            : monetization.yearlyPrice || ""
                    }
                    placeholder="0.00€"
                    onChange={(e) => monetizationPriceChanged(item.frequency, e.target.value)}
                    inputMode="numeric"
                  />
                  <Typography.Text className="authRevenueItemSubTitle">{item.subTitle}</Typography.Text>
                </Space>
                <Switch
                  className={`authSwitch monetizationSwitch ${item.frequency === "monthly" && monetization.monthly ? "switchcolor" : ""} 
                          ${item.frequency === "trimestrial" && monetization.trimestrial ? "switchcolor" : ""}
                          ${item.frequency === "half_yearly" && monetization.half_yearly ? "switchcolor" : ""}
                          ${item.frequency === "yearly" && monetization.yearly ? "switchcolor" : ""}`}
                  checked={
                    item.frequency === "monthly"
                      ? monetization.monthly
                      : item.frequency === "trimestrial"
                        ? monetization.trimestrial
                        : item.frequency === "half_yearly"
                          ? monetization.half_yearly
                          : monetization.yearly
                  }
                  onChange={(checked: boolean) => monetizationSelectedChanged(item.frequency, checked)}
                />
              </Row>
            </Col>
          ))}
        </Space>
      </Row>
      <Row className="mt-40">
        <AuthButtonComponent label={t("continue-label")} onClick={updateMonetization} />
      </Row>
    </Col>
  );
};

export default SignUpCreatorStepThreeComponent;
