import { Col, Row, Typography } from "antd";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import CollectionFileIcon from "../../../assets/icons/collection-file.svg";
import CollectionPriceIcon from "../../../assets/icons/collection-price.svg";
import { ICollections, PageableData } from "../../../helpers/types";
import { collectionService } from "../../../services/collection.service";
import SpinnerComponent from "../../SpinnerComponent/SpinnerComponent";
import "./index.less";

type CollectionGridProps = {
  isMyProfile?: boolean;
  creatorId?: string;
  isPublic?: boolean;
  showModal?: () => void;
};

const ITEMS_PER_PAGE = 15;

const CollectionGridComponent: React.FC<CollectionGridProps> = ({ isMyProfile, creatorId, isPublic = false, showModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState<"idle" | "pending" | "succeeded" | "failed">("idle");
  const [collections, setCollections] = useState<ICollections[]>([]);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        setLoading("pending");

        let res: PageableData<ICollections>;

        if (!isPublic) {
          res = isMyProfile
            ? await collectionService.getCreatorCollections(currentPage)
            : await collectionService.getFanCollections(creatorId!, currentPage);
        } else {
          res = await collectionService.getPublicCollections(creatorId!, currentPage);
          res.data = res?.data.map((item: any) => {
            delete item.price;
            return item;
          });
        }

        setCollections((prevCollections) => uniqBy([...prevCollections, ...res.data], "_id"));
        setLoading("succeeded");
        const totalPages = Math.ceil(res.total / ITEMS_PER_PAGE);
        setHasMore(currentPage < totalPages);
      } catch (error) {
        setHasMore(false);
        setLoading("failed");
      }
    };

    if (hasMore && ((creatorId && creatorId !== "") || isMyProfile)) {
      fetchCollections();
    }
  }, [isMyProfile, creatorId, currentPage]);

  useEffect(() => {
    setCollections([]);
    setCurrentPage(1);
    setHasMore(true);
  }, [isMyProfile, creatorId]);

  const handleNavigation = (collectionId: string) => {
    if (isPublic) {
      if (showModal) showModal();
    } else {
      if (isMyProfile) {
        navigate("/creator/collection/" + collectionId);
      } else {
        navigate("/common/collection/" + collectionId);
      }
    }
  };

  const loadMoreCollections = () => {
    if (hasMore && loading !== "pending") {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading === "pending",
    hasNextPage: hasMore,
    onLoadMore: loadMoreCollections,
  });

  return (
    <Row className="custom-spacing" gutter={4}>
      {collections.length === 0 && loading === "succeeded" && (
        <Row className="w-full justify-content-center mt-50">
          <Typography.Text className="font-16-bold text-white-color">{t("no-collection-at-the-moment")}</Typography.Text>
        </Row>
      )}
      {collections.map((collection, index) => (
        <Col
          key={index}
          className="collection-item"
          span={8}
          onClick={() => {
            handleNavigation(collection._id);
          }}
        >
          <div className="collection-box">
            <img className="collection-img" src={collection.thumbnail} alt="collection" />
            <div className={`${collection.price === undefined ? "d-none" : ""} overlay-left`}>
              <img src={CollectionPriceIcon} className="overlay-icon" alt="price" />
              <span className="overlay-text">{collection.price} €</span>
            </div>
            <div className="overlay-right">
              <img src={CollectionFileIcon} className="overlay-icon" alt="files" />
              <span className="overlay-text">{collection.filesCount}</span>
            </div>
            <div className="overlay-bottom">
              <div className="collection-description-container">
                <Typography.Text className="collection-description-text">{collection.title}</Typography.Text>
              </div>
            </div>
          </div>
        </Col>
      ))}
      {(loading === "pending" || hasMore) && (
        <Row className="w-full justify-content-center mt-20">
          <SpinnerComponent refProp={sentryRef} />
        </Row>
      )}
    </Row>
  );
};

export default CollectionGridComponent;
