import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";
import { ICollectionsState, IDeleteResponse } from "../../helpers/types";
import { collectionService } from "../../services/collection.service";

const initialState: ICollectionsState = {
  creatorCollections: [],
  fanCollections: [],
  loadingCreatorCollections: "idle",
  loadingFanCollections: "idle",
  errorCreatorCollections: null,
  errorFanCollections: null,
  total: -1,
};

export const getCreatorCollections = createAsyncThunk("collections/getCreatorCollections", async (page: number, { rejectWithValue }) => {
  try {
    return await collectionService.getCreatorCollections(page);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFanCollections = createAsyncThunk(
  "collections/getFanCollections",
  async ({ creatorId, page }: { creatorId: string; page: number }, { rejectWithValue }) => {
    try {
      return await collectionService.getFanCollections(creatorId, page);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCollectionById = createAsyncThunk<IDeleteResponse, string>(
  "collections/deleteCollection",
  async (collectionId: string, { rejectWithValue }) => {
    try {
      return await collectionService.deleteCollection(collectionId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const collectionsSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCreatorCollections.pending, (state) => {
      state.loadingCreatorCollections = "pending";
    });
    builder.addCase(getCreatorCollections.fulfilled, (state, action) => {
      if (action.payload.data.length > 0) {
        state.creatorCollections = uniqBy(state.creatorCollections.concat(action.payload.data), "_id");
      }
      state.loadingCreatorCollections = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(getCreatorCollections.rejected, (state, action) => {
      state.loadingCreatorCollections = "failed";
      state.errorCreatorCollections = action.payload || "Something went wrong";
    });
    builder.addCase(getFanCollections.pending, (state) => {
      state.loadingFanCollections = "pending";
    });
    builder.addCase(getFanCollections.fulfilled, (state, action) => {
      if (action.payload.data.length > 0) {
        state.fanCollections = uniqBy(state.fanCollections.concat(action.payload.data), "_id");
      }
      state.loadingFanCollections = "succeeded";
      state.total = action.payload.total;
    });
    builder.addCase(getFanCollections.rejected, (state, action) => {
      state.loadingFanCollections = "failed";
      state.errorFanCollections = action.payload || "Something went wrong";
    });
    builder.addCase(deleteCollectionById.fulfilled, (state, action) => {
      state.creatorCollections = state.creatorCollections.filter((collection) => collection._id !== action.payload._id);
    });
  },
});

export default collectionsSlice.reducer;
