import { Button, Row, Typography } from "antd";
import "./index.less";

const RESERVE_TICKET_TEXT =
  "Sebydaddy et Illan CTO s'affronteront dans un combat de MMA dans la soirée du 26 octobre. Vous avez la possibilité d'acheter un billet en ligne pour accéder au combat en direct, uniquement sur notre plateforme.";

const ALREADY_RESERVED_TEXT =
  "Sebydaddy et Illan CTO s'affronteront dans un combat de MMA le soir du 26 octobre. Étant donné que vous avez déjà un billet, il vous suffit de vous connecter avec votre compte et de vous rendre dans le menu \"Live\" pour visionner l'événement.";

interface InfoTicketLiveEventProps {
  alreadyReserved: boolean;
  onButtonClick: () => void;
}

const InfoTicketLiveEventComponent: React.FC<InfoTicketLiveEventProps> = ({ onButtonClick, alreadyReserved }) => {
  return (
    <>
      <Row className="public-live-content-container">
        <Typography.Text className="public-live-content-text">Pour un live exclusif.</Typography.Text>
        <Typography.Text className="public-live-content-description">{alreadyReserved ? ALREADY_RESERVED_TEXT : RESERVE_TICKET_TEXT}</Typography.Text>
      </Row>

      <Row className={`date-row ${alreadyReserved ? "" : "mb-100"}`}>
        <div className="date-container">
          <Typography.Text className="date-text">Samedi 26/10</Typography.Text>
        </div>
        <div className="date-container">
          <Typography.Text className="date-text">À partir de 19h00</Typography.Text>
        </div>
      </Row>

      {alreadyReserved ? (
        <Row className="live-event-btn-row">
          <Button className="already-reserve-btn" disabled>
            Vous avez déjà un ticket
          </Button>
        </Row>
      ) : (
        <Row className="live-event-btn-sticky-row">
          <Button className="reserve-sticky-btn" onClick={onButtonClick}>
            Réserve ton ticket maintenant
          </Button>
        </Row>
      )}
    </>
  );
};

export default InfoTicketLiveEventComponent;
