import { Layout, Space, Typography, message } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { LIVE_TYPES } from "../../helpers/constant";
import { getLivesForFan, updateEndLive } from "../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../redux/store";
import { socket } from "../../socket";
import { FanLivesPageSkelton } from "../SkeltonsComponents/FanLivesPageSkelton";
import FanLiveWhoLikesItComponent from "./FanLiveWhoLikesItComponent/FanLiveWhoLikesItComponent";
import FanDiscoverLiveComponent from "./FanDiscoverLiveComponent/FanDiscoverLiveComponent";
import "./index.less";
import FightEventComponent from "../LiveEventComponent/FightEventComponent";

interface FanLivesSwiperProps {
  onNewLivePublished: (data: any) => void;
}

const FanLivesSwiper: React.FC<FanLivesSwiperProps> = ({ onNewLivePublished }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const { discoverList: discoverLives, suggestedList: suggestedLives, loading, error, rtmpLive } = useSelector((state: StoreState) => state.lives);

  useEffect(() => {
    socket.on("live_published", onNewLivePublished);
    socket.on("user_leaved", liveUnPublished);
    if (searchParams.get("tab") === LIVE_TYPES.NOW && discoverLives.length === 0 && suggestedLives.length === 0) {
      dispatch(getLivesForFan({ startType: LIVE_TYPES.NOW }));
    }

    return () => {
      socket.off("live_published", onNewLivePublished);
      socket.off("user_leaved", liveUnPublished);
    };
  }, [searchParams, dispatch]);

  const liveUnPublished = (stream: any) => {
    dispatch(updateEndLive(stream));
  };

  const errorMessage = () => {
    return <>{message.error("live-menu-error")}</>;
  };

  return (
    <Layout className="content livesSwiperWrapper background-transparent">
      {error && errorMessage()}
      {loading ? (
        <FanLivesPageSkelton loading={true} />
      ) : suggestedLives.length !== 0 || discoverLives.length !== 0  || (rtmpLive !== null && rtmpLive?.isStreaming)  ? (
        <Space className="gap-1 mb-80" direction="vertical">
          {rtmpLive?.isStreaming && <FightEventComponent isFromNow={true} />}
          {suggestedLives.length !== 0 && <FanLiveWhoLikesItComponent lives={suggestedLives} />}
          {discoverLives.length !== 0 && <FanDiscoverLiveComponent lives={discoverLives} />}
        </Space>
      ) : (
        <Typography.Text className="text-white-color noLivesMessage">{t("no-lives-message")}</Typography.Text>
      )}
    </Layout>
  );
};

export default FanLivesSwiper;
