import axios from "../helpers/axios";
import { IFeed } from "../helpers/types";
import { authService } from "./index";

class PayoutRequestService {

  public getPayoutHistory(param: any) {
    const url = `/payout-requests/creator/payoutHistory?status=done`;
    return new Promise<any>((resolve, reject) => {
      axios.get(url,{
            params: {
                limit: param.limit ? param.limit : "",
                offset: param.offset ? param.offset : ""
            },
            headers: {
                Authorization: authService.getToken()
            }
        })
        .then(response => {
            resolve(response?.data);
        })
        .catch(error => {
            if (error?.response?.data?.message)
                reject(error.response.data.message);
            else reject("Something went wrong");
        });
    });
  };

  public getFeeds = (id: string) => {
    const url = `/feeds/creators/search`;
    return new Promise<IFeed[]>((resolve, reject) => {
      axios.get(url, {
        params: {
          creatorId: id
        },
        headers: {
          Authorization: authService.getToken()
        }
      })
        .then(response => {
          if (response?.data?.data?.data)
            resolve(response.data.data.data);
          else
            reject("Something went wrong");
        })
        .catch(error => {
          if (error?.response?.data?.message)
            reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

}

export const payoutRequestService = new PayoutRequestService();