import { Layout, Row, Space, Typography } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close-with-shadow.svg";
import VideoPlayer from "../../../components/VideoPlayerComponent";
import { authService } from "../../../services";
import "./index.less";

const FanLiveMMA = () => {
  const navigate = useNavigate();
  const user = authService.getUser();

  /* useEffect(() => {
    const handleOrientationChange = () => {
      const newOrientation = window.screen.orientation.type;

      const videoElement = document.querySelector(".video-player");

      if (newOrientation.startsWith("landscape")) {
        // Enter fullscreen when in landscape mode
        if (videoElement && videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if ((videoElement as any).webkitEnterFullscreen) {
          // For Safari
          (videoElement as any).webkitEnterFullscreen();
        }
      } else if (newOrientation.startsWith("portrait")) {
        // Exit fullscreen when in portrait mode
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
          // For Safari
          (document as any).webkitExitFullscreen();
        }
      }
    };

    window.screen.orientation.addEventListener("change", handleOrientationChange);

    // Cleanup event listener on component unmount
    return () => {
      window.screen.orientation.removeEventListener("change", handleOrientationChange);
    };
  }, []); */

  useEffect(() => {
    const handleOrientationChange = () => {
      const newOrientation = window.screen.orientation?.type || null;

      const videoElement = document.getElementsByTagName("video")[0] as HTMLVideoElement;

      if (videoElement) {
        if (newOrientation?.startsWith("landscape")) {
          // Enter fullscreen for landscape
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if ((videoElement as any).webkitEnterFullscreen) {
            // For Safari on iOS
            (videoElement as any).webkitEnterFullscreen();
          }
        } else if (newOrientation?.startsWith("portrait")) {
          // Exit fullscreen for portrait
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
          }
        }
      }
    };

    const handleResize = () => {
      // Fallback for older iOS versions that do not support screen.orientation
      const isLandscape = window.innerWidth > window.innerHeight;

      const videoElement = document.getElementsByTagName("video")[0] as HTMLVideoElement;

      if (videoElement) {
        if (isLandscape) {
          // Enter fullscreen for landscape mode
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if ((videoElement as any).webkitEnterFullscreen) {
            // For Safari on iOS
            (videoElement as any).webkitEnterFullscreen();
          }
        } else {
          // Exit fullscreen for portrait mode
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else if ((document as any).webkitExitFullscreen) {
            (document as any).webkitExitFullscreen();
          }
        }
      }
    };

    if (window.screen.orientation) {
      // Modern browsers with screen.orientation support
      window.screen.orientation.addEventListener("change", handleOrientationChange);
    } else {
      // Fallback for older browsers (iOS 15 and below)
      window.addEventListener("resize", handleResize);
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener("change", handleOrientationChange);
      } else {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  const onClickClose = async (data: any) => {
    navigate("/p/fan/lives?tab=schedule");
  };

  return (
    <Layout>
      <Layout.Content className="fanLiveContentWrapper">
        <Row className="justify-content-between pl-15 pr-3 z-fan-live" align="middle">
          <Space className="header-container-left-live gap-1">
            <Typography.Text className="fullname">LOLY.APP</Typography.Text>
          </Space>
          <Space className={`gap-20 `}>
            <img src={CloseIcon} width={22} alt="close" onClick={onClickClose} />
          </Space>
        </Row>

        <div className="">
          <VideoPlayer
            classNameProp={``}
            videoUrl="https://moly-bucket.s3.eu-west-1.amazonaws.com/MMA_10-26-2024.mp4"
            autoPlay={true}
            watermarkText={user.username}
            thumbnailUrl=""
          />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default FanLiveMMA;
