import { Input, Layout, Row, Spin, Switch, Typography, message } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import AddMediaComponent from "../../../components/AddMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { IFeed } from "../../../helpers/types";
import { getMyFeeds } from "../../../redux/feeds/feedsSlice";
import { AppDispatch } from "../../../redux/store";
import { feedService } from "../../../services";
import { decryptService } from "../../../services/decrypt.service";
import { UploadedPart } from "../CreatorCreateDecryptPage/CreatorCreateDecryptPage";
import "./index.less";

const CreatorPublicationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [feed, setFeed] = useState<IFeed>({ isPrivate: true, type: "photo", text: "" });
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  const changedItem = (field: keyof IFeed, value: any) => {
    setFeed((prevFeed) => ({
      ...prevFeed,
      [field]: value,
    }));
  };

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFiles(fileList);
  };

  // const createFeed = async () => {
  //   if (files !== undefined && files.length > 0) {
  //     setLoading(true);
  //     const feedData = new FormData();
  //     /* files.forEach((file: any) => {
  //       feedData.append(`files`, file.originFileObj);
  //     }); */
  //     const file = files[0];
  //     if (file?.originFileObj?.type?.includes("image/")) {
  //       feedData.append(`files`, file.originFileObj);
  //     } else {
  //       const { uploadId, filename } = await decryptService.initializeMultiPartUpload({
  //         filename: file.name,
  //         contentType: file.type,
  //       });

  //       setTotalSize(file.size);
  //       let totalUploadedBytes = { value: 0 };

  //       const chunkSize = 5 * 1024 * 1024; // 5MB chunks
  //       const chunks = Math.ceil(file.size / chunkSize);
  //       const uploadPromises: Promise<UploadedPart>[] = [];

  //       for (let i = 0; i < chunks; i++) {
  //         const start = i * chunkSize;
  //         const end = Math.min(file.size, start + chunkSize);
  //         const chunk = file.originFileObj.slice(start, end);

  //         uploadPromises.push(uploadChunk(chunk, i + 1, uploadId, chunks, filename, totalSize, totalUploadedBytes));
  //       }

  //       //setProgress(2);
  //       const parts = await Promise.all(uploadPromises);

  //       const result = await decryptService.finalizeMultipartUpload(uploadId, parts, filename);

  //       const videoFiles = [
  //         {
  //           Location: result.Location,
  //           Key: result.Key,
  //           ETag: result.ETag,
  //           Bucket: result.Bucket,
  //           filename: file.name,
  //           size: file.size,
  //         },
  //       ];
  //       feedData.append("videoFiles", JSON.stringify(videoFiles));
  //     }
  //     feed.type = files[0].type.includes("video") ? "video" : "photo";
  //     for (const [key, value] of Object.entries(feed)) {
  //       feedData.set(key, value);
  //     }

  //     const resp = feedService.createFeed(feedData, setProgress);
  //     resp
  //       .then((data: any) => {
  //         if (data.message) {
  //           message.error(data.message);
  //         } else {
  //           message.success(t("feed-created-successfully"));
  //           message.info(t("big-file-info"));
  //           setLoading(false);
  //           dispatch(getMyFeeds());
  //           navigate("/creator/profile/newPost");
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         message.error(error);
  //       });
  //   }
  // };

  const createFeed = async () => {
    if (files !== undefined && files.length > 0) {
      setLoading(true);
      const feedData = new FormData();
      const file = files[0];

      if (file?.originFileObj?.type?.includes("image/")) {
        feedData.append(`files`, file.originFileObj);
      } else {
        const { uploadId, filename } = await decryptService.initializeMultiPartUpload({
          filename: file.name,
          contentType: file.type,
        });

        setTotalSize(file.size);
        let totalUploadedBytes = { value: 0 };

        const chunkSize = 5 * 1024 * 1024; // 5MB chunks
        const chunks = Math.ceil(file.size / chunkSize);

        // Helper function to process a batch of chunks
        const uploadChunkBatch = async (startIndex: number, batchSize: number) => {
          const uploadPromises: Promise<UploadedPart>[] = [];

          // Prepare a batch of `batchSize` chunks
          for (let i = startIndex; i < Math.min(startIndex + batchSize, chunks); i++) {
            const start = i * chunkSize;
            const end = Math.min(file.size, start + chunkSize);
            const chunk = file.originFileObj.slice(start, end);

            uploadPromises.push(uploadChunk(chunk, i + 1, uploadId, chunks, filename, totalSize, totalUploadedBytes));
          }

          // Wait for the batch to finish uploading
          return await Promise.all(uploadPromises);
        };

        let allUploadedParts: UploadedPart[] = [];

        // Upload in batches of 6 chunks at a time
        const batchSize = 6;
        for (let i = 0; i < chunks; i += batchSize) {
          const uploadedParts = await uploadChunkBatch(i, batchSize);
          allUploadedParts = [...allUploadedParts, ...uploadedParts];
        }

        // Finalize the multipart upload once all chunks are uploaded
        const result = await decryptService.finalizeMultipartUpload(uploadId, allUploadedParts, filename);

        const videoFiles = [
          {
            Location: result.Location,
            Key: result.Key,
            ETag: result.ETag,
            Bucket: result.Bucket,
            filename: file.name,
            size: file.size,
          },
        ];
        feedData.append("videoFiles", JSON.stringify(videoFiles));
      }

      // Set the type of the feed based on the file type (photo or video)
      feed.type = files[0].type.includes("video") ? "video" : "photo";
      for (const [key, value] of Object.entries(feed)) {
        feedData.set(key, value);
      }

      const resp = feedService.createFeed(feedData, setProgress);
      resp
        .then((data: any) => {
          if (data.message) {
            message.error(data.message);
          } else {
            message.success(t("feed-created-successfully"));
            message.info(t("big-file-info"));
            setLoading(false);
            dispatch(getMyFeeds());
            navigate("/creator/profile/newPost");
          }
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  const uploadChunk = async (
    chunk: Blob,
    partNumber: number,
    uploadId: string,
    chunks: number,
    filename: string,
    totalSize: number,
    totalUploadedBytes: { value: number }
  ): Promise<UploadedPart> => {
    const formData = new FormData();
    formData.append("chunk", chunk);
    formData.append("partNumber", partNumber.toString());
    formData.append("uploadId", uploadId);
    formData.append("filename", filename);
    //const data = await decryptService.uploadMultiChunk(formData, chunks, totalSize, totalUploadedBytes, setProgress);
    const data = await decryptService.uploadMultiChunk(formData, setLoadedData).then((response) => {
      //totalUploadedBytes.value += chunk.size;
      //setLoadedData(totalUploadedBytes.value);
      return response;
    });
    return { ETag: data, PartNumber: partNumber };
  };

  return (
    <Spin
      spinning={loading}
      style={{ maxHeight: "100%" }}
      indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
    >
      <Layout>
        <Layout.Content className="content creatorPublicationContentWrapper gradient-background">
          <Row className="relative justify-content-center header-title-container">
            <ArrowLeftIconComponent top={10} left={0} />
            <Typography.Text className="header-title-black-32">{t("publications")}</Typography.Text>
          </Row>
          <Row className="w-full mt-20 justify-content-center">
            <AddMediaComponent
              showTitle={false}
              maxCount={1}
              crop={true}
              onFilesChange={handleFilesChange}
              greenCircle={true}
              iconSize={80}
              addPublication={true}
            />
          </Row>
          <Row className="mt-20">
            <Input.TextArea
              className="captionInput"
              placeholder={`${t("add-a-caption")}...`}
              autoSize={{ minRows: 6, maxRows: 6 }}
              bordered={false}
              onChange={(value) => changedItem("text", value.target.value)}
            />
          </Row>
          <Row className="justify-content-center mt-30">
            <Row className="w-full justify-content-between">
              <Typography.Text className="font-24-bold text-white-color">{t("want-blur-media")}</Typography.Text>
              <Switch className="switch revenueSwitch" defaultChecked={feed.isPrivate} onChange={(value) => changedItem("isPrivate", value)} />
            </Row>
            <Row className="w-full justify-content-center mt-30">
              <AuthButtonComponent
                label={t("publish")}
                onClick={() => {
                  !loading && createFeed();
                }}
              />
            </Row>
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default CreatorPublicationPage;
