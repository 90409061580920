import axios from "axios";
import { t } from "i18next";
import cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { IUser } from "../helpers/types";

class AuthService {
  setToken(token: string, remember = false): void {
    const options = {
      expires: !remember ? 1 : 30,
    };
    cookie.set("token", token, options);
  }

  getToken(): string {
    return cookie.get("token") as string;
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (token) {
      const decodedToken: any = jwt_decode(token);
      if (decodedToken.registrationStatus) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  }

  getId(): string {
    try {
      const token = this.getToken();
      const decodedToken: any = jwt_decode(token);
      return decodedToken.sourceId as string;
    } catch (e) {
      console.log("error in getId", e);
      return "";
    }
  }

  getRole(): string {
    try {
      const token = this.getToken();
      const decodedToken: any = jwt_decode(token);
      return decodedToken.source as string;
    } catch (e) {
      console.log("error in getRole", e);
      return "";
    }
  }

  getUser(): any {
    try {
      const token = this.getToken();
      const decodedToken: any = jwt_decode(token);
      return decodedToken;
    } catch (e) {
      console.log("error in getUser", e);
      return "";
    }
  }

  login(usernameOrEmail: string, password: string, remember: boolean, navigate: any) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/login`;

    return new Promise<void>((resolve, reject) => {
      axios
        .post(url, { usernameOrEmail, password, remember })
        .then((response: any) => {
          if (response?.data?.data?.token) {
            this.setToken(response.data.data.token, remember);
            resolve();
          } else if (response?.data?.status === 1) {
            const data = response?.data?.error?.stack;
            const { id, email, stage } = JSON.parse(data);
            navigate(`/auth/signUp?id=${id}&email=${email}&currentTab=${stage}`);
          } else reject(t("something-want-wrong"));
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject(t("something-want-wrong"));
        });
    });
  }

  register(data: IUser) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/register`;

    return new Promise<IUser>((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          if (response?.data?.data?.token) {
            this.setToken(response.data.data.token, true);
            resolve(response.data.data);
          } else if (response?.data?.data) {
            resolve(response.data.data);
          } else reject(t("something-want-wrong"));
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject(t("something-want-wrong"));
        });
    });
  }

  googleSignUp(accessToken: string, type: string, sponsorId: string = "") {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/google/register`;

    return new Promise<{ data: IUser; status: number; token: string }>((resolve, reject) => {
      axios
        .post(url, { accessToken, type, sponsorId })
        .then((response) => {
          if (response?.data.status === 0 && response?.data?.data)
            resolve({ data: response?.data?.data?.user, status: response.data.status, token: response.data.data.token });
          else reject(t("something-want-wrong"));
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject(t("something-want-wrong"));
        });
    });
  }

  googleLogin(accessToken: string, navigate: any) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/google/login`;

    return new Promise<string>((resolve, reject) => {
      axios
        .post(url, { accessToken })
        .then((response) => {
          if (response?.data?.token) {
            this.setToken(response.data.token, true);
            resolve(response.data.token);
          } else if (response?.data?.status === 1) {
            const data = response?.data?.error?.stack;
            const { id, email, stage } = JSON.parse(data);
            navigate(`/auth/signUp?id=${id}&email=${email}&currentTab=${stage}`);
          } else reject(t("something-want-wrong"));
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject(t("something-want-wrong"));
        });
    });
  }

  forgotPassword(email: string) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/users/forgot`;

    return new Promise<string>((resolve, reject) => {
      axios
        .post(url, { email })
        .then((response) => {
          if (response?.data?.data?.success) resolve(response.data.data.success);
          else reject(t("something-want-wrong"));
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject(t("something-want-wrong"));
        });
    });
  }

  changePassword(payload: any, token: string) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/auth/password-change?token=${token}`;

    return new Promise<string>((resolve, reject) => {
      axios
        .post(url, payload)
        .then((response) => {
          if (response?.data?.data?.success) resolve(response.data.data.success);
          else reject(t("something-want-wrong"));
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject(t("something-want-wrong"));
        });
    });
  }

  signOut() {
    cookie.remove("token");
  }
}

export const authService = new AuthService();
