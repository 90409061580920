import axios from "../helpers/axios";
import { ICreatorSuggestion, ICreatorSuggestionSection, IDirectLinks } from "../helpers/types";

class creatorSuggestion {
  public getCreatorSuggestion() {
    const url = `/suggestions/search`;
    return new Promise<ICreatorSuggestion[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {          
          resolve(response?.data?.data?.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getCreatorSuggestionByType(type: number) {
    const url = `/suggestions/type/${type}`;
    return new Promise<ICreatorSuggestionSection[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {    
          resolve(response?.data?.data?.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }

  public getDirectLinks() {
    const url = `/directLink/search`;
    return new Promise<IDirectLinks[]>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          resolve(response?.data?.data?.data);
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  }
}

export const creatorService = new creatorSuggestion();
