import React from "react";
import { Button } from "antd";
import "./index.less";
import { useTranslation } from "react-i18next";
type props = {
  label: string;
  onClick?: any;
  disabled?: boolean;
  height?: string;
  fontSize?: string;
  borderRadius?: string;
};

const AuthButtonComponent: React.FC<props> = ({ label, onClick, disabled, height, fontSize, borderRadius }) => {
  const { t } = useTranslation();

  const buttonStyle: React.CSSProperties = {
    ...(height ? { height } : {}),
    ...(fontSize ? { fontSize } : {}),
    ...(borderRadius ? { borderRadius } : {}),
  };

  return (
    <Button className="authBtn" onClick={onClick} disabled={disabled} style={buttonStyle}>
      {t(label)}
    </Button>
  );
};

export default AuthButtonComponent;
