import React, { useState } from "react";
import { Typography, Row, Checkbox, message } from "antd";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../assets/icons/cross-grey.svg";
import VerifiedIcon from "../../assets/icons/verify.svg";
import PhoneInputComponent from "../PhoneInputCompnent";
import AuthButtonComponent from "../AuthButtonCompnent";
import { userService } from "../../services";
import "./index.less";

interface FanPhoneModalComponent {
  visible: boolean;
  onClose: () => void;
}

const FanPhoneModalComponent: React.FC<FanPhoneModalComponent> = ({ visible, onClose }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [acceptPromo, setAcceptPromo] = useState<boolean>(true);

  if (!visible) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleSubmit = async () => {
    try {
      await userService.addFanPhoneNumber(phone, phoneCode, countryCode, acceptPromo);
      message.success(t("phone-number-added-successfully"));
      onClose();
    } catch (error) {
      message.error(t("an-error-occurred-while-adding-the-phone-number"));
    }
  };

  return (
    <div className="fan-phone-modal-overlay">
      <div className="fan-phone-modal-content" onClick={handleModalClick}>
        <div className="fan-phone-header">
          <img className="header-arrow-back" src={CrossIcon} width={16} alt="Back" onClick={handleOverlayClick} />
        </div>
        <div className="fan-phone-content">
          <Typography.Title className="title-content">
            {t("Certifier votre compte")} <img src={VerifiedIcon} width={20} alt="verified" />
          </Typography.Title>
          <Typography.Paragraph className="description-content">{t("enter-your-phone-number-and-you-will-be-certified")}</Typography.Paragraph>
          <Row className="mt-20 justify-content-center">
            <PhoneInputComponent
              className="fan-phone-modal"
              countryCode={countryCode}
              phoneCode={phoneCode}
              phone={phone}
              onChangePhone={(e) => setPhone(e.target.value)}
              onChangePhoneCode={(code, cc) => {
                setPhoneCode(code);
                setCountryCode(cc);
              }}
              autoFocus={true}
            />
          </Row>
          <Row className="mt-7">
            <Checkbox className="authTermsCheckBox" checked={acceptPromo} onChange={(e) => setAcceptPromo(e.target.checked)}>
              <span>{t("i-agree-to-receive-promotions")}</span>
            </Checkbox>
          </Row>
          <Row className="mt-25">
            <AuthButtonComponent height="50px" label={t("continue")} onClick={handleSubmit} disabled={phone.length < 9} />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FanPhoneModalComponent;
