import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Layout, message, Row, Space, Typography } from "antd";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { mediapushService } from "../../../services";
import { convertToShortDate } from "../../../utls/FunctionsUtil";
import { IMediapushHistory } from "../../../helpers/types";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import useInfiniteScroll from "react-infinite-scroll-hook";
import "./index.less";

const CreatorHistoryMediaPushPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [mediaPushHistory, setMediaPushHistory] = useState<IMediapushHistory[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(10);

  useEffect(() => {
    loadMoreData();
  }, []);

  const loadMoreData = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await mediapushService.mediaPushHistory({ limit, offset });

      if (response.length > 0) {
        setMediaPushHistory((prev) => [...prev, ...response]);
        setOffset((prevOffset) => prevOffset + response.length);
        setLimit((prevLimit) => prevLimit + 10);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMoreData,
  });

  return (
    <Layout>
      <Layout.Content className="content creatorHistoryMediaPushContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-60 mb-20">
          <ArrowLeftIconComponent top={0} left={0} />
          <Typography.Text className="ml-25 mt-1 header-title-black-20">{t("history-media-push")}</Typography.Text>
        </Row>
        {mediaPushHistory.map((item, index) => (
          <Row
            onClick={() => navigate(`/creator/performance/${item.mediaPushId}?totalEarn=${item.totalEarn}`)}
            className="justify-content-between items-start border-bottom-history-push"
            key={index}
          >
            <Col>
              <Space className="gap-40">
                <Typography.Text className="font-18-bold text-white-color">
                  {convertToShortDate(item.createdAt, t("current-language"))}
                </Typography.Text>
                <div className="subscriber-block">
                  <Typography.Text className="font-11-bold">
                    {item.targetPeople} {t("subscribers")}
                  </Typography.Text>
                </div>
              </Space>
            </Col>
            <Space className="gap-15">
              <Typography.Text className="font-20-bold text-white-color">{item.totalEarn.toFixed(2)} €</Typography.Text>
            </Space>
          </Row>
        ))}
        {(loading || hasMore) && <SpinnerComponent refProp={sentryRef} />}
      </Layout.Content>
    </Layout>
  );
};

export default CreatorHistoryMediaPushPage;
