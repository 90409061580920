import type { UploadFile } from "antd";
import { Button, Layout, Row, Space, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AddCircleGrey from "../../../assets/icons/add-circle-grey.svg";
import AddMediaComponent from "../../../components/AddMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { decryptService } from "../../../services/decrypt.service";
import { quickFileService } from "../../../services/quickFile.service";
import { UploadedPart } from "../CreatorCreateDecryptPage/CreatorCreateDecryptPage";
import "./index.less";

const CreatorAddMediaComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { albumId } = useParams();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  const handleFilesChange = (fileList: UploadFile<[]>[]) => {
    setFileList(fileList);
  };

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  // async function handleMediaUpload() {
  //   setLoading(true);
  //   let formData = new FormData();
  //   const videoFileToChunk: any[] = [];
  //   fileList.forEach((file: UploadFile) => {
  //     if (file.status !== "removed") {
  //       if (file?.originFileObj?.type?.includes("image/")) {
  //         formData.append("quickFiles", file.originFileObj as any);
  //       } else {
  //         videoFileToChunk.push(file);
  //       }
  //     }
  //   });

  //   //setProgress(2);
  //   if (videoFileToChunk.length > 0) {
  //     const videoFiles: any[] = [];
  //     const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
  //     setTotalSize(totalSizes);
  //     let totalUploadedBytes = { value: 0 };
  //     for (let i = 0; i < videoFileToChunk.length; i++) {
  //       const file = videoFileToChunk[i];
  //       const { uploadId, filename } = await decryptService.initializeMultiPartUpload({
  //         filename: file.name,
  //         contentType: file.type,
  //       });

  //       const chunkSize = 5 * 1024 * 1024; // 5MB chunks
  //       const chunks = Math.ceil(file.size / chunkSize);
  //       const uploadPromises: Promise<UploadedPart>[] = [];

  //       for (let i = 0; i < chunks; i++) {
  //         const start = i * chunkSize;
  //         const end = Math.min(file.size, start + chunkSize);
  //         const chunk = file.originFileObj.slice(start, end);
  //         uploadPromises.push(uploadChunk(chunk, i + 1, uploadId, chunks, filename, totalSize, totalUploadedBytes));
  //       }

  //       const parts = await Promise.all(uploadPromises);

  //       const result = await decryptService.finalizeMultipartUpload(uploadId, parts, filename);

  //       videoFiles.push({
  //         Location: result.Location,
  //         isPrivate: file.isPrivate,
  //         Key: result.Key,
  //         ETag: result.ETag,
  //         Bucket: result.Bucket,
  //         filename: file.name,
  //         size: file.size,
  //       });
  //     }

  //     formData.append("videoFiles", JSON.stringify(videoFiles));
  //   }

  //   quickFileService
  //     .addMediaToAlbum(albumId, formData, setProgress)
  //     .then(() => {
  //       message.success(t("quick-file-media-uploaded-success-message"));
  //       message.info(t("big-file-info"));
  //       navigate("/creator/quickLink");
  //     })
  //     .catch((err) => {
  //       message.error(err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }

  async function handleMediaUpload() {
    setLoading(true);
    let formData = new FormData();
    const videoFileToChunk: any[] = [];

    fileList.forEach((file: UploadFile) => {
      if (file.status !== "removed") {
        if (file?.originFileObj?.type?.includes("image/")) {
          formData.append("quickFiles", file.originFileObj as any);
        } else {
          videoFileToChunk.push(file);
        }
      }
    });

    if (videoFileToChunk.length > 0) {
      const videoFiles: any[] = [];
      const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
      setTotalSize(totalSizes);
      let totalUploadedBytes = { value: 0 };

      for (let i = 0; i < videoFileToChunk.length; i++) {
        const file = videoFileToChunk[i];
        const { uploadId, filename } = await decryptService.initializeMultiPartUpload({
          filename: file.name,
          contentType: file.type,
        });

        const chunkSize = 5 * 1024 * 1024; // 5MB chunks
        const chunks = Math.ceil(file.size / chunkSize);

        // Helper function to upload a batch of chunks
        const uploadChunkBatch = async (startIndex: number, batchSize: number) => {
          const uploadPromises: Promise<UploadedPart>[] = [];

          for (let i = startIndex; i < Math.min(startIndex + batchSize, chunks); i++) {
            const start = i * chunkSize;
            const end = Math.min(file.size, start + chunkSize);
            const chunk = file.originFileObj.slice(start, end);
            uploadPromises.push(uploadChunk(chunk, i + 1, uploadId, chunks, filename, totalSize, totalUploadedBytes));
          }

          // Wait for the current batch to finish uploading
          return await Promise.all(uploadPromises);
        };

        let allUploadedParts: UploadedPart[] = [];

        // Upload in batches of 6 chunks
        const batchSize = 6;
        for (let i = 0; i < chunks; i += batchSize) {
          const uploadedParts = await uploadChunkBatch(i, batchSize);
          allUploadedParts = [...allUploadedParts, ...uploadedParts];
        }

        // Finalize multipart upload after all chunks are uploaded
        const result = await decryptService.finalizeMultipartUpload(uploadId, allUploadedParts, filename);

        videoFiles.push({
          Location: result.Location,
          isPrivate: file.isPrivate,
          Key: result.Key,
          ETag: result.ETag,
          Bucket: result.Bucket,
          filename: file.name,
          size: file.size,
        });
      }

      formData.append("videoFiles", JSON.stringify(videoFiles));
    }

    // Upload the media files to the album
    quickFileService
      .addMediaToAlbum(albumId, formData, setProgress)
      .then(() => {
        message.success(t("quick-file-media-uploaded-success-message"));
        message.info(t("big-file-info"));
        navigate("/creator/quickLink");
      })
      .catch((err) => {
        message.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const uploadChunk = async (
    chunk: Blob,
    partNumber: number,
    uploadId: string,
    chunks: number,
    filename: string,
    totalSize: number,
    totalUploadedBytes: { value: number }
  ): Promise<UploadedPart> => {
    const formData = new FormData();
    formData.append("chunk", chunk);
    formData.append("partNumber", partNumber.toString());
    formData.append("uploadId", uploadId);
    formData.append("filename", filename);
    //const data = await decryptService.uploadMultiChunk(formData, chunks, totalSize, totalUploadedBytes, setProgress);
    const data = await decryptService.uploadMultiChunk(formData, setLoadedData).then((response) => {
      //totalUploadedBytes.value += chunk.size;
      //setLoadedData(totalUploadedBytes.value);
      return response;
    });
    return { ETag: data, PartNumber: partNumber };
  };

  //TODO: check Upload component
  return (
    <Spin
      spinning={loading}
      style={{ maxHeight: "100%" }}
      indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
    >
      <Layout className="gradient-background-blue-settings">
        <Layout.Content className="content CreatorAddMediaContentWrapper background-transparent">
          <Row className="relative justify-content-center header-title-container-70">
            <ArrowLeftIconComponent top={10} left={15} />
            <Typography.Text className="header-title-black-35">{t("media")}</Typography.Text>
          </Row>
          <Space direction="vertical" className="items-center w-full mt-30 addMediaAlbumWrapper">
            <AddMediaComponent disabled={loading} title=" " crop={false} onFilesChange={handleFilesChange}>
              <div>
                <img src={AddCircleGrey} width={250} alt="plus" />
              </div>
            </AddMediaComponent>
          </Space>
          <div className="full-width-container">
            <div className="centered-content">
              <Typography.Text className="creatorAddMediaText mt-5">{t("click-add-media-to-your-album")}</Typography.Text>
            </div>
          </div>

          <Space direction="vertical" className="items-center w-full mt-79 mb-20">
            <Button
              //loading={loading}
              disabled={loading}
              className={`AlbumMediaUploadButton ${fileList.length > 0 ? "bg-white" : "bg-grey"}`}
              onClick={() => {
                fileList.length > 0 && !loading && handleMediaUpload();
              }}
            >
              {t("auth-forgot-password-terminate-label")}
            </Button>
          </Space>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default CreatorAddMediaComponent;
