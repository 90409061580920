import { ILocalTrack } from "agora-rtc-sdk-ng";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAgora } from "../../helpers/agoraProvider";
import { AGORA_APP_ID } from "../../helpers/config";
import { USER_TYPES } from "../../helpers/constant";
import { IAgoraTrack } from "../../helpers/types";
import { StoreState } from "../../redux/store";
import { socket } from "../../socket";
import agoraUtil from "../../utls/AgoraUtil";
import AgoraPlayerComponent from "../AgoraPlayerComponent";
import LiveJoinWaitComponent from "../LiveJoinWaitComponent";

type LocalTracks = {
  videoTrack: ILocalTrack | null;
  audioTrack: ILocalTrack | null;
};

type props = {
  onStatusChanged: Function;
  handleDuration: Function;
  token: string;
  streamingTime: null | number
};

const LivePublisherComponent = React.forwardRef(({ onStatusChanged, handleDuration, token, streamingTime }: props, ref: React.Ref<any>) => {
  const { client } = useAgora();
  const live = useSelector((state: StoreState) => state.lives.item);
  //const token = useSelector((state: StoreState) => state.lives.token);
  const localTracks = useRef<LocalTracks>({ videoTrack: null, audioTrack: null });
  const clientRef = useRef<any>();
  let streamDurationIntervalRef = useRef<any>();
  const [tracks, setTracks] = useState<IAgoraTrack | null>(null);
  const [isStarted, setIsStarted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  
  const publish = async () => {
    if (!client || !live || !AGORA_APP_ID || !live?.sessionId || !live.creator || !token) return;
    await client.join(AGORA_APP_ID, live.sessionId, token, live.creator._id);
    const [microphoneTrack, cameraTrack] = await agoraUtil.createLocalTracks({}, { encoderConfig: { bitrateMax: 1000 } });
    await client.publish([microphoneTrack, cameraTrack]);

    setTracks({ tracks: [microphoneTrack, cameraTrack] });
    localTracks.current = { videoTrack: cameraTrack, audioTrack: microphoneTrack };
    socket.emit("public-stream/live", { id: live._id });

    onStatusChanged(true);
    setIsStarted(true);
    streamDurationIntervalRef.current && clearInterval(streamDurationIntervalRef.current);
    streamDurationIntervalRef.current = setInterval(() => {
      if(streamingTime !== null) {
        handleDuration(streamingTime + clientRef.current.getRTCStats().Duration);
      }
      else {
        handleDuration(clientRef.current.getRTCStats().Duration);
      }
    }, 1000);
  };

  const leave = async () => {
    Object.keys(localTracks.current).forEach((trackName) => {
      if (localTracks.current[trackName as keyof LocalTracks]) {
        localTracks.current[trackName as keyof LocalTracks]?.stop();
        localTracks.current[trackName as keyof LocalTracks]?.close();
      }
    });
    localTracks.current = { videoTrack: null, audioTrack: null };
    setTracks(null);

    if (clientRef.current && clientRef.current.uid) {
      await clientRef.current.leave();
    }

    streamDurationIntervalRef.current && clearInterval(streamDurationIntervalRef.current);
  };

  const handleError = (error: string) => {
    setErrorMessage(error);
  };

  const mute = async () => {
    if (!localTracks?.current?.audioTrack) return;
    await localTracks.current.audioTrack.setMuted(true);

    return localTracks.current.audioTrack.muted;
  };

  const unmute = async () => {
    if (!localTracks?.current?.audioTrack) return;
    await localTracks.current.audioTrack.setMuted(false);

    return localTracks.current.audioTrack.muted;
  };

  useEffect(() => {
    clientRef.current = client;
  }, [client]);

  useEffect(() => {
    return () => {
      leave();
    };
  }, []);

  React.useImperativeHandle(ref, () => ({
    publish,
    leave,
    handleError,
    mute,
    unmute,
  }));

  return isStarted ? (
    <AgoraPlayerComponent isFromMobile={live?.isFromMobile!} tracks={tracks?.tracks || []} />
  ) : (
    <LiveJoinWaitComponent errorMessage={errorMessage} type={USER_TYPES.CREATOR} />
  );
});

export default LivePublisherComponent;
